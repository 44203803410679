import React, {useState, useEffect, useCallback, useRef} from 'react'
import { useRoomChatStore, useUserConnectionStore, EMPTY_PAGES_ID, useUserSettingStore, StrokeEventType, StrokeEvent} from '../store'
import _debounce from 'lodash/debounce'
import { getCurrentPageSVGbase64String, sleep, thumbnailScrollIntoView } from '../utils'
import { apiCreatePage, apiDeletePage, apiGetPagesInfo, apiGetThumbnail, apiSetCurrPage, apiSetPageOrder, apiSetThumbnail } from '../api'
import whiteImage from '../images/Solid_white.svg'
import {useTranslation} from 'react-i18next'


const pageDetailsSelector = state => state.pageDetails
const setPageDetailsSelector = state => state.setPageDetails
const setPageDetailsWithHistorySelector = state => state.setPageDetailsWithHistory
const totalPagesOnInitialSelector = state => state.totalPagesOnInitial
const setThumbnailByPageIdSelector = state => state.setThumbnailByPageId
const insertPageSelector = state => state.insertPage
const currPageIDSelector = state => state.currPageID
const setCurrPageIdSelector = state => state.setCurrPageId
const deletePageByIdSelector = state => state.deletePageById

const isThumbnailHideSelector = state => state.isThumbnailHide
const toggleThumbnailSelector = state => state.toggleThumbnail
export const updateMiniMapThumbnail = (base64String) => {
    const minimap = document.querySelector('.minimap')
    if(minimap){
        if(!base64String){
            base64String = getCurrentPageSVGbase64String().base64
        }
        minimap.classList.add('loading')
        minimap.children[0].src = base64String
        minimap.classList.remove('loading')
        
    }
}


const ThumbnailSlides = ({setRoomPageSize, loadExistingData, mainCenterRef}) => {
    const {t} = useTranslation()
    const pageDetails = useRoomChatStore(pageDetailsSelector)
    const setPageDetails = useRoomChatStore(setPageDetailsSelector)
    const setPageDetailsWithHistory = useRoomChatStore(setPageDetailsWithHistorySelector)
    const setThumbnailByPageId = useRoomChatStore(setThumbnailByPageIdSelector)
    const totalPagesOnInitial = useRoomChatStore(totalPagesOnInitialSelector)
    const insertPage = useRoomChatStore(insertPageSelector)
    const currPageID = useRoomChatStore(currPageIDSelector)
    const setCurrPageId = useRoomChatStore(setCurrPageIdSelector)
    const deletePageById = useRoomChatStore(deletePageByIdSelector)
    const isThumbnailHide = useUserSettingStore(isThumbnailHideSelector)
    const toggleThumbnail = useUserSettingStore(toggleThumbnailSelector)
    const switchPageByThumbnail = useRef(false)
    const isFirstTimeLoad = useRef(true)
    const thumbnailRef = useRef()
    const [moreActionMenuOpen, setMoreActionMenuOpen] = useState(false)
    const updateThumbnail = useCallback(async (targetPageIdx=0, targetPageID, needUpdateToServer=false) => {
        while(document.querySelector('.whiteboard-container').dataset.pageid !== targetPageID){
            await sleep(5)
        }
        const {pageDetails, currPageID, marks, shapes, imageInks} = useRoomChatStore.getState()
        const roomID = useUserConnectionStore.getState().roomId
        if(pageDetails.length === 0) return

        const pageDetailsClone = [...pageDetails]
        const pageIdx = pageDetailsClone.findIndex(pageDetail => pageDetail.id === targetPageID)
        // console.log(useRoomChatStore.getState().isSwitchPageAction)
        if(useRoomChatStore.getState().isSwitchPageAction){
            if(marks.length === pageDetailsClone[pageIdx].markCount && 
                shapes.length === pageDetailsClone[pageIdx].shapeCount && 
                imageInks.length === pageDetailsClone[pageIdx].imageInkCount){
                updateMiniMapThumbnail(pageDetailsClone[pageIdx].thumbnail)
                return
            }
            if(pageDetailsClone[pageIdx].markCount === -1 && pageDetailsClone[pageIdx].shapeCount === -1 && pageDetailsClone[pageIdx].imageInkCount === -1){ //first load
                updateMiniMapThumbnail(pageDetailsClone[pageIdx].thumbnail)
                return
            }
        }
        

        
        const {base64, markCount, shapeCount, imageInkCount, hasLoadingElement} = getCurrentPageSVGbase64String()
        if(hasLoadingElement){
            return
        }
        updateMiniMapThumbnail(base64)    
        pageDetailsClone[pageIdx] = {
            ...pageDetailsClone[pageIdx],
            thumbnail: base64,
            markCount,   
            shapeCount,
            imageInkCount
        }
        setPageDetails(pageDetailsClone)
        if(needUpdateToServer === false)
            return
        debounceSetThumbnailToServerQuick({
            roomID,
            pageID: currPageID,
            thumbnailBase64: base64
        })
        
    }, [])
    const updateThumbnailToServer = (data) => {
        apiSetThumbnail(data)
    }

    const debounceUpdateThumbnailQuick = useCallback(_debounce(updateThumbnail, 50), [])
    const debounceSetThumbnailToServerQuick = useCallback(_debounce(updateThumbnailToServer, 30), [])

    const onMarksAndShapesChanged = (d) => {
        const {currentPageIdx, currPageID} = useRoomChatStore.getState()
        debounceUpdateThumbnailQuick(currentPageIdx, currPageID, true)
    }

    useEffect(() => {
        
        if(!isThumbnailHide){
            thumbnailRef.current.classList.remove('hide')
            mainCenterRef.current.classList.add('thumbnail-open')
            mainCenterRef.current.children.forEach(dom => dom.classList.add('thumbnail-open'))

        }else{
            thumbnailRef.current.classList.add('hide')
            mainCenterRef.current.classList.remove('thumbnail-open')
            mainCenterRef.current.children.forEach(dom => dom.classList.remove('thumbnail-open'))

        }
    }, [isThumbnailHide])

    useEffect(() => {
        const unsubMarksAndShapesChanged = useRoomChatStore.subscribe(onMarksAndShapesChanged, state => state.markAndShapesLastChanged)
        document.addEventListener('dragover', onDragOverAndEnter)
        document.addEventListener('dragenter', onDragOverAndEnter)
        return () => {
            unsubMarksAndShapesChanged()
            document.removeEventListener('dragover', onDragOverAndEnter)
            document.removeEventListener('dragenter', onDragOverAndEnter)
        }
    }, [])


    useEffect(async() => {
        if(totalPagesOnInitial === -1) return 


        const observer = new IntersectionObserver(entries => {
            entries.forEach(async (entry) => {
                if(entry.isIntersecting) {
                    const pageId = entry.target.dataset.pageid
                    const pageDetail = useRoomChatStore.getState().pageDetails.find(page => page.id === pageId)
                    if(!pageDetail.thumbnail){
                        const thumbnails = await apiGetThumbnail({
                            roomID: useUserConnectionStore.getState().roomId,
                            pageIDs: [pageId]
                        })
    
                        
                        if(thumbnails.data[0]?.pageID && thumbnails.data[0]?.pageID === pageId){
                            
                            setThumbnailByPageId(pageId, thumbnails.data[0].thumbnail || whiteImage)
                        }

                    }
                    
                    observer.unobserve(entry.target)
                }
            })
        })
        const thumbnails = document.querySelectorAll('.image-container>.image-box')
        thumbnails.forEach(thumbnail => {
            observer.observe(thumbnail)
        })
        useRoomChatStore.getState().resetTotalPageOnInitial()
        return () => {
            observer.disconnect()
        }


    }, [totalPagesOnInitial])

    useEffect(async () => {
        setMoreActionMenuOpen(false)
        if(currPageID === EMPTY_PAGES_ID){
            return
        }
        if(isFirstTimeLoad.current){
            isFirstTimeLoad.current = false
            return
        }
        document.querySelector('.whiteboard-container').classList.add('loading')
        const roomId = useUserConnectionStore.getState().roomId
        const {currentPageIdx, pageDetails} = useRoomChatStore.getState()

        await apiSetCurrPage(roomId, currPageID)
        document.querySelector('.currentPageIdx').value = currentPageIdx + 1
        await loadExistingData(currPageID, true)
        const {width, height} = pageDetails.find(page => page.id === currPageID)
        setRoomPageSize({width, height, isLoadFromServer: true, switchPage: true})
        debounceUpdateThumbnailQuick(currentPageIdx, currPageID, true)
        
    }, [currPageID])
    
    const togglePageSelected = async(e, id) => {
        if(!e.target.classList.contains('more-action-menu') && 
        !e.target.classList.contains('more-action')){
            setMoreActionMenuOpen(false)
        }
        switchPageByThumbnail.current = true
        useRoomChatStore.getState().setIsSwitchPageAction(true)
        setCurrPageId(id)
    }

    const createPage = async(e) => {
        const previousPageId = e.target.dataset.previouspageid
        try{
            const roomId = useUserConnectionStore.getState().roomId
            const result = await apiCreatePage(roomId, previousPageId)
            const pageIdx = result.data
            const pagesInfo = await apiGetPagesInfo(roomId)
            const {width, height, id: pageID} = pagesInfo.data.pages[pageIdx]
            insertPage({pageIdx, pageID, width, height})
            thumbnailScrollIntoView(pageIdx)
            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentPageDetailsChanged, useRoomChatStore.getState().pageDetails.map(p => ({...p, thumbnail: null})))
                useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
            }
        }catch(e){
            console.log(e)
            alert('建立失敗')
        }
    }


    const onDragOverAndEnter = useCallback((e) => {
        e.preventDefault()
        if(e.dataTransfer.items?.length >= 1){
            e.dataTransfer.dropEffect = "none"
            return 
        }

        if(thumbnailRef.current.contains(e.target)){
            e.dataTransfer.dropEffect = "move"
            const [closestElement, nonClosestElemnts] = getClosestElement(e.clientX, e.clientY)
            if(closestElement){
                closestElement.classList.add('dragover')
            }
            nonClosestElemnts.forEach(element => element.classList.remove('dragover'))
        }else{
            e.dataTransfer.dropEffect = "none"
            thumbnailRef.current.querySelectorAll('.insert-page.dragover').forEach(element => element.classList.remove('dragover'))
        }
        
       
    }, [])

    const onDragEnd = useCallback((e) => {
        e.preventDefault()
        const dragPosition = thumbnailRef.current.querySelector('.dragover')
        const dragElement = e.target
        dragElement.classList.remove('dragging')
        thumbnailRef.current.classList.remove('dragging')
        if(!dragPosition){
            return 
        }
        
        const dragElementPageIdx = Number(dragElement.dataset.pageidx)
        const targetPosition = Number(dragPosition.dataset.idx)

        if(dragElementPageIdx === targetPosition || dragElementPageIdx+1 === targetPosition){
            return 
        }
    
        const prevElementId = dragPosition.previousElementSibling?.id || null
        const pageDetails = useRoomChatStore.getState().pageDetails.filter(detail => detail.id !== dragElement.dataset.pageid)
        const insertPos = prevElementId ? pageDetails.findIndex(detail => detail.id === prevElementId) : -1
        let newPageDetails = [...pageDetails.slice(0, insertPos+1), useRoomChatStore.getState().pageDetails[dragElementPageIdx], ...pageDetails.slice(insertPos+1)]
        newPageDetails = newPageDetails.map((d, idx) => {
            return {
                ...d,
                pageIdx: idx
            }
        })

        setPageDetailsWithHistory(newPageDetails)
        if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentPageDetailsChanged, newPageDetails.map(p => ({...p, thumbnail: null})))
            useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
        }
        

        apiSetPageOrder({
            roomID: useUserConnectionStore.getState().roomId,
            pages: newPageDetails.map(detail => detail.id)
        })
    }, [])



    const getClosestElement = useCallback((x, y) => {
        const draggableElements = [...thumbnailRef.current.querySelectorAll('.insert-page')]
        let closestValue = Number.POSITIVE_INFINITY
        let closetElement = null
        draggableElements.forEach(element => {
            const {top, height} = element.getBoundingClientRect()
            const middle = top+height/2
            const offset = Math.abs(middle-y)
            if(offset < closestValue){
                closetElement = element
                closestValue = offset
            }
        })
        return [closetElement, draggableElements.filter(element => element !== closetElement)]
    }, [])

    const deleteAction = (pageId) => {
        deletePageById(pageId) 
        apiDeletePage(useUserConnectionStore.getState().roomId, pageId)
        setMoreActionMenuOpen(false)
    }

    return (
        <div className={`thumbnail ${isThumbnailHide? 'hide' : ''}`} ref={thumbnailRef}>
            <span className='thumbnail-expand' onClick={toggleThumbnail}/>
            <div data-idx={0} className={`insert-page`}><span data-tooltip={t('room.insertPage')} data-previouspageid={null} className='insert-page-icon' onClick={createPage}></span></div>
            {pageDetails.map(({id, thumbnail}, idx) =>{
                return(
                    <div key={id} className='page-detail' >
                        <div id={id} className='image-container' data-pagenum={Number(idx)+1}>
                            <div className={`image-box  ${currPageID === id ? 'selected' : ''}`} 
                            data-pageidx={idx}
                            data-pageid={id} data-loadingfinished={thumbnail == null ? false : true} 
                            onPointerLeave={(e) => {e.target.classList.remove('without-box-shadow')}}
                            onPointerDown={(e) => togglePageSelected(e, id)}
                            onDragStart={(e) => {
                                e.dataTransfer.effectAllowed = "move"
                                e.target.classList.add('dragging')
                                thumbnailRef.current.classList.add('dragging')
                            }}
                            onDragEnd={onDragEnd}
                            draggable='true'>
                                <span data-idx={Number(idx)} alt= '' 
                                className={`${thumbnail ? '' : 'loading'}`} 
                                />
                                <img data-idx={Number(idx)} alt= '' 
                                className={`${thumbnail ? 'thumbnail-img' : 'loading'}`} 
                                src={`${thumbnail ? thumbnail : ''}`} 
                                draggable={false}
                                />

                                {!(pageDetails.length===1) && <span data-tooltip={t('room.moreAction')} className={`more-action ${currPageID === id ? 'selected': 'unselected'}`} 
                                onClick={(e)=> {
                                    setMoreActionMenuOpen(open => !open)

                                }}></span>}
                                {moreActionMenuOpen 
                                    && currPageID === id
                                    && <div className='more-action-menu' onClick={(e) => {
                                        deleteAction(id)
                                        e.stopPropagation()
                                    }}>{t('toolbar.delete')}</div>
                                }
                            </div>
                        </div>
                        <div data-idx={Number(idx)+1} className='insert-page'><span data-tooltip={t('room.insertPage')} className='insert-page-icon' data-previouspageid={id} onClick={createPage}></span></div>
                    </div>
                )
            })}
        </div>
    )
}

export default React.memo(ThumbnailSlides, () => {
    return true
})
