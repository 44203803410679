import React, {useRef, useEffect, useState} from 'react'
import { AccountType, undoResult, useRoomChatStore, useToolbarSettingsStore, useUserConnectionStore } from '../store'
import useToolbarEffectEvent from '../hooks/useToolbarEffectEvent'
import undoImageHover from '../images/ic_menu_undo_a&d@2x.png'
import undoImage from '../images/ic_menu_undo_n@2x.png'
import undoDisableImage from '../images/ic_menu_undo_g@2x.png'
import { apiAddDrawObjectMulti, apiDeleteDrawObjectMulti, apiUndeletePage, apiDeletePage, apiMsgToOther, apiSetPageOrder, apiUpdateDrawObjectMulti } from '../api'
import { useCookies } from 'react-cookie'
import { SocketEvent, SocketEventType } from '../socketEvent'
import {useTranslation} from 'react-i18next'
import _xor from 'lodash/xor'
import { getLatestHistory, sleep } from '../utils'

const hasHistorySelector = state => state.hasHistory
const undoSelector = state => state.undo
const toCertainPageIDSelector = state => state.toCertainPageID
export const updateUndoResultToServer = async (undoResult) => {
    const {addMarks, addShapes, addImageInks, deleteMarks, deleteShapes, deleteImageInks, updateMarks, updateShapes, updateImageInks, oldPageDetails, newPageDetails} = undoResult
    const {userId, roomId} = useUserConnectionStore.getState()
    if(addMarks.length > 0 || addShapes.length >0 || addImageInks.length > 0){
        const dataMarks = addMarks.map(mark => {
            return {
                roomID: roomId,
                accountID: userId,
                pageID: useRoomChatStore.getState().currPageID,
                id: mark.id,
                type: mark.type,
                content: JSON.stringify(mark)
            }
        })
        const dataShapes = addShapes.map(shape => {
            return {
                roomID: roomId,
                accountID: userId,
                pageID: useRoomChatStore.getState().currPageID,
                id: shape.id,
                type: shape.type,
                content: JSON.stringify(shape)
            }
        })
        const dataImageInks = addImageInks.map(imageInk => {
            return {
                roomID: roomId,
                accountID: userId,
                pageID: useRoomChatStore.getState().currPageID,
                id: imageInk.id,
                type: imageInk.type,
                content: JSON.stringify(imageInk)
            }
        })
        apiAddDrawObjectMulti([...dataMarks, ...dataShapes, ...dataImageInks])

    }

    if(updateShapes.length > 0 || updateMarks.length > 0 || updateImageInks.length > 0){
        const dataShapes = updateShapes.map(shape => {
            return{
                id: shape.id,
                content: JSON.stringify(shape)
            }
        })
        const dataMarks = updateMarks.map(mark => {
            return{
                id: mark.id,
                content: JSON.stringify(mark)
            }
        })
        const dataImageInks = updateImageInks.map(imageInk => {
            return{
                id: imageInk.id,
                content: JSON.stringify(imageInk)
            }
        })

        apiUpdateDrawObjectMulti([...dataShapes, ...dataMarks, ...dataImageInks])
    }

    if(deleteMarks.length > 0 || deleteShapes.length > 0 || deleteImageInks.length > 0){
        apiDeleteDrawObjectMulti([...deleteMarks, ...deleteShapes, ...deleteImageInks])
    }

    if(oldPageDetails.length > 0 && newPageDetails.length>0){
        const oldPageDetailsId = oldPageDetails.map(detail => detail.id)
        const newPageDetailsId = newPageDetails.map(detail => detail.id)

        if(oldPageDetailsId.length > newPageDetailsId.length){ //需要刪除頁面
            const removePageId = _xor(oldPageDetailsId, newPageDetailsId)[0]
            if(removePageId){
                apiDeletePage(useUserConnectionStore.getState().roomId, removePageId)
            }
        }else if(oldPageDetailsId.length < newPageDetailsId.length){//需要新增頁面
            const addPageId = _xor(oldPageDetailsId, newPageDetailsId)[0]
            if(addPageId){
                const addPageIdIdx = newPageDetailsId.findIndex(id => id === addPageId)
                const {width, height} = newPageDetails[addPageIdIdx]
                apiUndeletePage({
                    roomID:roomId, 
                    pageID: addPageId,
                    pageIdx: addPageIdIdx,
                    width,
                    height
                })
            }
        }else{
            apiSetPageOrder({
                roomID: useUserConnectionStore.getState().roomId,
                pages: newPageDetailsId
            })
        }

    }
}
const UndoIcon = () => {
    const {t} = useTranslation()
    const iconRef = useRef()
    const [clickOneTimeTool, setClickOneTimeTool] = useState(false)    
    const {setIsShowDetail, isTouchDevice, displayIconBackground, displayHoverImage} = useToolbarEffectEvent({iconRef, clickOneTimeTool, setClickOneTimeTool})
    const hasHistory = useRoomChatStore(hasHistorySelector)
    const undo = useToolbarSettingsStore(undoSelector)
    const toCertainPageID = useRoomChatStore(toCertainPageIDSelector)
    const [cookies] = useCookies(['userInfo'])
    const renderImage = () => {
        if(hasHistory){
            return <img alt='' src={ displayHoverImage ? undoImageHover : undoImage}/>
        }else{
            return <img alt='' src={undoDisableImage}/>
        }
    }

    useEffect(async () => {
        if(clickOneTimeTool === false) return

        const history = getLatestHistory()
        let whiteboardContainer = document.querySelector('.whiteboard-container')
        let currentPageId = whiteboardContainer.dataset.pageid
        if(history?.pageId && history?.pageId !== currentPageId && cookies.userInfo.accountType === AccountType.inviter){
            toCertainPageID(history?.pageId)
            while(whiteboardContainer.dataset.pageid !== history?.pageId){
                await sleep(10)
            }
        }
        undo()
        if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
            const event = new SocketEvent(SocketEventType.OPPONENT_UNDO, {undoResult}, null, cookies.userInfo.roomId)
            
            apiMsgToOther({
                roomID:  cookies.userInfo.roomId,
                accountID: cookies.userInfo.account,
                content: JSON.stringify(event)
            })
        }
        updateUndoResultToServer(undoResult)
        if(isTouchDevice){
            await sleep(100)
            setIsShowDetail(false)    
            setClickOneTimeTool(false)
        }else{
            setClickOneTimeTool(false)
        }
    }, [clickOneTimeTool])

    return(
        <li ref={iconRef}>
            <div data-tooltip={t('toolbar.undo')} className={`toolbar-icon ${displayIconBackground&&hasHistory ? 'toolbar-icon-hover-active' : ''}`} >
                {renderImage()}
            </div>
        </li>
    )
}
export default React.memo(UndoIcon)
