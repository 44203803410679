import {useHotkeys} from 'react-hotkeys-hook'
import { updateUndoResultToServer } from '../components/UndoIcon'
import { ratioToScrollThumbnail, undoResult, useRoomChatStore, useUserConnectionStore, PointerState, AccountType } from '../store'
import { SocketEvent, SocketEventType } from '../socketEvent'
import { apiMsgToOther } from '../api'
import { canControlPage, thumbnailScrollIntoView } from '../utils'
import { getPointer, updatePointer } from './useEvent'
import _debounce from 'lodash/debounce'
import { useCallback } from 'react'

const useKeyboardShortcuts = () => {
    useHotkeys('command+z,ctrl+z', () => {
        if(!useRoomChatStore.getState().hasHistory) 
            return
        const {dataChannel} = useUserConnectionStore.getState()
        const {userId, roomId} = useUserConnectionStore.getState()
        useRoomChatStore.getState().undo()
        updateUndoResultToServer(undoResult)
        
        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new SocketEvent(SocketEventType.OPPONENT_UNDO, {undoResult}, null, roomId)
            
            apiMsgToOther({
                roomID:  roomId,
                accountID: userId,
                content: JSON.stringify(event)
            })
        }
    })

    useHotkeys('up,down,pageup,pagedown,home,end', (e) => {
        const isControlByInviter = useRoomChatStore.getState().isControlByInviter
        const accountType = useUserConnectionStore.getState().accountType
        if(canControlPage(accountType, isControlByInviter)){
            e.preventDefault()
            debouncePageChange(e.key)   
        }
    })

    const pageChange = (key) => {
        const {currentPageIdx, totalPages ,toPrevPage, toNextPage, toFirstPage, toLastPage, pointerState} = useRoomChatStore.getState()
        if(PointerState.down === pointerState){
            return
        }
        if(document.querySelector('.whiteboard-container').classList.contains('loading')){
            return
        }

        if((key === 'ArrowUp'|| key==='PageUp') && currentPageIdx > 0){
            thumbnailScrollIntoView(currentPageIdx - 1)    
            toPrevPage()
        }
        if((key === 'ArrowDown'|| key==='PageDown') && currentPageIdx+1 < totalPages){
            thumbnailScrollIntoView(currentPageIdx + 1)
            toNextPage()
        }
       
        if(key === 'Home' && currentPageIdx > 0){
            thumbnailScrollIntoView(0)
            toFirstPage()
        }
        if(key === 'End'  && currentPageIdx+1 < totalPages){
            thumbnailScrollIntoView(totalPages -1)
            toLastPage()
        }


    }
    const debouncePageChange = useCallback(_debounce(pageChange, 10), [])
    
    useHotkeys('ctrl+=,ctrl+-,command+=,command+-', (e) => {
        e.preventDefault()
        if(e.code === 'Equal'){
            const {x, y} = getPointer()
            useRoomChatStore.getState().updateCurrentPointerPositionForZoom([x,y])
            useRoomChatStore.getState().zoomIn()
        }
        if(e.code === 'Minus'){
            const {x, y} = getPointer()
            useRoomChatStore.getState().updateCurrentPointerPositionForZoom([x,y])
            useRoomChatStore.getState().zoomOut()
        }
    })

}

export default useKeyboardShortcuts
