import React, {useState, useEffect, useRef, useCallback, lazy, Suspense} from 'react'
import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'
import { AccountType, PointerState, StrokeEvent, StrokeEventType, useRoomChatStore, useUserConnectionStore, useUserSettingStore } from '../store'
import { canControlPage, getCurrentPageSVGbase64String, getCurrentRatioBaseOnContainer, getScrollLeftMaxAndScrollTopMax, mathRound, minimapRatioCalcluate, sleep, thumbnailScrollIntoView } from '../utils'
import { apiUpdateIsControlByInviter, apiUpdatePage } from '../api'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import ReactDOM from "react-dom"
import shallow from 'zustand/shallow'
// import PageToolbar from './PageToolbar'
import { updatePointer } from '../hooks/useEvent'
import { scrollToCertainLocation } from './Minimap'
import { updateMiniMapThumbnail } from './ThumbnailSlides'
 
export const whiteboardMinSize = 100
export const whiteboardMaxSize = 5000

const PageToolbar = lazy(() => import('./PageToolbar'))


//兩邊各10px加起來20px
//上下也是
const whiteboardWrapperPaddingTwice = 28
const whiteboardWrapperPaddingTwicePx = `${whiteboardWrapperPaddingTwice}px`
const miniMapMaxSize = 150
let syncViewPortOptimizationTimes = 5
let dragStartPoint
let dragStartSize

const ratioSelector = state => state.ratio
const zoomInSelector = state => state.zoomIn
const zoomOutSelector = state => state.zoomOut
const setRatioSelector = state => state.setRatio
const currentPageIdxSelector = state => state.currentPageIdx
const setPageDetailsSizeByPageIdSelector = state => state.setPageDetailsSizeByPageId
const setThumbnailByPageIdSelector = state => state.setThumbnailByPageId
const isControlByInviterSelector = state => state.isControlByInviter
const setIsControlByInviterSelector = state => state.setIsControlByInviter
const setImageInkColorPickerInfoSelector = state => state.setImageInkColorPickerInfo
const setImageInkStrokeWidthSliderInfoSelector = state => state.setImageInkStrokeWidthSliderInfo

let lastWheelTime = 0
const WhiteboardOptionsControl = ({roomPageSize, customAlert, setRoomPageSize}) => {
    const {t} = useTranslation()
    const setThumbnailByPageId = useRoomChatStore(setThumbnailByPageIdSelector)
    const setPageDetailSizeByPageId = useRoomChatStore(setPageDetailsSizeByPageIdSelector)
    const setImageInkStrokeWidthSliderInfo = useRoomChatStore(setImageInkStrokeWidthSliderInfoSelector)
    const whiteboardRef = useRef()
    const whiteboardWrapperRef = useRef()
    const whiteboardWrapperBoundingRect = useRef()
    const whiteboardContainerRef = useRef()
    const whiteboardDragRef = useRef()
    const sizeRef = useRef()
    const pageControlChangerRef = useRef()
    const isTriggerByFitScreen = useRef(false)
    const isTriggerByFollow = useRef(false)
    const reachSizeLimit = useRef(false)
    const [size, setSize] = useState({}, shallow)
    const ratio = useRoomChatStore(ratioSelector)
    const zoomIn = useRoomChatStore(zoomInSelector)
    const zoomOut = useRoomChatStore(zoomOutSelector)
    const setRatio = useRoomChatStore(setRatioSelector)
    const [cookies] = useCookies(['userInfo'])
    const scrollPosition = useRef({left: 0, top: 0})
    const previousRatio = useRef(ratio)
    const [isReadonly, setIsReadonly] = useState(true)
    const lastScrollTop = useRef(-1)
    const isControlByInviter = useRoomChatStore(isControlByInviterSelector)
    const setIsControlByInviter = useRoomChatStore(setIsControlByInviterSelector)
    const setImageInkColorPickerInfo = useRoomChatStore(setImageInkColorPickerInfoSelector)
    useEffect(() => {
        whiteboardRef.current = document.querySelector('.whiteboard')
        whiteboardWrapperRef.current = whiteboardRef.current.closest('.whiteboard-wrapper')
        whiteboardWrapperBoundingRect.current =  whiteboardWrapperRef.current.getBoundingClientRect()
        whiteboardContainerRef.current = whiteboardWrapperRef.current.closest('.whiteboard-container')
        whiteboardDragRef.current = document.querySelector('.whiteboard-drag')

        whiteboardDragRef.current.addEventListener('pointerdown', whiteboardDragPointerDown)
        whiteboardContainerRef.current.addEventListener('scroll', onScroll)
        whiteboardContainerRef.current.addEventListener('wheel', onWheel,{passive: false})
    
        return () => {
            whiteboardContainerRef.current.removeEventListener('wheel', onWheel)
            whiteboardDragRef.current.removeEventListener('pointerdown', whiteboardDragPointerDown)
            whiteboardContainerRef.current.removeEventListener('pointerdown', whiteboardDragPointerDown)
        }
    
    }, [])

    const changeMinimapSize = useCallback((w, h) => {
        const minimap = document.querySelector('.minimap')
        if(minimap){
            minimap.classList.add('loading')
            let scaleWidth, scaleHeight, scaleRatio
            if(parseInt(w) >= parseInt(h)){
                scaleWidth = miniMapMaxSize
                scaleRatio = scaleWidth/w
                scaleHeight = h*scaleRatio

            }else{
                scaleHeight = miniMapMaxSize
                scaleRatio = scaleHeight/h
                scaleWidth = w*scaleRatio
            }

            minimap.style.width = `${scaleWidth}px`
            minimap.style.height = `${scaleHeight}px`
            minimap.dataset.scaleratio = scaleRatio
        }
    }, [])

    const onScroll = (e) => {
        if(!isTriggerByFitScreen.current){
            scrollPosition.current = {left: whiteboardContainerRef.current.scrollLeft, top:whiteboardContainerRef.current.scrollTop}
        }
        debounceUpdateWhiteboardResetTime()
    }

    const onWheel = async(e) => {
        if(e.ctrlKey || e.metaKey){
            e.preventDefault()
            // console.log(e.timeStamp)
            isTriggerByFitScreen.current = false
            isTriggerByFollow.current = false


            // console.log(e.timeStamp - lastWheelTime)
            if(e.timeStamp - lastWheelTime > 70){
                const {x, y} =  updatePointer(e)
                useRoomChatStore.getState().updateCurrentPointerPositionForZoom([x,y])
            }
            
            const size = [Math.round(whiteboardRef.current.style.width.replace('px','')),
            Math.round(whiteboardRef.current.style.height.replace('px',''))]
            
            lastWheelTime = e.timeStamp
            if(e.deltaY< 0){
                if(size[0] > whiteboardMaxSize*50 || size[1] > whiteboardMaxSize*50){
                    return 
                }
                zoomIn()
            }else if(e.deltaY > 0){
                if(size[0] < whiteboardMinSize || size[1] < whiteboardMinSize){
                    return 
                }

                zoomOut()
            }

        }else{
            useRoomChatStore.getState().updateCurrentPointerPositionForZoom([])

            const {isControlByInviter, currentPageIdx, totalPages, toPrevPage, toNextPage, pointerState} = useRoomChatStore.getState()
            const elm  = whiteboardContainerRef.current
            const accountType = useUserConnectionStore.getState().accountType
            if(canControlPage(accountType, isControlByInviter) === false)
                return
            if(Math.abs(elm.scrollTop + elm.clientHeight - elm.scrollHeight)<1 && e.deltaY > 0 && currentPageIdx+1 < totalPages){
                if(PointerState.down === pointerState){
                    return
                }
                if(document.querySelector('.whiteboard-container').classList.contains('loading')){
                    return
                }
                thumbnailScrollIntoView(currentPageIdx + 1)
                toNextPage()
            }else if(elm.scrollTop === 0 && e.deltaY < 0 && currentPageIdx > 0){
                if(PointerState.down === pointerState){
                    return
                }
                if(document.querySelector('.whiteboard-container').classList.contains('loading')){
                    return
                }
                thumbnailScrollIntoView(currentPageIdx - 1)    
                toPrevPage()
            }

            
        }
    }
    const whiteboardDragPointerDown = (e) => {
        const xOffset=whiteboardContainerRef.current.scrollLeft
        const yOffset=whiteboardContainerRef.current.scrollTop
        dragStartPoint = [e.clientX+xOffset, e.clientY+yOffset]
        dragStartSize = [Math.round(whiteboardRef.current.style.width.replace('px','')),
        Math.round(whiteboardRef.current.style.height.replace('px',''))]

        window.addEventListener('pointermove', resize)
        window.addEventListener('pointerup', stopResize)
    }
    const resize = (e) => {
        e.preventDefault()
        const xOffset=whiteboardContainerRef.current.scrollLeft
        const yOffset=whiteboardContainerRef.current.scrollTop
        const [originalX, originalY] = dragStartPoint
        const [originalWidth, originalHeight] = dragStartSize
        const deltaX = e.clientX+xOffset - originalX
        const deltaY = e.clientY+yOffset - originalY
        const scaleWidth = originalWidth + deltaX
        const scaleHeight = originalHeight + deltaY
        const actualWidth = Math.round(scaleWidth/useRoomChatStore.getState().ratio)
        const actualHeight = Math.round(scaleHeight/useRoomChatStore.getState().ratio)

        if((actualWidth < whiteboardMinSize) || (actualWidth > whiteboardMaxSize) ||
            (actualHeight < whiteboardMinSize) || (actualHeight > whiteboardMaxSize)){
            return
        }

        whiteboardWrapperRef.current.style.width = `${scaleWidth+whiteboardWrapperPaddingTwice}px` 
        whiteboardWrapperRef.current.style.height = `${scaleHeight+whiteboardWrapperPaddingTwice}px` 
        whiteboardRef.current.style.width = `${scaleWidth}px`
        whiteboardRef.current.style.height = `${scaleHeight}px`

        whiteboardRef.current.setAttribute('viewBox', `0 0 ${actualWidth} ${actualHeight}`) 
        sizeRef.current.firstElementChild.value = actualWidth
        sizeRef.current.lastElementChild.value = actualHeight
    }
    const stopResize = (e) => {
        e.preventDefault()
        window.removeEventListener('pointermove', resize)
        const whiteboardSize = document.getElementById('whiteboard-size')
        if(whiteboardSize){

            const w = whiteboardSize.firstElementChild.value
            const h = whiteboardSize.lastElementChild.value
            setSize({
                width: w,
                height: h
            })
            dragStartPoint = null
            dragStartSize = null
            changeMinimapSize(w, h)
            debounceUpdateWhiteboardResetTime()
            debounceUpdateMiniMapThumbnail()
        }
        window.removeEventListener('pointerup', stopResize)
       
    }
     
    useEffect(() => {
        if(roomPageSize){
            ReactDOM.unstable_batchedUpdates(() => {
                const width = Math.round(roomPageSize.width)
                const height = Math.round(roomPageSize.height)
                if(roomPageSize.isLoadFromServer && roomPageSize.switchPage){
                    fillInWindow(width, height)
                    sizeRef.current.firstElementChild.value = width
                    sizeRef.current.lastElementChild.value = height
                    changeMinimapSize(width, height)
                    
                }else if(roomPageSize.isLoadFromServer){
                    
                    setTimeout(() => {
                        fillInWindow(width, height)
                        sizeRef.current.firstElementChild.value = width
                        sizeRef.current.lastElementChild.value = height
                        changeMinimapSize(width, height)
                        debounceUpdateWhiteboardResetTime()
                        debounceUpdateMiniMapThumbnail()    
                    }, 500)
                    
                }else{
                    resetViewPort(width, height)
                    sizeRef.current.firstElementChild.value = width
                    sizeRef.current.lastElementChild.value = height
                    changeMinimapSize(width, height)
                    debounceUpdateWhiteboardResetTime()
                    debounceUpdateMiniMapThumbnail()
                }
                
                
            })
        }
    }, [roomPageSize])

    const ratioChangeAndScrollToCertainPosition = (ratioChanged) => {
        resetViewPort(Math.round(sizeRef.current.firstElementChild.value), Math.round(sizeRef.current.lastElementChild.value))
        const [x, y] =  useRoomChatStore.getState().currentPointerPositionForZoom
        if(x && y){    
            scrollPosition.current.left += x*ratioChanged
            scrollPosition.current.top += y*ratioChanged
            whiteboardContainerRef.current.scrollLeft = scrollPosition.current.left
            whiteboardContainerRef.current.scrollTop = scrollPosition.current.top 
            
        }else{
            whiteboardContainerRef.current.scrollLeft = scrollPosition.current.left
            whiteboardContainerRef.current.scrollTop = scrollPosition.current.top 
        }
    }


    //白板尺寸改變
    useEffect(() => {
        setImageInkColorPickerInfo({left: 0, top: 0, isActive: false})
        setImageInkStrokeWidthSliderInfo({left: 0, top:0, isActive: false, value: 0})
        const ratioChanged = ratio - previousRatio.current 
        
        if(isTriggerByFitScreen.current === true){
            whiteboardContainerRef.current.style.overflow = 'auto'
            setTimeout(() => {
                isTriggerByFitScreen.current = false
            }, 100)
        }else if(isTriggerByFollow.current === true){
            setTimeout(() => {
                isTriggerByFollow.current = false
            }, 100)
        }else{
            ratioChangeAndScrollToCertainPosition(ratioChanged)    
            
        }
        previousRatio.current = ratio


    }, [ratio])

    const resetViewPort = (w, h) => {
        const scaleWidth = mathRound(w*ratio)
        const scaleHeight = mathRound(h*ratio)
    
        
        whiteboardRef.current.style.width = `${scaleWidth}px`
        whiteboardRef.current.style.height = `${scaleHeight}px`
        whiteboardWrapperRef.current.style.width = `${scaleWidth+whiteboardWrapperPaddingTwice}px` 
        whiteboardWrapperRef.current.style.height = `${scaleHeight+whiteboardWrapperPaddingTwice}px` 

        whiteboardRef.current.setAttribute('viewBox', `0 0 ${w} ${h}`)  
        
        
       
        
        if(w > 0 && h > 0){
            debounceUpdateWhiteboardResetTime()
        }
    
    }
    const validateWidth = useCallback((value) => {
        if(isNaN(value)){
            return false
        }
        if(value < whiteboardMinSize) return  false
        if(value > whiteboardMaxSize) return false
        return true
    })

    const validateHeight = useCallback((value) => {
        if(isNaN(value)){
            return false
        }
        if(value < whiteboardMinSize) return false
        if(value > whiteboardMaxSize) return false
        return true
    })
   
    const updateSize = (w, h) => {
        if(w && validateWidth(w)){
            h = Math.round(sizeRef.current.lastElementChild.value)
            setSize({width: Math.round(w), height: h})
            changeMinimapSize(Math.round(w), h)
            debounceUpdateWhiteboardResetTime()
            debounceUpdateMiniMapThumbnail()
        }else if(h && validateHeight(h)){
            w = Math.round(sizeRef.current.firstElementChild.value)
            setSize({width: w, height: Math.round(h)})
            changeMinimapSize(w, Math.round(h))
            debounceUpdateWhiteboardResetTime()
            debounceUpdateMiniMapThumbnail()
        }else{
            reachSizeLimit.current = true
            return
        }
        reachSizeLimit.current = false
    }
    
    useEffect(() => {
        if(size?.width && size?.height){
            console.log(size)
            const currPageID = useRoomChatStore.getState().currPageID
            setPageDetailSizeByPageId(currPageID, size.width, size.height)
            resetViewPort(size.width, size.height)
            if(useUserConnectionStore.getState().token){
                apiUpdatePage(cookies.userInfo.roomId, currPageID , size.width, size.height)
            }
            
            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.roomSizeChanged, {width: size.width, height: size.height, currPageID: useRoomChatStore.getState().currPageID})
                useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event)) 
            }
        }
    }, [size])

    const scrollToChangePage = () =>{
        const {scrollTopMax} = getScrollLeftMaxAndScrollTopMax(whiteboardContainerRef.current)
        if(scrollTopMax === whiteboardContainerRef.current.scrollTop && lastScrollTop.current !== whiteboardContainerRef.current.scrollTop){
        
            
        }else if(scrollTopMax === whiteboardContainerRef.current.scrollTop && lastScrollTop.current === whiteboardContainerRef.current.scrollTop){
            console.log('change page')

        }else{
           
        }
        lastScrollTop.current = whiteboardContainerRef.current.scrollTop


            
        // console.log( whiteboardContainerRef.current.scrollTop)
        // if(scrollTopMax === 0){
        //     triggerChange.current = false
        //     debounceChangePage()
            
        // }if(!triggerChange.current && whiteboardContainerRef.current.scrollTop === scrollTopMax){
        //     triggerChange.current = true
        // }else if(triggerChange.current === true){
            
        //     triggerChange.current = false
        //     debounceChangePage()
            
        // }

    }
    const debounceScrollToChangePage = useCallback(_debounce(scrollToChangePage, 300), [])
    const debounceSetSize = useCallback(_debounce(updateSize, 300), [])
    const debounceUpdateMiniMapThumbnail = useCallback(_debounce(() => {
        const {base64, pageId} = getCurrentPageSVGbase64String()
        updateMiniMapThumbnail(base64)
        setThumbnailByPageId(useRoomChatStore.getState().currPageID, base64)
    }, 100), [])
    const debounceUpdateWhiteboardResetTime = useCallback(_debounce(useRoomChatStore.getState().updateWhiteboardSizeResetTime, 10))
    // const debounceResizeMinimap = useCallback(_debounce())
    // const throttleZoomIn = useCallback(_throttle(zoomIn, 200, {leading: true, trailing: false}), [])
    // const throttleZoomOut = useCallback(_throttle(zoomOut, 200, {leading: true, trailing: false}), [])

    const fillInWindow = useCallback((w, h) => {
        if(!w && !h) return 
        
        const boundingRect = whiteboardContainerRef.current.getBoundingClientRect()
        const targetWidth = boundingRect.width - whiteboardWrapperPaddingTwice - 2  // left and right 1px border
        let targetHeight = boundingRect.height - whiteboardWrapperPaddingTwice- 1 // bottom 1px border
        if(cookies.userInfo.hardware ==='true'){
            targetHeight-- //多了top 1px border
        }
        
        const widthHeightRatio = w/h
        let scaleWidth
        let scaleHeight
        let ratio
        
        scaleWidth = targetWidth 
        scaleHeight = targetWidth/widthHeightRatio
        ratio = scaleWidth/w
        if(scaleHeight > targetHeight){
            scaleHeight = targetHeight
            scaleWidth = targetHeight*widthHeightRatio
            ratio = scaleHeight/h
        }

        whiteboardRef.current.style.width = `${scaleWidth}px`
        whiteboardRef.current.style.height = `${scaleHeight}px`
        whiteboardWrapperRef.current.style.width = `calc(${scaleWidth}px + ${whiteboardWrapperPaddingTwicePx})` 
        whiteboardWrapperRef.current.style.height = `calc(${scaleHeight}px + ${whiteboardWrapperPaddingTwicePx})` 
        whiteboardRef.current.setAttribute('viewBox', `0 0 ${w} ${h}`)
        
        setRatio(ratio)
        isTriggerByFitScreen.current = true
        debounceUpdateWhiteboardResetTime()
    })

    const onErrorCheck = () => {
        if(reachSizeLimit.current){
            reachSizeLimit.current = false
            sizeRef.current.firstElementChild.value = size.width
            sizeRef.current.lastElementChild.value = size.height
            changeMinimapSize(size.width, size.height)
            customAlert(t('room.whiteobardSizeReachLimit'))
        }
    }
    
    const parseStylePercent = (style) => {
        let {left, top, width, height} = style
        left = parseFloat(left) || 0
        top = parseFloat(top) || 0
        width = parseFloat(width) || 100
        height = parseFloat(height) || 100

        return {left, top, width, height}
    }

    const moveMyViewportTo = (opponentLeftPercent, opponentTopPercent, opponentWidthPercent, opponentHeightPercent, isFinished = false) => {
        const myMinimap = document.querySelector('.my-minimap')
        const {width: myWidthPercent, height:myHeightPercent, left, top} = parseStylePercent(myMinimap.style)
        // 若使寬度%相同：使myWidthPercent = opponentWidthPercent
        let heightPercentAfterChanged = myHeightPercent * (opponentWidthPercent/myWidthPercent)
        let leftPercentResult, topPercentResult
        if(heightPercentAfterChanged >= opponentHeightPercent){
            if(heightPercentAfterChanged < 0) heightPercentAfterChanged = 0
            else if(heightPercentAfterChanged > 100) heightPercentAfterChanged = 100
            isTriggerByFollow.current = true
           
            if(opponentLeftPercent + opponentWidthPercent > 100){
                leftPercentResult = 100 - opponentWidthPercent
            }else{
                leftPercentResult = opponentLeftPercent
            }

            const verticalMiddlePercent = opponentTopPercent + opponentHeightPercent/2
            const topPercent = verticalMiddlePercent - heightPercentAfterChanged/2

            
            if(topPercent + heightPercentAfterChanged > 100){
                topPercentResult = 100 - heightPercentAfterChanged
            }else if(topPercent < 0){
                topPercentResult = 0
            }else{
                topPercentResult = topPercent
            }
            
            
            // myMinimap.style.left = `${leftPercentResult}%`
            // myMinimap.style.top = `${topPercentResult}%`

            const h = Math.round(sizeRef.current.lastElementChild.value)
            const w = Math.round(sizeRef.current.firstElementChild.value)

            let newRatio = useRoomChatStore.getState().ratio/(opponentWidthPercent/myWidthPercent)
            whiteboardRef.current.style.width = `${w*newRatio}px`
            whiteboardRef.current.style.height = `${h*newRatio}px`
            whiteboardWrapperRef.current.style.width = `${w* newRatio+whiteboardWrapperPaddingTwice}px` 
            whiteboardWrapperRef.current.style.height = `${h* newRatio+whiteboardWrapperPaddingTwice}px` 
            whiteboardRef.current.setAttribute('viewBox', `0 0 ${w} ${h}`)

            setRatio(newRatio) 
            
            debounceUpdateMiniMapThumbnail()
            // debounceUpdateWhiteboardResetTime()
            const {diffX, diffY, containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize} = minimapRatioCalcluate()
            const { leftResult, topResult, widthResult, heightResult} = getCurrentRatioBaseOnContainer(containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize)
            myMinimap.style.left = `${leftResult}%`
            myMinimap.style.width = `${widthResult}%`
            myMinimap.style.top  = `${topResult}%`
            myMinimap.style.height = `${heightResult}%`

            scrollToCertainLocation(leftPercentResult, topPercentResult)
            return
        }

        // 若使高度%相同：使myHeightPercent = opponentHeightPercent   
        let widthPercentAfterChanged = myWidthPercent * (opponentHeightPercent/myHeightPercent)
        if(widthPercentAfterChanged >= opponentWidthPercent){
            if(widthPercentAfterChanged < 0) widthPercentAfterChanged = 0
            else if(widthPercentAfterChanged > 100) widthPercentAfterChanged = 100
            isTriggerByFollow.current = true
         
            if(opponentTopPercent + opponentHeightPercent > 100){
                topPercentResult = 100 - opponentHeightPercent
            }else{
                topPercentResult = opponentTopPercent
            }

            const horizontalMiddlePercent = opponentLeftPercent + opponentWidthPercent/2
            const leftPercent = horizontalMiddlePercent - widthPercentAfterChanged/2

            if(leftPercent + widthPercentAfterChanged > 100){
                leftPercentResult = 100 - widthPercentAfterChanged
            }else if(leftPercent < 0){
                leftPercentResult = 0
            }else{
                leftPercentResult = leftPercent
            }
 
            // myMinimap.style.left = `${leftPercentResult}%`
            // myMinimap.style.top = `${topPercentResult}%`


            const h = Math.round(sizeRef.current.lastElementChild.value)
            const w = Math.round(sizeRef.current.firstElementChild.value)
            let newRatio = useRoomChatStore.getState().ratio/(opponentHeightPercent/myHeightPercent)

            whiteboardRef.current.style.width = `${w*newRatio}px`
            whiteboardRef.current.style.height = `${h*newRatio}px`
            whiteboardWrapperRef.current.style.width = `${w* newRatio + whiteboardWrapperPaddingTwice}px` 
            whiteboardWrapperRef.current.style.height = `${h* newRatio + whiteboardWrapperPaddingTwice}px` 
            whiteboardRef.current.setAttribute('viewBox', `0 0 ${w} ${h}`)

            setRatio(newRatio) 
            
            debounceUpdateMiniMapThumbnail()
            // debounceUpdateWhiteboardResetTime()
            const {diffX, diffY, containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize} = minimapRatioCalcluate()
            const { leftResult, topResult, widthResult, heightResult} = getCurrentRatioBaseOnContainer(containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize)
            myMinimap.style.left = `${leftResult}%`
            myMinimap.style.width = `${widthResult}%`
            myMinimap.style.top  = `${topResult}%`
            myMinimap.style.height = `${heightResult}%`
            scrollToCertainLocation(leftPercentResult, topPercentResult)

            myMinimap.style.transition = null;
        }   
    }

    useEffect(() =>{
        if(canControlPage(cookies.userInfo.accountType, isControlByInviter)){
            setIsReadonly(false)
        }else{
            document.querySelectorAll('.thumbnail-open').forEach(d => {
                d.classList.remove('thumbnail-open')
            })
            setIsReadonly(true)
        }

        if(pageControlChangerRef.current){
            if(cookies.userInfo.accountType === AccountType.inviter && isControlByInviter){
                pageControlChangerRef.current.selectedIndex  = 0
            }else{
                pageControlChangerRef.current.selectedIndex  = 1
            }
        }

    }, [cookies.userInfo.accountType, isControlByInviter])

    const onPageControlChanged = (e) => {
        if(e.target.value === AccountType.inviter){
            setIsControlByInviter(true)
            apiUpdateIsControlByInviter(cookies.userInfo.roomId, true)
        }else{
            setIsControlByInviter(false)
            apiUpdateIsControlByInviter(cookies.userInfo.roomId, false)
            
        }
    }
    return (
        <div className='whiteboard-options-control'>
            <span id='whiteboard-size' ref={sizeRef}>{t('room.whiteboardSize')}{t('general.colon')} 
                W <input type='number' defaultValue={size?.width} style={{marginRight: '1em'}} onChange={(e) => {debounceSetSize(e.target.value, null)}} onBlur={onErrorCheck} />  
                H <input type='number' defaultValue={size?.height} onChange={(e) => debounceSetSize(null, e.target.value)} onBlur={onErrorCheck}/>
            </span>
            <div className="control-icon">
                <span data-tooltip={t('room.zoomOut')} style={{display: 'block'}} className='zoomOut' onClick={() => {
                    isTriggerByFitScreen.current = false
                    isTriggerByFollow.current = false
                    const {width, height} = whiteboardRef.current.style
                    if(Math.round(width.replace('px', '')) < whiteboardMinSize/2 || 
                    Math.round(height.replace('px', '')) < whiteboardMinSize/2){
                        return
                    }
                    zoomOut()
                }}/>   
            </div>
            <div className="control-icon">
                <span data-tooltip={t('room.zoomIn')} style={{display: 'block'}} className='zoomIn' onClick={() => {
                    isTriggerByFitScreen.current = false
                    isTriggerByFollow.current = false
                    const {width, height} = whiteboardRef.current.style

                    if(Math.round(width.replace('px', '')) > whiteboardMaxSize*10 || 
                    Math.round(height.replace('px', '')) > whiteboardMaxSize*10){
                        return
                    }
                    zoomIn()
                }}/>
            </div>
            <div className="control-icon">
                <span data-tooltip={t('room.actualSize')} style={{display: 'block'}} className='actualSize' onClick={() => {
                    isTriggerByFitScreen.current = false
                    isTriggerByFollow.current = false
                    setRatio(1)
                }}/>
            </div>
            <div className="control-icon">
                <span data-tooltip={t('room.fillInWindow')} style={{display: 'block'}} className='fillInWindow' 
                onClick={() => {
                    isTriggerByFollow.current = false
                    fillInWindow(Math.round(sizeRef.current.firstElementChild.value), Math.round(sizeRef.current.lastElementChild.value))
                    debounceUpdateWhiteboardResetTime()
                }}/>
            </div>
            <div className="control-icon">
                <span data-tooltip={t('room.syncViewport')} style={{display: 'block'}} className='syncViewport' onClick={async () => {
                    isTriggerByFitScreen.current = false
                    const opponentMinimap = document.querySelector('.opponent-minimap')
                    if(!opponentMinimap) return
                    if(opponentMinimap?.classList.contains('displayNone') === false){
                        let callTimes = 0
                        while(callTimes < syncViewPortOptimizationTimes){
                            let result =parseStylePercent(opponentMinimap.style)
                            moveMyViewportTo(result.left, result.top, result.width, result.height)
                            await sleep(5)
                            callTimes++
                        }
                        
                        if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                            const event = new StrokeEvent(StrokeEventType.opponentMinimapChanged, document.querySelector('.my-minimap').style)
                            useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))    
                        }
                    }
                }}/>
            </div>
            <Suspense fallback={<></>}>
                <PageToolbar 
                    setRoomPageSize={setRoomPageSize} isReadonly={isReadonly}
                />
            </Suspense>
            {cookies.userInfo.accountType === AccountType.inviter && 
                <div className='page-control-changer'>
                    <span>{t('room.pageControl')}</span>
                    <select ref={pageControlChangerRef} onChange={onPageControlChanged} defaultValue={isControlByInviter ? AccountType.inviter : AccountType.invitee}>
                        <option value={AccountType.inviter} >{t('room.you')}</option>
                        <option value={AccountType.invitee} >{t('room.invitee')}</option>
                    </select>
                </div>
            }
        </div>
    )
}

export default React.memo(WhiteboardOptionsControl)
