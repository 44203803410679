import {useEffect} from 'react'
import { ToolBarTool, useRoomChatStore, useToolbarSettingsStore } from '../store'
import { updatePointer } from './useEvent'

const useContextMenu = () => {
    
    
    
    useEffect(() => {
        const onContextMenu = (e) => {
            if(e.target.classList?.contains('control-element') && 
                useToolbarSettingsStore.getState().currentActiveTool === ToolBarTool.cursor && 
                useRoomChatStore.getState().currentActiveShape){
                const{x, y} = updatePointer(e)
                // const shape = document.getElementById(useRoomChatStore.getState().currentActiveShape)
                useRoomChatStore.getState().updateShapeMoreButtonInfo({x, y, isOpen: true})

                
            }else if(e.target.classList?.contains('thumbnail-img')){
                e.target.nextElementSibling.click()
            }else{
                console.log(e.target)
            }
            
            if (process.env.NODE_ENV !== 'development'){
                e.preventDefault()
            }
            // e.preventDefault()
        }

        window.addEventListener('contextmenu', onContextMenu, false)
        return () => {
            window.removeEventListener('contextmenu', onContextMenu, false)
        }
    },[])
}

export default useContextMenu
