import axios from 'axios'
import {EMPTY_PAGES_ID, useUserConnectionStore} from './store'




const resourceRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/Resource/`
})

const roomRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/Room/`
})

const pageRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/Page/`
})

const historyRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/History/`
})

const drawObjectRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/DrawObject/`
})

const accountsRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/Accounts/`
})

const systemRequest = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/System/`
})


//Resource api
export const apiUploadResource = (data) => resourceRequest.post('', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiGetResource = (resourceId) => resourceRequest.get(``, {
    params: {resourceId},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})


//Room api
export const apiVerifyRoom = (roomID) =>  roomRequest.get('Vertify', {
    params: {roomID}
})
export const apiGetRoomInfo = (roomID) =>  roomRequest.get('GetRoominfo', {
    params: {roomID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiJoinRoom = (data) =>  roomRequest.post('Join', data)
export const apiLeaveRoom = (data) => roomRequest.post('Leave', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiMsgToOther = (data) => roomRequest.post('MsgToOther', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiReady = (data) => roomRequest.post('Ready', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiRoomClear = (roomID) => roomRequest.post('Clear', null, {
    params: {roomID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiRoomKick = (message) => roomRequest.post('kick', {message}, {
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token,
        'Content-Type': 'application/json'
    }
})
export const apiGetIsControlByInviter = (roomID) =>  roomRequest.get('IsControlByInviter', {
    params: {roomID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiUpdateIsControlByInviter = (roomID, IsControlByInviter) => roomRequest.put('IsControlByInviter', null, {
    params:{roomID, IsControlByInviter},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})

//Page api
export const apiGetPagesInfo = (roomID) => pageRequest.get('GetPagesInfo',{
    params: {roomID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiGetPage = (roomID, pageID) => pageRequest.get('', {
    params: {roomID, pageID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiUpdatePage = (roomID, pageID, Width, Height) => pageRequest.put('', null, {
    params:{roomID, pageID, Width, Height},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiCreatePage = (roomID, previousPageId) => pageRequest.post('', null, {
    params:{
        roomID, 
        pageID: previousPageId || EMPTY_PAGES_ID, 
        pagelocation: previousPageId ? 'AFTER' : 'FIRST'
    },
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})

export const apiUndeletePage = (data) => pageRequest.post('Undelete', data, {
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})

export const apiDeletePage = (roomID, pageID) => pageRequest.delete('', {
    params: {roomID, pageID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})

export const apiSetThumbnail = (data) => pageRequest.post('setThumbnail', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})

export const apiGetThumbnail = (data) => pageRequest.post('getThumbnail', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})

export const apiSetCurrPage = (roomID, pageID) => pageRequest.post('setCurrPage', null, {
    params: {roomID, pageID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
}) 
export const apiSetPageOrder = (data) => pageRequest.post('PageOrder', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})




//History api
export const apiAddHistory = (data) => historyRequest.post('', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiDeleteHistory = (data) => historyRequest.post('Del', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiGetHistory = (roomID, accountID, pageIdx) => historyRequest.get('', {
    params: {roomID, accountID, pageIdx},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})


//DrawObject api
export const apiAddDrawObject = (data, isGeneralCreationTime= true) => drawObjectRequest.post('', data, {
    params:{isGeneralCreationTime},
    headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiDeleteDrawObject = (drawObjectID) => drawObjectRequest.delete('', {
    params: {drawObjectID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiUpdateDrawObject = (data) => drawObjectRequest.put('', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiGetDrawObject = (drawObjectID) => drawObjectRequest.get('', {
    params: {drawObjectID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiGetDrawObjectList = (roomID, pageID) => drawObjectRequest.get('List', {
    params: {roomID, pageID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})

export const apiGetDrawObjectsDetail = (objectIds) => drawObjectRequest.post('Objs', objectIds, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})

export const apiExchangeDrawObjectsCreationTime = (data) => drawObjectRequest.post('ExchangeCreationTime', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})
export const apiUpdateDrawObjectCreationTime = (data) => drawObjectRequest.put('UpdateCreationTime', data, {headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})


//DrawObject api Multi

export const apiAddDrawObjectMulti = (datas, isGeneralCreationTime = true) => drawObjectRequest.post('Multi', datas, {
    params:{isGeneralCreationTime},
    headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})

export const apiDeleteDrawObjectMulti = (objectIds) => drawObjectRequest.post('DelMulti', objectIds, {
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
})
export const apiUpdateDrawObjectMulti = (datas) => drawObjectRequest.put('Multi', datas, {
    headers: {
    'Auth-Token': useUserConnectionStore.getState().token
}})




//accounts api
export const apiUpdateConnectId = (AccountID, connectID) => accountsRequest.post('UpdateConnectID', null, {
    params: {AccountID, connectID},
    headers: {
        'Auth-Token': useUserConnectionStore.getState().token
    }
}) 

//system api
export const apiSystemGetVersion = () => systemRequest.get('getVersion')
export const apiSystemGetSetting = (token) => systemRequest.get('getSetting', {
    headers: {
        'Auth-Token': token
    }
})


