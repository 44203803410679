import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-http-backend'
import tw from '../locales/zh-tw/translation.json'
import cn from '../locales/zh-cn/translation.json'
import ja from '../locales/ja/translation.json'
import en from '../locales/en/translation.json'
import fr from '../locales/fr/translation.json'
import de from '../locales/de/translation.json'
import it from '../locales/it/translation.json'
import es from '../locales/es/translation.json'

const resources = {
    'zh_tw':  {
        translation: tw
    },
    'zh_cn':  {
        translation: cn
    },
    en:  {
        translation: en
    },
    ja:  {
        translation: ja
    },
    fr: {
        translation: fr
    },
    de: {
        translation: de
    },
    it: {
        translation: it
    },
    es: {
        translation: es
    }
}
const options = {
    order: ['myQueryStringDetector', 'cookie' ,'myNavigatorDetector'],
    lookupQuerystring: 'lang',
    caches: ['cookie']
}
const lngDetector = new LanguageDetector()
lngDetector.addDetector({
    name: 'myQueryStringDetector',
    lookup(options){
        const lang = new URLSearchParams(window.location.search).get('lang')?.toLowerCase()
        if(Object.keys(resources).includes(lang)) return lang
        return null
    }
})
lngDetector.addDetector({
    name: 'myNavigatorDetector',
    lookup(options){
        var lang = navigator.language.toLowerCase()
        if(lang.includes('zh-tw')){
            return 'zh_tw'
        }
        if(lang.includes('zh-cn')){
            return 'zh_cn'
        }
        if(lang.includes('ja')){
            return 'ja'
        }
        if(lang.includes('fr')){
            return 'fr'
        }
        if(lang.includes('de')){
            return 'de'
        }
        if(lang.includes('it')){
            return 'it'
        }
        if(lang.includes('es')){
            return 'es'
        }
        
        return "en"
    }
})

i18n
    .use(XHR)
    .use(lngDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources,
        fallbackLng: 'en',
        supportedLngs:['en', 'ja', 'zh_tw', 'zh_cn', 'fr', 'de', 'it', 'es'],
        interpolation: {
            escapeValue: false
        },
        debug: false,
    })

export default i18n