import {useEffect} from 'react'
import {useUpdateEffect} from 'react-use'
import {drag} from 'd3-drag'
import { select, selectAll } from 'd3-selection'
import {ToolBarTool, useToolbarSettingsStore ,useRoomChatStore, useUserConnectionStore, StrokeEvent, StrokeEventType, minFontSizeRem } from '../store'
import { getRectControlElementPositionInfo, getLineControlElementPositionInfo } from '../components/ShapeControlElement'
import { getResizeDifferenceWithRotate } from '../utils'
import { updatePointer } from './useEvent'
import { parse } from 'transform-parser'
import { apiUpdateDrawObject } from '../api'

export const modifyPathAction = {
    drag: 'drag',
    resize: 'resize',
    rotate: 'rotate'
}
export const setPathModifyHistoryLastStep = (shapeId, lastStep) => {
    if(historyTemp[shapeId]){
        historyTemp[shapeId].lastStep = lastStep
    }
}

const historyTemp = {}
const minimumSize = 30
const minimumSizeOfImageInk = 100
const textboxMinSize = 60
const lineMinimumLength = 30

// let lastHistoryId = null

const dragShapeSelector = state => state.dragShape
const dragEndSelector = state => state.dragEnd
const onResizeSelector = state => state.onResize
const resizeEndSelector = state => state.resizeEnd
const onRotateSelector = state => state.onRotate
const rotateEndSelector = state => state.rotateEnd
const shapesSelector = state => state.shapes
const imageInksSelector = state => state.imageInks
const updateCurrentActiveShapeSelector = state => state.updateCurrentActiveShape
const updateShapeMoreButtonInfoSelector = state => state.updateShapeMoreButtonInfo
const updateShapeMoreButtonInfoIsOpenSelector = state => state.updateShapeMoreButtonInfoIsOpen
const setImageInkColorPickerInfoSelector = state => state.setImageInkColorPickerInfo
const setImageInkStrokeWidthSliderInfoSelector = state => state.setImageInkStrokeWidthSliderInfo


let touchduration = 50
let touchstartTimeStamp = 0
export const parseCurrentTransformValue = (transform) => {
    try{
    const transformValue = parse(transform)
    
        return {
            translateX: (transformValue.translate && transformValue.translate[0]) ? transformValue.translate[0] : 0,
            translateY: (transformValue.translate && transformValue.translate[1]) ? transformValue.translate[1] : 0,
            rotate: transformValue.rotate ? parseFloat(transformValue.rotate.replace('deg', '')) : 0
        }
    }catch(e){
        return{
            translateX: 0,
            translateY: 0,
            rotate: 0
        }
    }
}
const parseRemValue = (remValue) => {
    if(remValue ==null || remValue === '') 
        return minFontSizeRem
    return Number(remValue.substring(0, remValue.indexOf('rem')))
}
export const updateShapesContentToServer = async (shapeId, isimageink) => {
    const shapeContent = isimageink ? useRoomChatStore.getState().imageInks.find(s => s.id === shapeId) : useRoomChatStore.getState().shapes.find(s => s.id === shapeId)
    if(shapeContent){
        await apiUpdateDrawObject({
            id: shapeId,
            content: JSON.stringify(shapeContent)
        })
    }
}
let currentOperationId = null
const useModifyPathHandler = () => {
    const dragShape = useRoomChatStore(dragShapeSelector)
    const dragEnd = useRoomChatStore(dragEndSelector)
    const onResize = useRoomChatStore(onResizeSelector)
    const resizeEnd = useRoomChatStore(resizeEndSelector)
    const onRotate = useRoomChatStore(onRotateSelector)
    const rotateEnd = useRoomChatStore(rotateEndSelector)
    const shapes = useRoomChatStore(shapesSelector)
    const imageInks = useRoomChatStore(imageInksSelector)
    const updateCurrentActiveShape = useRoomChatStore(updateCurrentActiveShapeSelector)
    const updateShapeMoreButtonInfo = useRoomChatStore(updateShapeMoreButtonInfoSelector)
    const updateShapeMoreButtonInfoIsOpen = useRoomChatStore(updateShapeMoreButtonInfoIsOpenSelector)
    const setImageInkColorPickerInfo = useRoomChatStore(setImageInkColorPickerInfoSelector)
    const setImageInkStrokeWidthSliderInfo = useRoomChatStore(setImageInkStrokeWidthSliderInfoSelector)

    const getTransformValue = (shape) => {
        let s
        if(shape instanceof SVGForeignObjectElement){
            s = shape.parentElement
        }else{
            s = shape
        }

        return s.style.transform.length > 0 ? s.style.transform : null
    }
    const getCurrentRotateDeg = (shape) => {
        let s
        if(shape instanceof SVGForeignObjectElement){
            s = shape.parentElement
        }else{
            s = shape
        }

        if(s.style.transform.length <= 0) return null
        const rotateParams = 'rotate('
        const indexOfRotateParams = s.style.transform.indexOf(rotateParams)
        if(indexOfRotateParams < 0) return null
    
        return s.style.transform.substring(indexOfRotateParams+ rotateParams.length, s.style.transform.indexOf('deg'))
    }


    const getAttributeByShape = (shape, forAttribute) => {
        if(!shape) return 

        let left, top, width, height, rx, ry, cx, cy, x1, y1, x2, y2
        const strokeWidth = Number(shape.style['stroke-width'].replace('px',''))
    
        if(shape instanceof SVGRectElement || shape instanceof SVGImageElement){
            // transform = shape.getAttribute('transform').split(' ')[0]
            left = Number(shape.getAttribute('x'))
            top = Number(shape.getAttribute('y'))
            width = Number(shape.getAttribute('width'))
            height = Number(shape.getAttribute('height'))
            rx = width/2
            ry = height/2
            cx = left+rx
            cy = top+ry
    
            if(forAttribute){
                return {x: left, y: top, width, height}
            }
        }else if(shape instanceof SVGEllipseElement){
            cx = Number(shape.getAttribute('cx'))
            cy = Number(shape.getAttribute('cy'))
            rx = Number(shape.getAttribute('rx'))
            ry = Number(shape.getAttribute('ry'))
            left = cx-rx
            top = cy-ry
            width = 2*rx
            height = 2*ry 
            if(forAttribute){
                return {cx, cy, rx, ry}
            }
        }else if(shape instanceof SVGLineElement){
            x1 = Number(shape.getAttribute('x1'))
            y1 = Number(shape.getAttribute('y1'))
            x2 = Number(shape.getAttribute('x2'))
            y2 = Number(shape.getAttribute('y2'))
            cx = (x1+x2)/2
            cy = (y1+y2)/2
            if(forAttribute){
                return {x1, y1, x2, y2}
            }
        }else if(shape instanceof SVGForeignObjectElement){
            //ForeignObject x and y
            const dragRect = shape.parentElement.previousElementSibling
         
            left = Number(dragRect.getAttribute('x'))
            top = Number(dragRect.getAttribute('y'))
            width = Number(dragRect.getAttribute('width'))
            height = Number(dragRect.getAttribute('height'))
            rx = width/2
            ry = height/2
            cx = left+rx
            cy = top+ry
            //width 
            // width = Number(boundingClientRect.width.replace('px', ''))
            // height = Number(boundingClientRect.height)
            // rx = width/2
            // ry = height/2
            // cx = left+rx
            // cy = top+ry
            if(forAttribute){
                return {x: left, y: top, width, height}
            }

        }
        return {
            left, top, width, height, rx, ry, cx, cy, x1, y1, x2, y2, strokeWidth
        }
    }
    const setCursorAttribute = (shape, current) => {
        const whiteboard = document.querySelector('.whiteboard')
        if(!whiteboard.dataset.current && !current) return 

        whiteboard.dataset.current = current
        shape.dataset.current = current
        if(shape.nextElementSibling){
            shape.nextElementSibling.dataset.current = current
        }
    
        const controlElement = document.getElementById(`${shape.id}-control`)
        if(!controlElement) return
        controlElement.children.forEach(ele => {
            ele.dataset.current = current
        })
        
    }


    useUpdateEffect(() => {
       
        const onDragStartEvent = (x, y, selection, touchId) => {
            if(!selection) return 

            const shape = selection.shape
            if(!shape) return 
            if(currentOperationId && document.getElementById(currentOperationId)){
               return 
            }else{
                currentOperationId = shape.id
            }
            updateCurrentActiveShape(shape.id, shape.dataset.isimageink)
            
            const shapeAttribute = getAttributeByShape(shape) 
            selection.dx = shapeAttribute.cx - x
            selection.dy = shapeAttribute.cy - y

            if(touchId !=null ) selection.touchId = touchId
            selection.modifyPathAction = modifyPathAction.drag
            setCursorAttribute(shape, 'drag')
            shape.hasChanged = false
            // drageStart(shape.id, {cx: x+selection.dx, cy: y+selection.dy})
            // if(dataChannel){
            //     const event = new StrokeEvent(StrokeEventType.opponentBeginDrag, {
            //         shapeId: shape.id, dx: selection.dx, dy: selection.dy, touchId, modifyPathAction: modifyPathAction.drag
            //     })
            //     dataChannel.send(JSON.stringify(event))
            // }
        }
        const onDragEvent = (x, y, selection, touchId) => {
            if(!selection) return 
            if(selection.touchId === touchId || touchId == null){
                selection.hasDrag = true
                const shape = selection.shape
                if(!shape) return 
                shape.hasChanged = true
                // const shapeAttribute = getAttributeByShape(shape) 
                // const rp = getRotatePoint(getCurrentRotateDeg(shape), shapeAttribute.cx, shapeAttribute.cy, x, y)
                // x = rp.x
                // y = rp.y
                const {currentPageHeight: maxHeight, currentPageWidth: maxWidth} = useRoomChatStore.getState()
                const {translateX, translateY, rotate} =  parseCurrentTransformValue(shape.style.transform)
                let cx = x+selection.dx
                let cy = y+selection.dy
                if(cx+translateX < 0) cx = -translateX
                if(cy+translateY < 0) cy = -translateY 
                if(cx+translateX > maxWidth) cx = maxWidth-translateX
                if(cy+translateY > maxHeight) cy = maxHeight-translateY

                dragShape(shape.id, {cx, cy}, shape.dataset.isimageink)
                if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                    const event = new StrokeEvent(StrokeEventType.opponentOnDrag, {
                        shapeId: shape.id, cx, cy
                    })
                    useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                }
            }            
        }
        const onDragEndEvent = (shapeId) => {
            if(!shapeId) return 
            const shape = document.getElementById(shapeId)
            if(!shape) return 
            const svgAttributesPair = getAttributeByShape(shape, true)
            setCursorAttribute(shape, null)
            const transform = getTransformValue(shape)
            dragEnd(shapeId, svgAttributesPair, transform, shape.dataset.isimageink)

            currentOperationId = null
            if(shape.hasChanged){
                updateShapesContentToServer(shapeId, shape.dataset.isimageink)

                if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                    const event = new StrokeEvent(StrokeEventType.opponentCompleteDrag, {shapeId, svgAttributesPair, transform, isImageInk: shape.dataset.isimageink})
                    useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                }
            }
            return shape
        }

        const onResizeStartEvent = (x, y, selection, touchId, className ) => {
            const shape = selection.shape
            if(!shape) return 
            if(currentOperationId && document.getElementById(currentOperationId)){
                return 
             }else{
                 currentOperationId = shape.id
             }

            updateCurrentActiveShape(shape.id, shape.dataset.isimageink)
            if(shape instanceof SVGRectElement || shape instanceof SVGEllipseElement || shape instanceof SVGImageElement){
                const {left, top, width, height} =  getAttributeByShape(shape)
                const {translateX, translateY, rotate} =  parseCurrentTransformValue(shape.style.transform)
                
                shape.originalWidth = width
                shape.originalHeight = height
                shape.originalMouseX = x
                shape.originalMouseY = y
                shape.controlElementClassName = className
                shape.translateX = translateX
                shape.translateY = translateY
                shape.rotate = rotate
                shape.originalLeft = left
                shape.originalTop = top
                shape.originalCenter = [(left+width/2), (top+height/2)]
                shape.orginalTranslateX = shape.translateX
                shape.orginalTranslateY = shape.translateY
            }else if(shape instanceof SVGForeignObjectElement){
                const dragRect = shape.parentElement.previousElementSibling
                const {left, top, width, height} = getAttributeByShape(dragRect)
                const {translateX, translateY, rotate} =  parseCurrentTransformValue(dragRect.style.transform)

                // console.log(shape)
                // dragRect.originalScale = parseScaleValue(dragRect.shape.firstElementChild.style.getPropertyValue('--textscale'))
                dragRect.originalFontSizeRem = parseRemValue(shape.firstElementChild.style.fontSize)
                dragRect.originalWidth = width
                dragRect.originalHeight = height
                dragRect.originalMouseX = x
                dragRect.originalMouseY = y
                dragRect.controlElementClassName = className
                dragRect.translateX = translateX
                dragRect.translateY = translateY
                dragRect.rotate = rotate
                dragRect.originalLeft = left
                dragRect.originalTop = top
                dragRect.originalCenter = [(left+width/2), (top+height/2)]
                dragRect.orginalTranslateX = dragRect.translateX
                dragRect.orginalTranslateY = dragRect.translateY
            }else if(shape instanceof SVGLineElement){
                shape.controlElementClassName = className
            }

            if(touchId != null) selection.touchId = touchId
            selection.modifyPathAction = modifyPathAction.resize
            setCursorAttribute(shape, shape.controlElementClassName)
        }
        const onResizeEvent = (x, y, selection, touchId ) => {
            if(selection.touchId === touchId || touchId == null){

                
                const shape = selection.shape
                if(!shape) return 
                let left, top, rx, ry

                if(shape instanceof SVGForeignObjectElement){
                    const dragRect = shape.parentElement.previousElementSibling
                    const shapeAttribute = getAttributeByShape(dragRect)
                    const degree = getCurrentRotateDeg(dragRect)
                    const { differenceOfX, differenceOfY } = getResizeDifferenceWithRotate(dragRect.originalMouseX, dragRect.originalMouseY, x, y, degree, 
                        dragRect.originalCenter[0], dragRect.originalCenter[1], dragRect.orginalTranslateX, dragRect.orginalTranslateY)
                    let fontSizeRem = dragRect.originalFontSizeRem
                        
                    if(dragRect.controlElementClassName === 'e'){//東
                        const width = dragRect.originalWidth + differenceOfX
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        
                        if(width > textboxMinSize){
                            rx = width/2
                        } 
                        // shape.dataset.current = shape.controlElementClassName
                        // shape.nextElementSibling.dataset.current = shape.controlElementClassName
                        
                    }else if(dragRect.controlElementClassName === 'se'){ //東南
                        const width = dragRect.originalWidth + differenceOfX
                        const height = width / shape.dataset.widthheightratio
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        
                        const newFrontSizeRem = (width/dragRect.originalWidth)*dragRect.originalFontSizeRem
                        if(width > height){
                            if(newFrontSizeRem > minFontSizeRem){
                                rx = width/2
                                ry = (width / shape.dataset.widthheightratio)/2
                                // shape.firstElementChild.style.setProperty('--fontSize', `${fontSize}rem`)
                                fontSizeRem = newFrontSizeRem
                            }else{
                                fontSizeRem = minFontSizeRem
                            }
                        }else{
                            if(newFrontSizeRem > minFontSizeRem){
                                rx = width/2
                                ry = (width / shape.dataset.widthheightratio)/2
                                // shape.firstElementChild.style.setProperty('--fontSize', `${fontSize}rem`)
                                fontSizeRem = newFrontSizeRem
                            }else{
                                // shape.firstElementChild.style.setProperty('--fontSize', `${minFontSizeRem}rem`)
                                fontSizeRem = minFontSizeRem
                            }
                        }
                       

                    }else if(dragRect.controlElementClassName === 's'){ //南
                        const height = dragRect.originalHeight + differenceOfY
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry

                        if(height > textboxMinSize){
                            ry = height/2
                        }
                    
                    }else if(dragRect.controlElementClassName === 'sw'){//西南                    
                        let width = dragRect.originalWidth - differenceOfX
                        left = dragRect.originalLeft + differenceOfX
                        let height = width / shape.dataset.widthheightratio
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        left = shapeAttribute.left

                        const newFrontSizeRem = (width/dragRect.originalWidth)*dragRect.originalFontSizeRem
                        if(width > height){
                            if(newFrontSizeRem > minFontSizeRem){
                                rx = width/2
                                ry = height/2 
                                left = dragRect.originalLeft + differenceOfX
                                // shape.firstElementChild.style.setProperty('--fontSize', `${fontSize}rem`)
                                fontSizeRem = newFrontSizeRem
                            }else{
                                // shape.firstElementChild.style.setProperty('--fontSize', `${minFontSizeRem}rem`)
                                fontSizeRem = minFontSizeRem
                                
                            }
                        }else{
                            if(newFrontSizeRem > minFontSizeRem){
                                rx = width/2
                                ry = height/2 
                                left = dragRect.originalLeft + differenceOfX
                                // shape.firstElementChild.style.setProperty('--fontSize', `${fontSize}rem`)
                                fontSizeRem = newFrontSizeRem
                            }else{
                                // shape.firstElementChild.style.setProperty('--fontSize', `${minFontSizeRem}rem`)
                                fontSizeRem = minFontSizeRem
                            }
                        }

                        top = shapeAttribute.top
                    
                    }else if(dragRect.controlElementClassName === 'w'){//西
                        const height = shapeAttribute.height
                        let width = dragRect.originalWidth - differenceOfX
                        left = dragRect.originalLeft + differenceOfX

                        if(width <= textboxMinSize){
                            width = shapeAttribute.width
                            left = shapeAttribute.left
                        }
                        top = shapeAttribute.top
                        rx = width/2
                        ry = height/2
                        

                    }else if(dragRect.controlElementClassName === 'n'){//北
                        const width = shapeAttribute.width
                        let height = dragRect.originalHeight - differenceOfY
                        top= dragRect.originalTop + differenceOfY
                        left = shapeAttribute.left
                        

                        if(height <= textboxMinSize){
                            height = shapeAttribute.height
                            top = shapeAttribute.top
                        }

                        rx = width/2
                        ry = height/2
                        
                    } else if(dragRect.controlElementClassName === 'ne'){//東北
                        let width = dragRect.originalWidth + differenceOfX                   
                        // let height = shape.originalHeight - differenceOfY
                        let height = width / shape.dataset.widthheightratio
                        top = dragRect.originalTop + (dragRect.originalHeight - height)
                        left = shapeAttribute.left
                        
                        
                        const newFrontSizeRem = (width/dragRect.originalWidth)*dragRect.originalFontSizeRem
                        if(width > height){
                            if( newFrontSizeRem < minFontSizeRem){
                                width = shapeAttribute.width
                                height = width / shape.dataset.widthheightratio
                                top = dragRect.originalTop + (dragRect.originalHeight - height)
                                // shape.firstElementChild.style.setProperty('--fontSize', `${minFontSizeRem}rem`)
                                fontSizeRem = minFontSizeRem
                            }else{
                                // shape.firstElementChild.style.setProperty('--fontSize', `${fontSize}rem`)
                                fontSizeRem = newFrontSizeRem
                            }
                        }else{
                            if(newFrontSizeRem < minFontSizeRem){
                                width = shapeAttribute.width
                                height = width / shape.dataset.widthheightratio
                                top = dragRect.originalTop + (dragRect.originalHeight - height)
                                // shape.firstElementChild.style.setProperty('--fontSize', `${minFontSizeRem}rem`)
                                fontSizeRem = minFontSizeRem
                            }else{
                                // shape.firstElementChild.style.setProperty('--fontSize', `${fontSize}rem`)
                                fontSizeRem = newFrontSizeRem
                            }
                        }
                        

                        rx = width/2
                        ry = height/2
                        
                    }else{
                        return
                    }

                    const dCx = left+rx - dragRect.originalCenter[0]
                    const dCy = top+ry - dragRect.originalCenter[1]
                    const radian = -dragRect.rotate*Math.PI/180
                    dragRect.translateX = dCx*(Math.cos(radian) -1 ) + dCy*Math.sin(radian)
                    dragRect.translateY = -dCx*Math.sin(radian) + dCy*(Math.cos(radian)-1)
                    // console.log({dCx, dCy})
                    const transform = `translate(${dragRect.translateX+ dragRect.orginalTranslateX}px, ${dragRect.translateY+dragRect.orginalTranslateY}px) rotate(${dragRect.rotate}deg)`

                    onResize(shape.id, {left, top, rx, ry, cx: left+rx, cy: top+ry, transform, fontSizeRem})
                    if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                        const event = new StrokeEvent(StrokeEventType.opponentOnResize, {
                            shapeId: shape.id, 
                            left, top, rx, ry, cx: left+rx, cy: top+ry, transform, fontSizeRem
                        })
                        useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                    }
                }
                if(shape instanceof SVGImageElement && shape.dataset.isimageink){
                    //keep aspect ratio
                    const shapeAttribute = getAttributeByShape(shape)
                    const degree = getCurrentRotateDeg(shape)
                    const { differenceOfX, differenceOfY } = getResizeDifferenceWithRotate(shape.originalMouseX, shape.originalMouseY, x, y, degree, 
                        shape.originalCenter[0], shape.originalCenter[1], shape.orginalTranslateX, shape.orginalTranslateY)

                    if(shape.controlElementClassName === 'se'){ //東南
                        const width = shape.originalWidth + differenceOfX
                        const height = width / shape.dataset.imageratio
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        
                        if(width > height){
                            if(width > minimumSizeOfImageInk){
                                rx = width/2
                                ry = (width / shape.dataset.imageratio)/2
                            }
                        }else{
                            if(height > minimumSizeOfImageInk){
                                rx = width/2
                                ry = (width / shape.dataset.imageratio)/2
                            }
                        }
                       
                    }else if(shape.controlElementClassName === 'sw'){//西南                    
                        let width = shape.originalWidth - differenceOfX
                        left = shape.originalLeft + differenceOfX
                        let height = width / shape.dataset.imageratio
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        left = shapeAttribute.left

                        if(width > height){
                            if(width > minimumSizeOfImageInk){
                                rx = width/2
                                ry = height/2 
                                left = shape.originalLeft + differenceOfX
                            }
                        }else{
                            if(height > minimumSizeOfImageInk){
                                rx = width/2
                                ry = height/2 
                                left = shape.originalLeft + differenceOfX
                            }
                        }


                        top = shapeAttribute.top
                        // rx = width/2
                        // ry = height/2 
                        
                    
                    }else if(shape.controlElementClassName === 'ne'){//東北
                        let width = shape.originalWidth + differenceOfX                   
                        // let height = shape.originalHeight - differenceOfY
                        let height = width / shape.dataset.imageratio
                        top = shape.originalTop + (shape.originalHeight - height)
                        left = shapeAttribute.left
                        
                        
                        if(width > height){
                            if(width <= minimumSizeOfImageInk){
                                width = shapeAttribute.width
                                height = width / shape.dataset.imageratio
                                top = shape.originalTop + (shape.originalHeight - height)
                            }
                        }else{
                            if(height <= minimumSizeOfImageInk){
                                width = shapeAttribute.width
                                height = width / shape.dataset.imageratio
                                top = shape.originalTop + (shape.originalHeight - height)
                            }
                        }
                        

                        rx = width/2
                        ry = height/2
                                
                    }else if(shape.controlElementClassName === 'nw'){
                        let width = shape.originalWidth - differenceOfX
                        left = shape.originalLeft + differenceOfX
                        // if(width <= minimumSize){
                        //     width = shapeAttribute.width
                        //     left = shapeAttribute.left
                        // }
                        
                        let height = width / shape.dataset.imageratio
                        top = shape.originalTop + (shape.originalHeight - height)

                        if(width > height){
                            if(width <= minimumSizeOfImageInk){
                                width = shapeAttribute.width
                                left = shapeAttribute.left
                                height = width / shape.dataset.imageratio
                                top = shape.originalTop + (shape.originalHeight - height)
                            }
                        }else{
                            if(height <= minimumSizeOfImageInk){
                                width = shapeAttribute.width
                                left = shapeAttribute.left
                                height = width / shape.dataset.imageratio
                                top = shape.originalTop + (shape.originalHeight - height)
                            }
                        }


                        rx = width/2
                        ry = height/2 
                    }else{
                        return
                    }

                    const dCx = left+rx - shape.originalCenter[0]
                    const dCy = top+ry - shape.originalCenter[1]
                    const radian = -shape.rotate*Math.PI/180
                    shape.translateX = dCx*(Math.cos(radian) -1 ) + dCy*Math.sin(radian)
                    shape.translateY = -dCx*Math.sin(radian) + dCy*(Math.cos(radian)-1)
                    
                    
                    const transform = `translate(${shape.translateX+ shape.orginalTranslateX}px, ${shape.translateY+shape.orginalTranslateY}px) rotate(${shape.rotate}deg)`

                    onResize(shape.id, {left, top, rx, ry, cx: left+rx, cy: top+ry, transform}, shape.dataset.isimageink)
                    if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                        const event = new StrokeEvent(StrokeEventType.opponentOnResize, {
                            shapeId: shape.id, 
                            left, top, rx, ry, cx: left+rx, cy: top+ry, transform
                        })
                        useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                    }
                }else if(shape instanceof SVGRectElement || shape instanceof SVGEllipseElement || shape instanceof SVGImageElement){
                    const shapeAttribute = getAttributeByShape(shape)
                    const degree = getCurrentRotateDeg(shape)
                    const { differenceOfX, differenceOfY } = getResizeDifferenceWithRotate(shape.originalMouseX, shape.originalMouseY, x, y, degree, 
                        shape.originalCenter[0], shape.originalCenter[1], shape.orginalTranslateX, shape.orginalTranslateY)
                    // const {currentPageHeight: maxHeight, currentPageWidth: maxWidth} = useRoomChatStore.getState()
                    // const {translateX, translateY, rotate} =  parseCurrentTransformValue(shape.style.transform)
                        
                    if(shape.controlElementClassName === 'e'){//東
                        const width = shape.originalWidth + differenceOfX
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        
                        if(width > minimumSize){
                            rx = width/2
                        } 
                        // if(left + rx + translateX > maxWidth){
                        //     rx = maxWidth - translateX - left
                        // }
  
                    }else if(shape.controlElementClassName === 'se'){ //東南
                        const width = shape.originalWidth + differenceOfX
                        const height = shape.originalHeight + differenceOfY
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry
                        
                        if(width > minimumSize){
                            rx = width/2
                        }
                        // if(left + rx + translateX > maxWidth){
                        //     rx = maxWidth - translateX - left
                        // }
                        if(height> minimumSize){
                            ry = height/2
                        } 
                        // if(top + ry + translateY > maxHeight){
                        //     ry = maxHeight - translateY - top
                        // }
                        

                    }else if(shape.controlElementClassName === 's'){ //南
                        const height = shape.originalHeight + differenceOfY
                        left = shapeAttribute.left
                        top = shapeAttribute.top
                        rx = shapeAttribute.rx
                        ry = shapeAttribute.ry

                        if(height > minimumSize){
                            ry = height/2
                        }

                        // if(top + ry + translateY > maxHeight){
                        //     ry = maxHeight - translateY - top
                        // }
                        
                    
                    }else if(shape.controlElementClassName === 'sw'){//西南                    
                        let width = shape.originalWidth - differenceOfX
                        let height = shape.originalHeight + differenceOfY
                        left = shape.originalLeft + differenceOfX
                        top = shapeAttribute.top
                        if(height <= minimumSize){
                            height = shapeAttribute.height
                        }
                        // if(top + height/2 + translateY > maxHeight){
                        //     height = shapeAttribute.height
                        // }

                        if(width <= minimumSize){
                            width = shapeAttribute.width
                            left = shapeAttribute.left
                        }
                        // if(left + width/2 + translateX < 0){
                        //     width = shapeAttribute.width
                        //     left = shapeAttribute.left
                        // }

                        rx = width/2
                        ry = height/2 
                        
                        
                    
                    }else if(shape.controlElementClassName === 'w'){//西
                        const height = shapeAttribute.height
                        let width = shape.originalWidth - differenceOfX
                        left = shape.originalLeft + differenceOfX

                        if(width <= minimumSize){
                            width = shapeAttribute.width
                            left = shapeAttribute.left
                        }

                        // if(left + width/2 + translateX < 0){
                        //     left = shapeAttribute.left
                        //     width = shapeAttribute.width
                        // }


                        top = shapeAttribute.top
                        rx = width/2
                        ry = height/2
                        

                    }else if(shape.controlElementClassName === 'n'){//北
                        const width = shapeAttribute.width
                        let height = shape.originalHeight - differenceOfY
                        top= shape.originalTop + differenceOfY
                        left = shapeAttribute.left
                        

                        if(height <= minimumSize){
                            height = shapeAttribute.height
                            top = shapeAttribute.top
                        }
                        // if(top + height/2 + translateY < 0){
                        //     height = shapeAttribute.height
                        //     top = shapeAttribute.top
                        // }

                        rx = width/2
                        ry = height/2
                        
                    } else if(shape.controlElementClassName === 'ne'){//東北
                        let width = shape.originalWidth + differenceOfX                   
                        let height = shape.originalHeight - differenceOfY
                        top = shape.originalTop + differenceOfY
                        left = shapeAttribute.left
                        

                        if(width <= minimumSize){
                            width = shapeAttribute.width
                        }
                        // if(left + width/2 + translateX > maxWidth){
                        //     width = shapeAttribute.width
                        // }

                        if(height <= minimumSize){
                            height = shapeAttribute.height
                            top = shapeAttribute.top
                        }
                        // if(top + height/2 + translateY < 0){
                        //     height = shapeAttribute.height
                        //     top = shapeAttribute.top
                        // }

                        rx = width/2
                        ry = height/2
                                
                    }else{
                        return
                    }

                    const dCx = left+rx - shape.originalCenter[0]
                    const dCy = top+ry - shape.originalCenter[1]
                    const radian = -shape.rotate*Math.PI/180
                    shape.translateX = dCx*(Math.cos(radian) -1 ) + dCy*Math.sin(radian)
                    shape.translateY = -dCx*Math.sin(radian) + dCy*(Math.cos(radian)-1)
                    
                    
                    const transform = `translate(${shape.translateX+ shape.orginalTranslateX}px, ${shape.translateY+shape.orginalTranslateY}px) rotate(${shape.rotate}deg)`
                   
                    let cx = left+rx
                    let cy = top+ry

                    onResize(shape.id, {left, top, rx, ry, cx, cy, transform}, shape.dataset.isimageink)
                    if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                        const event = new StrokeEvent(StrokeEventType.opponentOnResize, {
                            shapeId: shape.id, 
                            left, top, rx, ry, cx, cy, transform
                        })
                        useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                    }
                }else if(shape instanceof SVGLineElement){
                    let x1 = parseFloat(shape.getAttribute('x1'))
                    let y1 = parseFloat(shape.getAttribute('y1'))
                    let x2 = parseFloat(shape.getAttribute('x2'))
                    let y2 = parseFloat(shape.getAttribute('y2'))

                    if(shape.controlElementClassName === 'lineControlBegin'){
                        const distance = Math.sqrt(Math.pow(x-x2, 2) + Math.pow(y-y2, 2))
                        if(distance > lineMinimumLength){
                            x1 = x
                            y1 = y
                        }
                        
                    }else if(shape.controlElementClassName === 'lineControlEnd'){
                        const distance = Math.sqrt(Math.pow(x-x1, 2) + Math.pow(y-y1, 2))
                        if(distance > lineMinimumLength){
                            x2 = x
                            y2 = y
                        }
                    }
                    onResize(shape.id, {x1, y1, x2, y2})
                    if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                        const event = new StrokeEvent(StrokeEventType.opponentOnResize, {
                            shapeId: shape.id, 
                            x1, y1, x2, y2
                        })
                        useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                    }
                }
            }
        }

        const onResizeEndEvent = (shapeId) => {
            const shape = document.getElementById(shapeId)
            if(!shape) return 
            const svgAttributesPair = getAttributeByShape(shape, true)
            // const transform = `translate(${shape.realCenterVec.x - svgAttributesPair.cx}px, ${shape.realCenterVec.y - svgAttributesPair.cy}px) rotate(${getCurrentRotateDeg(shape)}deg)`
            // console.log(getTransformValue(shape))
            // console.log(transform)
            const fontSizeRem = parseRemValue(shape.firstElementChild?.style?.fontSize)
            shape.translateX += shape.orginalTranslateX
            shape.translateY += shape.orginalTranslateY
            setCursorAttribute(shape, null)
            const transform = getTransformValue(shape)
            resizeEnd(shapeId, svgAttributesPair, transform, shape.dataset.isimageink, fontSizeRem)
            currentOperationId = null
            updateShapesContentToServer(shapeId, shape.dataset.isimageink)
            
            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentCompleteResize, {shapeId, svgAttributesPair, transform, isImageInk: shape.dataset.isimageink, fontSizeRem})
                useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
            }
        }

        const onRotateStartEvent = (x, y, selection, touchId) => {
            const shape = selection.shape
            if(!shape) return 
            if(currentOperationId && document.getElementById(currentOperationId)){
                return 
             }else{
                 currentOperationId = shape.id
             }

            const {cx, cy, width, height} =  getAttributeByShape(shape, false)
            selection.originalCenterX = cx
            selection.originalCenterY = cy
            selection.w = width
            selection.h = height
            selection.rectAngle =  Math.atan2(height / 2, width / 2)
            shape.rotate = shape.rotate ? shape.rotate : 0
           
            if(touchId != null) selection.touchId = touchId
            selection.modifyPathAction = modifyPathAction.rotate
            setCursorAttribute(shape, 'rotate')
        }
        const onRotateEvent = (x, y, selection, touchId) => {
            if(selection.touchId === touchId || touchId == null){
                const shape = selection.shape
                if(!shape) return 
                const {translateX, translateY} =  parseCurrentTransformValue(shape instanceof SVGForeignObjectElement ? shape.parentElement.style.transfrom : shape.style.transform)
                const angle = Math.atan2(selection.originalCenterY - y + translateY, selection.originalCenterX - x +translateX) - selection.rectAngle
                const rotate = angle * 180/Math.PI

                const transform = `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`
                onRotate(shape.id, {transform})
                if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                    const event = new StrokeEvent(StrokeEventType.opponentOnRotate, {
                        shapeId: shape.id, transform
                    })
                    useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
                }
            }
        }
        
        const onRotateEndEvent = (shapeId) => {
            const shape = document.getElementById(shapeId)
            if(!shape) return 
            const svgAttributesPair = getAttributeByShape(shape, true)
            setCursorAttribute(shape, null)
            const transform = getTransformValue(shape)
            rotateEnd(shapeId, svgAttributesPair, transform)
            currentOperationId = null
            updateShapesContentToServer(shapeId)

            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentCompleteRotate, {
                    shapeId: shape.id, svgAttributesPair, transform
                })
                useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))
            }
            
        }

        // d3.selectAll('.shapes').selectChildren('.draggable')
        selectAll('.control-element')
        .on('touchstart', function(e){
            if((useRoomChatStore.getState().currentOnMultiTouch || useRoomChatStore.getState().currentOnScreenTouches.length >= 2)){
                return
            }

            e.preventDefault()
            if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 
            const t = e.touches[0]
            const {x, y} = updatePointer(e, 0)
            

            const targetClass = e.target.getAttribute('class')
            if(targetClass !== 'more' && targetClass !== 'morebg'){
                updateShapeMoreButtonInfoIsOpen(false)
            }
            
            if(targetClass !== 'imageinkcolor' && targetClass !== 'imageinkcolorbg'){
                setImageInkColorPickerInfo({left: 0, top: 0, isActive: false})
            }
            if(targetClass !== 'imageinkwidth' && targetClass !== 'imageinkwidthbg'){
                setImageInkStrokeWidthSliderInfo({left: 0, top: 0, isActive: false, value: 0})
            }
            
            if(((targetClass === 'nw' || targetClass === 'rotate') && !this.shape?.dataset?.isimageink)){
                onRotateStartEvent(x, y, this, t.identifier)
            }else if(targetClass === 'dragrect control-element' || targetClass === 'shape control-element' || targetClass === 'textdragrect control-element'){
                if(targetClass === 'shape control-element'){
                    this.shape = this.shape ? this.shape : this
                }else if(targetClass === 'dragrect control-element'){
                    this.shape = this.shape ? this.shape : e.target.previousElementSibling
                }else if(targetClass === 'textdragrect control-element'){
                    this.shape = this.shape ? this.shape : e.target.nextElementSibling.firstElementChild
                }
                onDragStartEvent(x, y, this, t.identifier)
                touchstartTimeStamp = e.timeStamp
            }else if(targetClass === 'more' || targetClass === 'morebg'){
                this.modifyPathAction = null
                updateShapeMoreButtonInfo({x, y, isOpen: !useRoomChatStore.getState().shapeMoreButtonInfo.isOpen, 
                    isImageInk: this.shape.dataset.isimageink === 'true' ? true : false})   
                
            }else if(
                targetClass === 'imageinktoink' ||
                targetClass === 'imageinkcolor' || targetClass === 'imageinkcolorbg' ||
                targetClass === 'imageinkwidth' || targetClass === 'imageinkwidthbg'){
                return
            }else{    
                onResizeStartEvent(x, y, this, t.identifier, targetClass)
            }
                
            
            
        },{passive: false})
        .on('touchmove', function(e){
            e.preventDefault()
            if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 
           
            const t = e.touches[0]
            const {x, y} = updatePointer(e, 0)
            
            if(this.modifyPathAction === modifyPathAction.rotate){
                onRotateEvent(x, y, this, t.identifier)
            }else if(this.modifyPathAction === modifyPathAction.drag){
                onDragEvent(x, y, this, t.identifier)
            }else if(this.modifyPathAction === modifyPathAction.resize){
                onResizeEvent(x, y, this, t.identifier)
            }                      
                
        },{passive: false})
        .on('touchend', function(e){
            if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 
            this.touchId = null
            if(this.modifyPathAction === modifyPathAction.rotate){
                onRotateEndEvent(this.dataset.shapeid)
            }else if(this.modifyPathAction === modifyPathAction.drag){
                const shape = onDragEndEvent(this.dataset.shapeid)
                if(e.timeStamp - touchstartTimeStamp < touchduration){
                    if(shape instanceof SVGForeignObjectElement){
                        shape.firstElementChild.classList.add('editable')
                        shape.firstElementChild.classList.remove('uneditable')
                        shape.firstElementChild.focus()
                    }
                }
            }else if(this.modifyPathAction === modifyPathAction.resize){
                onResizeEndEvent(this.dataset.shapeid)
            }
            this.modifyPathAction = null
            
        })
        .call(drag()
            .on('start', function(e) {
                if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 
                if(e.sourceEvent.touches) 
                    return
                const targetClass = e.sourceEvent.target.getAttribute('class')
                if(targetClass !== 'more' && targetClass !== 'morebg'){
                    updateShapeMoreButtonInfoIsOpen(false)
                }
                if(targetClass !== 'imageinkcolor' && targetClass !== 'imageinkcolorbg'){
                    setImageInkColorPickerInfo({left: 0, top: 0, isActive: false})
                }

                if(targetClass !== 'imageinkwidth' && targetClass !== 'imageinkwidthbg'){
                    setImageInkStrokeWidthSliderInfo({left: 0, top: 0, isActive: false, value: 0})
                }

                if((targetClass === 'nw' || targetClass === 'rotate') && !this.shape?.dataset?.isimageink){
                    onRotateStartEvent(e.x, e.y, this)
                }else if(targetClass === 'textdragrect control-element' || targetClass === 'dragrect control-element' || targetClass === 'shape control-element'){
                    if(targetClass === 'shape control-element'){
                        this.shape = this.shape ? this.shape : this
                    }else if(targetClass === 'dragrect control-element'){
                        this.shape = this.shape ? this.shape : e.sourceEvent.target.previousElementSibling
                    }else if(targetClass === 'textdragrect control-element'){
                        this.shape = this.shape ? this.shape : e.sourceEvent.target.nextElementSibling.firstElementChild
                    }
                    onDragStartEvent(e.x, e.y, this)
                    
                }else if(targetClass === 'more' || targetClass === 'morebg'){
                    this.modifyPathAction = null
                    updateShapeMoreButtonInfo({x: e.x, y: e.y, isOpen: !useRoomChatStore.getState().shapeMoreButtonInfo.isOpen})    
                    
                }else if(
                    targetClass === 'imageinktoink' ||
                    targetClass === 'imageinkcolor' || targetClass === 'imageinkcolorbg' ||
                    targetClass === 'imageinkwidth' || targetClass === 'imageinkwidthbg'){
                    return
                }else{
                    onResizeStartEvent(e.x, e.y, this, null, targetClass)
                }
            })
            .on('drag', function(e) {
                if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 
                if(e.sourceEvent.touches) 
                    return 

                if(this.modifyPathAction === modifyPathAction.rotate){
                    onRotateEvent(e.x, e.y, this)
                }else if(this.modifyPathAction === modifyPathAction.drag){
                    onDragEvent(e.x, e.y, this)
                }else if(this.modifyPathAction === modifyPathAction.resize){
                    onResizeEvent(e.x, e.y, this)
                }    
            })
            .on('end', function(e){
                if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 

                if(e.sourceEvent.touches) 
                    return
                if(this.modifyPathAction === modifyPathAction.rotate){
                    onRotateEndEvent(this.dataset.shapeid)
                }else if(this.modifyPathAction === modifyPathAction.drag){
                    onDragEndEvent(this.dataset.shapeid)
                }else if(this.modifyPathAction === modifyPathAction.resize){
                    onResizeEndEvent(this.dataset.shapeid)
                    
                }    
                this.modifyPathAction = null
                
            })
        )
        select('.whiteboard').on('pointerdown', (e) => {
            if(useToolbarSettingsStore.getState().currentActiveTool !== ToolBarTool.cursor) return 
            if(e.buttons !== 1) return
            if(e.target.getAttribute('class') !== 'whiteboard') return 
            useRoomChatStore.getState().updateCurrentActiveShape(null)
        })

    }, [shapes, imageInks])
    const updateRectControlElementPosition = (cx,cy,rx,ry, controlElement, strokeWidth, isImageInk) => {
        if(!controlElement) return
        const controlElementChildren =  controlElement.children
        const info = getRectControlElementPositionInfo(cx,rx,cy,ry, strokeWidth, isImageInk ? true :false, false)
        
        for(let i = 0 ; i < controlElementChildren.length ; i++){
            const element = controlElementChildren[i]
            const className = element.getAttribute('class')
            Object.keys(info[className]).forEach(key => {
                element.setAttribute(key, info[className][key])
                
            })
        }
    }
    const updateLineControlElementPosition = (x1, y1, x2, y2, controlElement) => {
        if(!controlElement) return
        const controlElementChildren =  controlElement.children
        const info = getLineControlElementPositionInfo(x1, y1, x2, y2)
        for(let i = 0 ; i < controlElementChildren.length ; i++){
            const element = controlElementChildren[i]
            Object.keys(info[element.getAttribute('class')]).forEach(key => {
                element.setAttribute(key, info[element.getAttribute('class')][key])
            })
        }
    }


    
    const onPathChanged = (modifyList) => {
        modifyList.forEach(modifyDetail => {
            if(!modifyDetail) return

            const shape = document.getElementById(modifyDetail.id)
            if(!shape) return
            const controlElement = document.getElementById(`${modifyDetail.id}-control`)
            const lastExecute = historyTemp[modifyDetail.id] ? historyTemp[modifyDetail.id].lastExecute : 0
            const currentActionType = modifyDetail.action
            
            for(let i = lastExecute ; i < modifyDetail.list.length ; i++ ){
            
                const detail = modifyDetail.list[i]
                const shapeAttribute = getAttributeByShape(shape) 
                if(shape instanceof SVGEllipseElement){
                    if(currentActionType === modifyPathAction.drag){
                        shape.setAttribute('cx', detail.cx)
                        shape.setAttribute('cy', detail.cy)
                        const rx = shapeAttribute.rx
                        const ry = shapeAttribute.ry

                        shape.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        if(controlElement){
                            controlElement.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        }
                        
                        updateRectControlElementPosition(detail.cx, detail.cy,
                            rx, ry, controlElement, shapeAttribute.strokeWidth)
                        
                        
                    }else if(currentActionType === modifyPathAction.resize){
                       
                        shape.setAttribute('rx', detail.rx)
                        shape.setAttribute('ry', detail.ry)
                        shape.setAttribute('cx', detail.cx)
                        shape.setAttribute('cy', detail.cy)
                        shape.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        shape.style['transform'] = detail.transform

                        if(controlElement){
                            controlElement.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                            controlElement.style['transform'] = detail.transform
                        }

                        updateRectControlElementPosition(detail.cx, detail.cy,
                            detail.rx, detail.ry, controlElement, shapeAttribute.strokeWidth)     
                    

                    }else if(currentActionType === modifyPathAction.rotate){
                        shape.style.transform = detail.transform

                        if(controlElement){
                            controlElement.style.transform = detail.transform
                        }
                        // useElement.setAttribute('xlink:href', '#control-element')

                    }                  
                }else if(shape instanceof SVGRectElement || shape instanceof SVGImageElement){
                    if(currentActionType === modifyPathAction.drag){
                        const rx = shapeAttribute.rx
                        const ry = shapeAttribute.ry
                        const x = detail.cx-rx
                        const y = detail.cy-ry
                        shape.setAttribute('x', x)
                        shape.setAttribute('y', y)
                        shape.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        if(controlElement){
                            controlElement.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        }
                        updateRectControlElementPosition(detail.cx, detail.cy,
                            rx, ry, controlElement, shapeAttribute.strokeWidth, shape.dataset.isimageink)

                    }else if(currentActionType === modifyPathAction.resize){
                        
                        shape.setAttribute('x', detail.left)
                        shape.setAttribute('y', detail.top)
                        shape.setAttribute('width', detail.rx*2)
                        shape.setAttribute('height', detail.ry*2)
                        shape.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        shape.style['transform'] = detail.transform
                        if(controlElement){
                            controlElement.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                            controlElement.style['transform'] = detail.transform
                        }
                        updateRectControlElementPosition(detail.cx, detail.cy,
                            detail.rx, detail.ry, controlElement, shapeAttribute.strokeWidth, shape.dataset.isimageink)  

                    }else if(currentActionType === modifyPathAction.rotate){
                        shape.style.transform = detail.transform

                        if(controlElement){
                            controlElement.style.transform = detail.transform
                        }
                        

                    } 
                }else if(shape instanceof SVGLineElement){
                    if(currentActionType === modifyPathAction.drag){
                        const dx = detail.cx - shapeAttribute.cx
                        const dy = detail.cy - shapeAttribute.cy
                        const newx1 = shapeAttribute.x1 + dx
                        const newy1 = shapeAttribute.y1 + dy
                        const newx2 = shapeAttribute.x2 + dx
                        const newy2 = shapeAttribute.y2 + dy

                        shape.setAttribute('x1', newx1)
                        shape.setAttribute('y1', newy1)
                        shape.setAttribute('x2', newx2)
                        shape.setAttribute('y2', newy2)
                        updateLineControlElementPosition(newx1, newy1,
                            newx2, newy2, controlElement, shapeAttribute.strokeWidth) 
                    }else if(currentActionType === modifyPathAction.resize){
                        
                        shape.setAttribute('x1', detail.x1)
                        shape.setAttribute('y1', detail.y1)
                        shape.setAttribute('x2', detail.x2)
                        shape.setAttribute('y2', detail.y2)
                        updateLineControlElementPosition(detail.x1, detail.y1,
                            detail.x2, detail.y2, controlElement, shapeAttribute.strokeWidth)     
                    }
                }else if(shape instanceof SVGForeignObjectElement){
                    if(currentActionType === modifyPathAction.drag){
                        const rx = shapeAttribute.rx
                        const ry = shapeAttribute.ry
                        const x = detail.cx-rx
                        const y = detail.cy-ry
                        const group = shape.parentElement
                        const textDragRect = shape.parentElement.previousElementSibling
                        
                        textDragRect.setAttribute('x', x)
                        textDragRect.setAttribute('y', y)
                        shape.setAttribute('x', x+10)
                        shape.setAttribute('y', y+5)

                        textDragRect.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        group.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        // shape.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`

                        if(controlElement){
                            controlElement.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        }

                        updateRectControlElementPosition(detail.cx, detail.cy,
                            rx, ry, controlElement, shapeAttribute.strokeWidth)
                    }else if(currentActionType === modifyPathAction.resize){

                        const textDragRect = shape.parentElement.previousElementSibling
                        textDragRect.setAttribute('x', detail.left)
                        textDragRect.setAttribute('y', detail.top)
                        textDragRect.setAttribute('width', detail.rx*2)
                        textDragRect.setAttribute('height', detail.ry*2)
                       
                        
                        const textarea = shape.firstElementChild
                        const group = shape.parentElement
                        shape.setAttribute('x', detail.left + 10)
                        shape.setAttribute('y', detail.top + 5)
                        textarea.style['height'] = `${ detail.ry*2}px`
                        textarea.style['width'] = `${ detail.rx*2 - 10}px`
                        textarea.style['fontSize'] = `${detail.fontSizeRem}rem`
                        // shape.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        // shape.style['transform'] = detail.transform
                        group.style['transform'] = detail.transform
                        group.style['transform-origin'] =`${detail.cx}px ${detail.cy}px`
                        textDragRect.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                        textDragRect.style['transform'] = detail.transform
                        if(controlElement){
                            controlElement.style['transform-origin'] = `${detail.cx}px ${detail.cy}px`
                            controlElement.style['transform'] = detail.transform
                        }
                        updateRectControlElementPosition(detail.cx, detail.cy,
                            detail.rx, detail.ry, controlElement, shapeAttribute.strokeWidth)  
        
                    }else if(currentActionType === modifyPathAction.rotate){
                        const textDragRect = shape.parentElement.previousElementSibling
                        textDragRect.style.transform = detail.transform
                        shape.parentElement.style['transform'] = detail.transform

                        if(controlElement){
                            controlElement.style.transform = detail.transform
                        }
                    }
                }
            }
            historyTemp[modifyDetail.id] = {...historyTemp[modifyDetail.id], lastExecute: modifyDetail.list.length}
        })

        Object.keys(historyTemp).forEach(key => {
            const {lastExecute, lastStep=-1} = historyTemp[key]
            if(lastExecute === lastStep){
                
                 delete historyTemp[key]
            } 
        })
    }
    useEffect(() => {
        const unsubPathChanged = useRoomChatStore.subscribe(onPathChanged, state => state.modifyList)
        return () =>{
            unsubPathChanged()
        }
    })
    
}



export default useModifyPathHandler
