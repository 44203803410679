import React, {useEffect, useRef, useLayoutEffect, useCallback} from 'react'
import { StrokeEvent, StrokeEventType, useRoomChatStore, useUserConnectionStore } from '../store'
import { getCurrentRatioBaseOnContainer, getScrollLeftMaxAndScrollTopMax, minimapRatioCalcluate } from '../utils'


const scrollThreshold = 18
export const scrollToCertainLocation = (leftPercent, topPercent) => {

    const {whiteboardWrapperSize, whiteboardContainer} = minimapRatioCalcluate()
    const scrollLeft = (whiteboardWrapperSize[0])*(leftPercent/100)
    const scrollTop = (whiteboardWrapperSize[1])*(topPercent/100)

    const {scrollLeftMax, scrollTopMax} = getScrollLeftMaxAndScrollTopMax(whiteboardContainer)

    //因為邊界的padding 14px , 而小地圖沒有padding ,導致%數在邊邊的準確率會有誤差, 做個額外修正偏移
    whiteboardContainer.scrollLeft = scrollLeftMax - scrollLeft < scrollThreshold ? scrollLeftMax : scrollLeft
    whiteboardContainer.scrollTop =  scrollTopMax - scrollTop < scrollThreshold ? scrollTopMax : scrollTop


}
const miniMapBoundarySpace = 3

const Minimap = ({opponentUserName}) => {
    const miniMapInitialized = useRef(false)
    const myMapRef = useRef(null)
    const minimapRef = useRef(null)
    const ondragProperty = useRef(null)

    const onChanged = (e) => {
        const {diffX, diffY, containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize} = minimapRatioCalcluate()
        if(diffX === diffY && diffX === 0 && miniMapInitialized.current === false){
            console.log('initialized minimap')
            miniMapInitialized.current = true
        }
        if(diffX !== diffY && miniMapInitialized.current === false){
            setTimeout(() => {
                useRoomChatStore.getState().updateWhiteboardSizeResetTime()    
            }, 30)
            return
        }
        if(!minimapRef.current){
            setTimeout(() => {
                useRoomChatStore.getState().updateWhiteboardSizeResetTime()    
            }, 30)
            return
        }
        minimapRef.current.parentElement.style.display = null    

        const { leftResult, topResult, widthResult, heightResult} = getCurrentRatioBaseOnContainer(containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize)
        
        const data = {
            left:`${leftResult}%`,
            top:`${topResult}%`,
            width: `${widthResult}%`,
            height: `${heightResult}%`
        }
        
        myMapRef.current.style.left = data.left
        myMapRef.current.style.width = data.width
        myMapRef.current.style.top = data.top
        myMapRef.current.style.height = data.height


        if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentMinimapChanged, data)
            useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))    
        }
    }

    const onResize = useCallback( () => {
        useRoomChatStore.getState().updateWhiteboardSizeResetTime()
    }, [])

    useLayoutEffect(() => {
        if(miniMapInitialized.current){
            minimapRef.current.parentElement.style.display = null
            useRoomChatStore.getState().updateWhiteboardSizeResetTime()
        }else{
            minimapRef.current.parentElement.style.display = 'none'
            useRoomChatStore.getState().updateWhiteboardSizeResetTime()
        }
    }, [])

    useEffect(() => {
        const unsubWhiteboardSizeResetTimeChanged = useRoomChatStore.subscribe(onChanged, state => state.whiteboardSizeResetTime)
        window.addEventListener('resize', onResize)
        return () => {
            unsubWhiteboardSizeResetTimeChanged()
            window.removeEventListener('resize', onResize)
        }
    }, [onResize])

    useEffect(() => {
        if(opponentUserName){
            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                const {left, top, width, height} =  myMapRef.current.style
                const data = {
                    left: parseFloat(left) + '%',
                    top: parseFloat(top) + '%',
                    width: parseFloat(width) + '%',
                    height: parseFloat(height) + '%'
                }

                const event = new StrokeEvent(StrokeEventType.opponentMinimapChanged, data)
                useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))    
            }
        }
    }, [opponentUserName])

    const myMinimapDragStart = useCallback((e) => {
        e.preventDefault()
        if((e.target.classList.contains('map') && ondragProperty.current == null)){
            return
        }
    
        const elementRect = e.target.getBoundingClientRect()
        const pointerPosition = [e.clientX, e.clientY]
        const originalLeft = elementRect.left
        const originalTop = elementRect.top
        ondragProperty.current = {
            element: e.target, 
            elementRect,
            pointerPosition
        }
        ondragProperty.current = {...ondragProperty.current, 
            originalLeft, originalTop, 
            originalLeftPercent: parseFloat(e.target.style.left),
            originalTopPercent: parseFloat(e.target.style.top),
            diffX: pointerPosition[0] - originalLeft,
            diffY: pointerPosition[1] - originalTop
        }
        e.target.classList.add('isdragging')
        minimapRef.current.parentElement.addEventListener('pointermove', myMinimapDragging)
        minimapRef.current.parentElement.addEventListener('pointerup', myMinimapDragEnd)
        minimapRef.current.parentElement.addEventListener('pointerleave', myMinimapDragEnd)
    }, [])


    const myMinimapDragging = useCallback((e) => {
        if(!ondragProperty.current) return
        if(ondragProperty.current.element.classList.contains('my-minimap')){
            const currentPointerPosition = [e.clientX, e.clientY]
            const leftDiffPercent = ((currentPointerPosition[0]- ondragProperty.current.diffX - ondragProperty.current.originalLeft)/parseFloat(minimapRef.current.style.width))*100
            const rightDiffPercent = ((currentPointerPosition[1]- ondragProperty.current.diffY - ondragProperty.current.originalTop)/parseFloat(minimapRef.current.style.height))*100
            
            let leftPercent = ondragProperty.current.originalLeftPercent + leftDiffPercent
            let topPercent = ondragProperty.current.originalTopPercent + rightDiffPercent

            if(leftPercent < 0) leftPercent = 0
            if(topPercent < 0) topPercent = 0

            const {width, height} = ondragProperty.current.elementRect
            let widthPercent = (width/parseFloat(minimapRef.current.style.width))*100
            let heightPercent = (height/parseFloat(minimapRef.current.style.height))*100
            if(leftPercent + widthPercent > 100) leftPercent = 100-widthPercent
            if(topPercent + heightPercent > 100) topPercent = 100-heightPercent

            ondragProperty.current.element.style.left = `${leftPercent}%`
            ondragProperty.current.element.style.top = `${topPercent}%`


            scrollToCertainLocation(leftPercent, topPercent)

        }else{
            return 
        }
    }, [])
    const myMinimapDragEnd = useCallback((e) => {
        minimapRef.current.parentElement.removeEventListener('pointermove', myMinimapDragging)
        minimapRef.current.parentElement.removeEventListener('pointerup', myMinimapDragEnd)
        minimapRef.current.parentElement.removeEventListener('pointerleave', myMinimapDragEnd)


        if(ondragProperty.current){
            ondragProperty.current.element.classList.remove('isdragging')
            ondragProperty.current = null
        }
    }, [])

    const miniMapDragStart = useCallback((e) => {
        e.preventDefault()

        const elementRect = e.target.getBoundingClientRect()
        const pointerPosition = [e.clientX, e.clientY]
        const originalLeft = elementRect.left
        const originalTop = elementRect.top
        ondragProperty.current = {
            element: e.target, 
            elementRect,
            pointerPosition
        }
        const whiteboardContainerRect = document.querySelector('.whiteboard-container').getBoundingClientRect()
        const videosWidth = parseFloat(document.querySelector('.videos').style.width)
        const thumbnailWidth = document.querySelector('.thumbnail')?.getBoundingClientRect()?.width || 0
        ondragProperty.current = {...ondragProperty.current, 
            originalLeft, 
            originalTop, 
            diffX: pointerPosition[0] - originalLeft,
            diffY: pointerPosition[1] - originalTop,
            absolutePositionTop: parseFloat(e.target.parentElement.style.top),
            absolutePositionRight: parseFloat(e.target.parentElement.style.right),
            absolutePositionMaxRight: whiteboardContainerRect.width + videosWidth + thumbnailWidth - e.target.parentElement.clientWidth - miniMapBoundarySpace,
            absolutePositionMaxTop: whiteboardContainerRect.height - e.target.parentElement.clientHeight - miniMapBoundarySpace,
        }
        e.target.parentElement.classList.add('isDragging')
        window.addEventListener('pointermove', miniMapDragging)
        window.addEventListener('pointerup', minimapDragEnd)
        window.addEventListener('pointerleave', minimapDragEnd)
    }, [])

    const miniMapDragging = useCallback((e) => {
        if(ondragProperty.current && ondragProperty.current.element.classList.contains('title')){
            const currentPointerPosition = [e.clientX, e.clientY]
            let top = currentPointerPosition[1]- ondragProperty.current.diffY - ondragProperty.current.originalTop + ondragProperty.current.absolutePositionTop
            let right = ondragProperty.current.absolutePositionRight - (currentPointerPosition[0]- ondragProperty.current.diffX - ondragProperty.current.originalLeft)

            if(top < miniMapBoundarySpace) top = miniMapBoundarySpace
            if(top > ondragProperty.current.absolutePositionMaxTop) top = ondragProperty.current.absolutePositionMaxTop
            if(right < miniMapBoundarySpace) right = miniMapBoundarySpace
            if(right > ondragProperty.current.absolutePositionMaxRight) right = ondragProperty.current.absolutePositionMaxRight
            
            

            ondragProperty.current.element.parentElement.style.top = `${top}px`
            ondragProperty.current.element.parentElement.style.right = `${right}px`
                
        }else{
            e.stopPropagation()
            e.preventDefault()
        }
    }, [])

    const minimapDragEnd = useCallback((e) => {
        window.removeEventListener('pointermove', miniMapDragging)
        window.removeEventListener('pointerup', minimapDragEnd)
        window.removeEventListener('pointerleave', minimapDragEnd)

        if(ondragProperty.current){
            ondragProperty.current.element.classList.remove('isdragging')
            ondragProperty.current = null
        }
    }, [])


    return (
        <div className='minimap-wrapper' data-title='Mini Map' style={{top: '40px', right:'290px'}}>
            <div className='title' onPointerDown={miniMapDragStart} onPointerMove={miniMapDragging} onPointerUp={minimapDragEnd}>Mini Map</div>
            <div ref={minimapRef} className='minimap'>
                <img alt=''></img>
                <div className='map'>
                    <div className={`range opponent-minimap ${opponentUserName? '': 'displayNone'}`}></div>
                    <div ref={myMapRef} className={`range my-minimap ${opponentUserName? '': 'onlyme'}`} onPointerDown={myMinimapDragStart} onPointerMove={myMinimapDragging} onPointerUp={myMinimapDragEnd}></div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Minimap)
