import React, {useEffect, useRef, useState, lazy, Suspense} from 'react'
import { useLocalStorageStore, useRoomChatStore, useUserConnectionStore } from '../store'
import { ShapeType } from '../store'
import { select } from 'd3-selection'
import rotateIconHref from '../images/ic_rotate_n@2x.png'
import rotateIconHoverHref from '../images/ic_rotate_h@2x.png'
import moreIconHref from '../images/ic_note-mode_more_n2x-removebg-preview.png'
import transferToInkHref from '../images/ic_note-mode_change-to-handwriting_n2x-removebg-preview.png'
import { getLatestHistory, getStrokePath, sleep } from '../utils'
import loadingImage from '../images/ic_loading@2x.gif'
import { v4 as uuid } from 'uuid'
import { apiAddDrawObjectMulti, apiDeleteDrawObjectMulti, apiMsgToOther } from '../api'
import { SocketEvent, SocketEventType } from '../socketEvent'
import { useTranslation } from 'react-i18next'

const resizeRectWidthAndHeight = 14
const resizeCircleRadius = 7
const fill = '#49C9AB'
const imageinkColorPickerSize = 30;
const hoverObjClass = "ishoverobj"
export const getRectControlElementPositionInfo = (cx, rx, cy, ry, strokeWidth=0, isImageInk, clickToggleSlider) => {
    const r = resizeCircleRadius
    const rectHalfSize = resizeRectWidthAndHeight/2
    const nwCirclePosition = {cx: (cx-rx)-strokeWidth/2, cy: (cy-ry)-strokeWidth/2, r, fill}
    const neCirclePosition = {cx: (cx+rx)+strokeWidth/2, cy: (cy-ry)-strokeWidth/2, r, fill}
    const swCirclePosition = {cx: (cx-rx)-strokeWidth/2, cy: (cy+ry)+strokeWidth/2, r, fill}
    const seCirclePosition = {cx: (cx+rx)+strokeWidth/2, cy: (cy+ry)+strokeWidth/2, r, fill}
    const info = {
        dragrect: {x: (cx-rx), y: (cy-ry), width: (2*rx), height: (2*ry), fill: 'transparent', stroke: 'transparent', strokeWidth},
        rotate: {x: nwCirclePosition.cx -r*6, y: nwCirclePosition.cy-r*6},
        imageinkwidthbg: {cx: neCirclePosition.cx-40*r+imageinkColorPickerSize/2, cy: neCirclePosition.cy-10*r + imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinkwidth: {cx: neCirclePosition.cx-40*r+imageinkColorPickerSize/2, cy: neCirclePosition.cy-10*r + imageinkColorPickerSize/2, r: imageinkColorPickerSize/3, fill: '#6DD2BA'},
        imageinkcolorbg: {cx: neCirclePosition.cx-29*r+imageinkColorPickerSize/2, cy: neCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinkcolor: {x: neCirclePosition.cx-29*r, y: neCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        imageinktoinkbg: {cx: neCirclePosition.cx-18*r+imageinkColorPickerSize/2, cy: neCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinktoink: {x: neCirclePosition.cx-18*r, y: neCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        morebg: {cx: neCirclePosition.cx-7*r+imageinkColorPickerSize/2, cy: neCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        more: {x: neCirclePosition.cx-7*r, y: neCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        nw: nwCirclePosition,
        ne: neCirclePosition,
        sw: swCirclePosition,
        se: seCirclePosition,
        n:  {x: (cx-rectHalfSize), y: (cy-ry-rectHalfSize)-strokeWidth/2, width:resizeRectWidthAndHeight, height:resizeRectWidthAndHeight, fill},
        s:  {x: (cx-rectHalfSize), y: (cy+ry-rectHalfSize)+strokeWidth/2, width:resizeRectWidthAndHeight, height:resizeRectWidthAndHeight, fill},
        w:  {x: (cx-rx-rectHalfSize)-strokeWidth/2, y: (cy-rectHalfSize), width:resizeRectWidthAndHeight, height:resizeRectWidthAndHeight, fill},
        e:  {x: (cx+rx-rectHalfSize)+strokeWidth/2, y: (cy-rectHalfSize), width:resizeRectWidthAndHeight, height:resizeRectWidthAndHeight, fill},
        imageinkwidthbgBottom: {cx: neCirclePosition.cx-40*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy+6*r + imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinkwidthBottom: {cx: neCirclePosition.cx-40*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy+6*r + imageinkColorPickerSize/2, r: imageinkColorPickerSize/3, fill: '#6DD2BA'},
        imageinkcolorbgBottom: {cx: neCirclePosition.cx-29*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy+6*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinkcolorBottom: {x: neCirclePosition.cx-29*r, y: seCirclePosition.cy+6*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        imageinktoinkbgBottom: {cx: neCirclePosition.cx-18*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy+6*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinktoinkBottom: {x: neCirclePosition.cx-18*r, y: seCirclePosition.cy+6*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        morebgBottom: {cx: neCirclePosition.cx-7*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy+6*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        moreBottom: {x: neCirclePosition.cx-7*r, y: seCirclePosition.cy+6*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        imageinkwidthbgBottomInside: {cx: neCirclePosition.cx-40*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy-10*r + imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinkwidthBottomInside: {cx: neCirclePosition.cx-40*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy-10*r + imageinkColorPickerSize/2, r: imageinkColorPickerSize/3, fill: '#6DD2BA'},
        imageinkcolorbgBottomInside: {cx: neCirclePosition.cx-29*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinkcolorBottomInside: {x: neCirclePosition.cx-29*r, y: seCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        imageinktoinkbgBottomInside: {cx: neCirclePosition.cx-18*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        imageinktoinkBottomInside: {x: neCirclePosition.cx-18*r, y: seCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        morebgBottomInside: {cx: neCirclePosition.cx-7*r+imageinkColorPickerSize/2, cy: seCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        moreBottomInside: {x: neCirclePosition.cx-7*r, y: seCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
    }
    if(isImageInk){
        info.pos = 'top'
       
        if(info.imageinkwidthbg.cy - info.imageinkwidthbg.r < 0){ //上方工具列超出範圍，擺下面
            info.imageinkwidthbg = info.imageinkwidthbgBottom
            info.imageinkwidth = info.imageinkwidthBottom
            info.imageinkcolorbg = info.imageinkcolorbgBottom
            info.imageinkcolor = info.imageinkcolorBottom
            info.imageinktoinkbg = info.imageinktoinkbgBottom
            info.imageinktoink = info.imageinktoinkBottom
            info.morebg = info.morebgBottom
            info.more = info.moreBottom
            info.pos = 'bottom'
        }
        const maxHeight = useRoomChatStore.getState().currentPageHeight
        const maxWidth = useRoomChatStore.getState().currentPageWidth
        if(info.imageinkwidthbg.cy + info.imageinkwidthbg.r > maxHeight){ //下方擺不下，擺下方裡面
            info.imageinkwidthbg = info.imageinkwidthbgBottomInside
            info.imageinkwidth = info.imageinkwidthBottomInside
            info.imageinkcolorbg = info.imageinkcolorbgBottomInside
            info.imageinkcolor = info.imageinkcolorBottomInside
            info.imageinktoinkbg = info.imageinktoinkbgBottomInside
            info.imageinktoink = info.imageinktoinkBottomInside
            info.morebg = info.morebgBottomInside
            info.more = info.moreBottomInside

            info.pos = 'inside-bottom'
        }
        const sliderWidth = 146/useRoomChatStore.getState().ratio
        
        if(info.imageinkwidthbg.cx - info.imageinkwidthbg.r < 0){ //左側
            info.imageinkwidthbg.cx = info.imageinkwidthbg.r
            info.imageinkwidth.cx = info.imageinkwidthbg.r
            info.imageinkcolorbg.cx = info.imageinkwidthbg.cx+(11*r)
            info.imageinkcolor.x = info.imageinkwidthbg.cx+(11*r) - info.imageinkcolor.width/2
            info.imageinktoinkbg.cx = info.imageinkcolorbg.cx+(11*r)
            info.imageinktoink.x = info.imageinkcolorbg.cx+(11*r) - info.imageinktoink.width/2
            info.morebg.cx = info.imageinktoinkbg.cx+(11*r)
            info.more.x = info.imageinktoinkbg.cx+(11*r) - info.more.width/2
        }

        if(clickToggleSlider && (info.imageinkwidthbg.cx - info.imageinkwidthbg.r < sliderWidth)){ //左側slider 不夠放
            info.imageinkwidthbg.cx = sliderWidth + info.imageinkwidthbg.r
            info.imageinkwidth.cx = sliderWidth + info.imageinkwidthbg.r

            info.imageinkcolorbg.cx = info.imageinkwidthbg.cx+(11*r)
            info.imageinkcolor.x = info.imageinkwidthbg.cx+(11*r) - info.imageinkcolor.width/2
            info.imageinktoinkbg.cx = info.imageinkcolorbg.cx+(11*r)
            info.imageinktoink.x = info.imageinkcolorbg.cx+(11*r) - info.imageinktoink.width/2
            info.morebg.cx = info.imageinktoinkbg.cx+(11*r)
            info.more.x = info.imageinktoinkbg.cx+(11*r) - info.more.width/2
        }

        if(info.morebg.cx + info.imageinkwidthbg.r > maxWidth){ //右側
            info.morebg.cx = maxWidth - info.imageinkwidthbg.r
            info.more.x = info.morebg.cx - info.more.width/2
            info.imageinktoinkbg.cx = info.morebg.cx-(11*r)
            info.imageinktoink.x = info.morebg.cx-(11*r) - info.imageinktoink.width/2
            info.imageinkcolorbg.cx = info.imageinktoinkbg.cx-(11*r)
            info.imageinkcolor.x = info.imageinktoinkbg.cx-(11*r) - info.imageinkcolor.width/2
            info.imageinkwidthbg.cx = info.imageinkcolorbg.cx-(11*r)
            info.imageinkwidth.cx = info.imageinkwidthbg.cx
        }
    }

    return info
}

export const getLineControlElementPositionInfo = (x1, y1, x2, y2) => {
    const r = resizeCircleRadius
    const neCirclePosition = {
        cx: x2+r,
        cy: y2-r*2
    }
    return {
        morebg: {cx: neCirclePosition.cx-10*r+imageinkColorPickerSize/2, cy: neCirclePosition.cy-10*r+imageinkColorPickerSize/2, r: imageinkColorPickerSize, fill: 'none', stroke: '#6DD2BA', strokeWidth: '2px'},
        more: {x: neCirclePosition.cx-10*r, y: neCirclePosition.cy-10*r, width: imageinkColorPickerSize, height: imageinkColorPickerSize},
        // more:{x: (x2+r), y:(y2-r*2)},
        lineControlBegin: {cx: (x1), cy: (y1), r, fill},
        lineControlEnd: {cx: (x2), cy: (y2), r, fill}
    }
}

const updateShapeMoreButtonInfoIsOpenSelector = state => state.updateShapeMoreButtonInfoIsOpen
const updateShapeMoreButtonInfoSelector = state => state.updateShapeMoreButtonInfo
const transferImageInkToInkSelector = state => state.transferImageInkToInk
const setImageInkColorPickerInfoSelector = state => state.setImageInkColorPickerInfo
const setImageInkStrokeWidthSliderInfoSelector = state => state.setImageInkStrokeWidthSliderInfo
const ShapeControlElement = ({markId, strokeWidth, shapeType, cx, cy, rx, ry, x, y, width, height, x1, y1, x2, y2, transform, imageInkFill, imageInkToInkHint}) => {
    const {t} = useTranslation()
    const setImageInkColorPickerInfo = useRoomChatStore(setImageInkColorPickerInfoSelector)
    const setImageInkStrokeWidthSliderInfo = useRoomChatStore(setImageInkStrokeWidthSliderInfoSelector)
    const currentActiveShape = useRoomChatStore(state => state.currentActiveShape, (prev, cur) => {
        if(prev === cur) return true
        if(prev !== markId && cur !== markId) return true
        return false
    })
    const updateShapeMoreButtonInfoIsOpen = useRoomChatStore(updateShapeMoreButtonInfoIsOpenSelector)
    const transferImageInkToInk = useRoomChatStore(transferImageInkToInkSelector)
    const ref = useRef()
    const hoverRef = useRef()
    const imageinkwidthbgRef = useRef()
    const imageinkcolorbgRef = useRef()
    const [rotateIcon, setRotateIcon] = useState(rotateIconHref)
    if(shapeType === ShapeType.rectangle || shapeType === ShapeType.image || shapeType === ShapeType.textbox || shapeType === ShapeType.imageInk){
        rx = width/2
        ry = height/2
        cx = x+rx
        cy = y+ry
    }
    useEffect(() => {
        const shape = document.getElementById(markId)
        ref.current.shape = shape
        ref.current.children.forEach(e => e.shape = shape)
    },[markId])

    useEffect(() => {
        if(currentActiveShape === markId){
            select(ref.current).raise()
            ref.current.style['pointer-events'] = 'all'
        }else{
            ref.current.style['pointer-events'] = 'none'
            updateShapeMoreButtonInfoIsOpen(false) 
            setImageInkColorPickerInfo({left: 0, top:0, isActive: false})
            setImageInkStrokeWidthSliderInfo({left: 0, top:0, isActive: false, value: 0})
        }
    }, [currentActiveShape])

    const triggerTransferToInk = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if(currentActiveShape == null){
            return 
        }

        if(e.pointerType === 'mouse' && e.buttons === 2){ //右鍵
            return
        }

        if(useLocalStorageStore.getState().imageInkToHintShowNextTime === true){
            imageInkToInkHint(() => {
                const doNotShowNextTimeCheckBox = document.getElementById('do-not-show-next-time')
                useLocalStorageStore.getState().setImageInkToHintShowNextTime(!doNotShowNextTimeCheckBox.checked)
                transferToInk(e)
            })
        }else{
            transferToInk(e)
        }
        hideHoverText()
        
    }


    const transferToInk = async(e) => {
        
        const {userId, roomId} = useUserConnectionStore.getState()

        updateShapeMoreButtonInfoIsOpen(false)
        setImageInkColorPickerInfo({left: 0, top: 0, isActive: false})
        setImageInkStrokeWidthSliderInfo({left: 0, top:0, isActive: false, value: 0})

        const shape = e.target.parentElement.shape
        const shapeGroup = e.target.parentElement.shape.parentElement

        const loadingSvg = shape.cloneNode(true)
        const imageIconWidth = 50
        loadingSvg.id = uuid()
        loadingSvg.setAttribute('href', loadingImage)
        loadingSvg.setAttribute('width', imageIconWidth)
        loadingSvg.setAttribute('height', imageIconWidth)
        loadingSvg.setAttribute('x', parseFloat(shape.getAttribute('x')) + parseFloat(shape.getAttribute('width'))/2 - imageIconWidth/2)
        loadingSvg.setAttribute('y', parseFloat(shape.getAttribute('y')) + parseFloat(shape.getAttribute('height'))/2 - imageIconWidth/2)
        loadingSvg.style.outline = 'none'
        shapeGroup.appendChild(loadingSvg)
        await sleep(200)
        loadingSvg.remove()
       
        transferImageInkToInk(useUserConnectionStore.getState().userId, e.target.parentElement.dataset.shapeid)
        const history = getLatestHistory()
        if(history){
            const detail = history.details[0]
            const addMarks = detail.after
            const dataMarks = addMarks.map(mark => {
                return {
                    roomID: roomId,
                    accountID: userId,
                    pageID: useRoomChatStore.getState().currPageID,
                    id: mark.id,
                    type: mark.type,
                    content: JSON.stringify(mark)
                }
            })

            const needToRemoveImageInkIds = [detail.before.id]

            if(dataMarks.length > 0){
                apiAddDrawObjectMulti(dataMarks)
            }

            if(needToRemoveImageInkIds.length > 0){
                apiDeleteDrawObjectMulti(needToRemoveImageInkIds)
            }

            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                const event = new SocketEvent(SocketEventType.OPPONENT_TRANSFER_IMAGE_INK_TO_INK, {addMarks, needToRemoveImageInkIds}, null, roomId)
                
                apiMsgToOther({
                    roomID:  roomId,
                    accountID: userId,
                    content: JSON.stringify(event)
                })
            }
        }

    }

    const toggleColorPicker = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if(currentActiveShape == null){
            return 
        }

        if(e.pointerType === 'mouse' && e.buttons === 2){ //右鍵
            return
        }
        hideHoverText(e)
        updateShapeMoreButtonInfoIsOpen(false) 
        setImageInkStrokeWidthSliderInfo({left: 0, top:0, isActive: false, value: 0})
        
        // const {x, y} = updatePointer(e)
        const {ratio, imageInkColorPickerInfo} = useRoomChatStore.getState()
        const box = imageinkcolorbgRef.current.getBBox()
        const box2 = document.getElementById(currentActiveShape).getBBox()

        let yPos
        let direction 
        if(imageinkcolorbgRef.current.dataset.pos === 'top'){
            yPos = (box2.y)*ratio
            direction = 'down'
        }else if(imageinkcolorbgRef.current.dataset.pos === 'bottom'){
            yPos = (box2.y + box2.height)*ratio - 80
            direction = 'up'
        }else if(imageinkcolorbgRef.current.dataset.pos === 'inside-bottom'){
            yPos = (box2.y + box2.height - box.height-50)*ratio - 80
            direction = 'up'
        }
        
        setImageInkColorPickerInfo({
            left: (box.x)*ratio,
            top: yPos,
            isActive:!imageInkColorPickerInfo.isActive,
            direction
        })
    }

    const toggleStrokeWidthPicker = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if(currentActiveShape == null){
            return 
        }

        if(e.pointerType === 'mouse' && e.buttons === 2){ //右鍵
            return
        }

        updateShapeMoreButtonInfoIsOpen(false) 
        setImageInkColorPickerInfo({left: 0, top:0, isActive: false})

        

        // console.log(cx, cy, rx, ry, x, y, width, height)

        const info = getRectControlElementPositionInfo(cx,rx,cy,ry, strokeWidth, true, !useRoomChatStore.getState().imageInkStrokeWidthSliderInfo.isActive)
        const controlElementChildren =  ref.current.children
        for(let i = 0 ; i < controlElementChildren.length ; i++){
            const element = controlElementChildren[i]
            const className = element.getAttribute('class')
            Object.keys(info[className]).forEach(key => {
                element.setAttribute(key, info[className][key])
            })
        }
        const ratio = useRoomChatStore.getState().ratio
        const box = imageinkwidthbgRef.current.getBBox()
        const offsetY = 34
        const offsetX = 0
        const currentImageInk = useRoomChatStore.getState().imageInks.find(imageInk => imageInk.id === currentActiveShape)

        setImageInkStrokeWidthSliderInfo({
            left: (box.x+offsetX)*ratio,
            top: (box.y+offsetY)*ratio,
            isActive:!useRoomChatStore.getState().imageInkStrokeWidthSliderInfo.isActive,
            value: parseInt(currentImageInk.imageInkData.StrokeWidth)
        })

    }

    const showHoverText = (e, textContent) => {
        
        const {x:textX, y:textY } = e.target.getBBox()
        const svgns = "http://www.w3.org/2000/svg"
        const textElement = document.createElementNS( svgns ,'text' )
        
        textElement.setAttribute('x', textX)
        textElement.setAttribute('y', textY+100)
        textElement.setAttribute('stroke', '#494949')
        textElement.style['font-size'] = '1.2em'
        textElement.style['font-weight'] = '100'
        textElement.classList.add(hoverObjClass)
        textElement.textContent = textContent
            
        ref.current.parentElement.appendChild(textElement)

        const {x, y, width, height} = textElement.getBBox()

        const rectElement = document.createElementNS( svgns ,'rect' )
        const paddingX = 10
        const paddingY = 3
        rectElement.setAttribute('height', height + paddingY*2)
        rectElement.setAttribute('width', width + paddingX*2)
        rectElement.setAttribute('x', x - paddingX)
        rectElement.setAttribute('y', y - paddingY)
        rectElement.setAttribute('stroke', '#494949')
        rectElement.setAttribute('rx', '.1em')
        rectElement.style.fill = '#f1f2f3'
        rectElement.classList.add(hoverObjClass)
        ref.current.parentElement.insertBefore(rectElement, textElement)
        
    }

    const hideHoverText = (e) => {
        const hoverObjs = [...document.querySelectorAll(`.${hoverObjClass}`)]
        hoverObjs.forEach(obj => {
            obj.remove()
        })
    }


    const renderElement = () => {
        if(shapeType === ShapeType.rectangle || shapeType === ShapeType.ellipse || shapeType === ShapeType.image){
            const info = getRectControlElementPositionInfo(cx, rx, cy, ry, strokeWidth, false, false)
            return (
                <>
                    <image className='rotate' {...info.rotate} href={rotateIcon} onMouseEnter={() => setRotateIcon(rotateIconHoverHref)} onMouseLeave={() => setRotateIcon(rotateIconHref)}/>
                    <circle className='morebg' {...info.morebg} data-isbg={true}/>
                    <image className='more' {...info.more} href={moreIconHref} />
                    <circle className='nw' {...info.nw} onMouseEnter={() => setRotateIcon(rotateIconHoverHref)} onMouseLeave={() => setRotateIcon(rotateIconHref)}/>
                    <circle className='ne' {...info.ne}/>
                    <circle className='sw' {...info.sw}/>
                    <circle className='se' {...info.se}/>
                    <rect className='n' {...info.n}/>
                    <rect className='s' {...info.s}/>
                    <rect className='w' {...info.w}/>
                    <rect className='e' {...info.e}/>
                </>
            )
        }else if(shapeType === ShapeType.imageInk){
            const info = getRectControlElementPositionInfo(cx, rx, cy, ry, strokeWidth, true, false)        
            
            return (
                <>
                    <circle ref={imageinkwidthbgRef} className='imageinkwidthbg' {...info.imageinkwidthbg} data-isbg={true} onPointerDown={toggleStrokeWidthPicker} onMouseEnter={e => showHoverText(e, t('toolbar.inkSize'))} onMouseLeave={hideHoverText}/>
                    <circle className='imageinkwidth' {...info.imageinkwidth} />
                    <circle ref={imageinkcolorbgRef} className='imageinkcolorbg' {...info.imageinkcolorbg} data-pos={info.pos} data-isbg={true} onPointerDown={toggleColorPicker} onMouseEnter={e => showHoverText(e, t('toolbar.inkColor'))} onMouseLeave={hideHoverText}/>
                    <rect className='imageinkcolor' {...info.imageinkcolor} style={{fill: imageInkFill}} />
                    <circle className='imageinktoinkbg' {...info.imageinktoinkbg} data-isbg={true} onPointerDown={triggerTransferToInk} onMouseEnter={e => showHoverText(e, t('toolbar.transferToInk'))} onMouseLeave={hideHoverText}/>
                    <image className='imageinktoink' {...info.imageinktoink} href={transferToInkHref}/>
                    <circle className='morebg' {...info.morebg} data-isbg={true} onMouseEnter={e => showHoverText(e, t('toolbar.moreOption'))} onMouseLeave={hideHoverText}/>
                    <image className='more' {...info.more} href={moreIconHref} />
                    <circle className='nw' {...info.nw} data-isimageink={true}/>
                    <circle className='ne' {...info.ne}/>
                    <circle className='sw' {...info.sw}/>
                    <circle className='se' {...info.se}/>
                </>
            )
        
        }else if(shapeType === ShapeType.line){
            const info = getLineControlElementPositionInfo(x1, y1, x2, y2)
            return(
                <>
                    <circle className='morebg' {...info.morebg} data-isbg={true}/>
                    <image className='more' {...info.more} href={moreIconHref} />
                    <circle className='lineControlBegin' {...info.lineControlBegin}/>
                    <circle className='lineControlEnd' {...info.lineControlEnd}/>
                </>
            )
        }else if (shapeType === ShapeType.textbox){
            const info = getRectControlElementPositionInfo(cx, rx, cy, ry, strokeWidth, false, false)
            return(
                <>
                    <image className='rotate' {...info.rotate} href={rotateIcon} onMouseEnter={() => setRotateIcon(rotateIconHoverHref)} onMouseLeave={() => setRotateIcon(rotateIconHref)}/>
                    <circle className='morebg' {...info.morebg} data-isbg={true}/>
                    <image className='more' {...info.more} href={moreIconHref} />
                    <circle className='nw' {...info.nw} onMouseEnter={() => setRotateIcon(rotateIconHoverHref)} onMouseLeave={() => setRotateIcon(rotateIconHref)}/>
                    <circle className='ne' {...info.ne}/>
                    <circle className='sw' {...info.sw}/>
                    <circle className='se' {...info.se}/>
                    <rect className='n' {...info.n}/>
                    <rect className='s' {...info.s}/>
                    <rect className='w' {...info.w}/>
                    <rect className='e' {...info.e}/>
                </>
            )
        }
    }
    return (
        <>
            <g ref={ref} data-shapeid={markId} className={`control-element ${currentActiveShape===markId ? 'active': 'inactive'}`} id={currentActiveShape===markId ? `${markId}-control` : ''} style={{transformOrigin:`${cx}px ${cy}px`, transform}}>
                {renderElement()}
            </g>
        </>
    )
}



export default React.memo(ShapeControlElement)
