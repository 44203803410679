import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './library/i18n'


ReactDOM.render(
  <Suspense fallback={<></>}>
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);