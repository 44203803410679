import { ShapeType, PointerState, ToolBarTool, useUserConnectionStore, useRoomChatStore, useToolbarSettingsStore, StrokeEvent, StrokeEventType, removeIds, AccountType, postMessageAction } from '../store'
import { deleteCookie, mathRound, notifyWindowsOrMac, sleep } from "../utils";
import { v4 as uuid } from 'uuid'
import { apiAddDrawObject, apiDeleteDrawObjectMulti, apiLeaveRoom } from "../api";
import { whiteboardMaxSize, whiteboardMinSize } from "../components/WhiteboardOptionsControl";



export const pointer = {
    x: 0,
    y: 0,
    cx: 0,
    cy: 0,
    dx: 0,
    dy: 0,
    tx: 0,
    ty: 0,
    p: 0,
    type: 'mouse',
};
const keys = {
    shift: false,
    meta: false,
    alt: false,
};

export function updatePointer(e, touchIdx=0) {
    const dpr = window.devicePixelRatio || 1;
    let left, right, top, bottom
    try{
        const target = e.target.closest('.whiteboard')
        const bounding = target ? target.getBoundingClientRect() : e.target.getBoundingClientRect()
        left = bounding.left
        right = bounding.right
        top = bounding.top
        bottom = bounding.bottom
    }catch(e){
        console.error(e)
    }
   
    
    const {current: scale} = useRoomChatStore.getState().scale
    const touch = e.touches ? e.touches[touchIdx] : null
    
    const adjustX = (clientX) =>{
        if(clientX > left && clientX < right) return clientX -left
        else if(clientX <= left) return 0
        else if(clientX >= right) return right - left
    }
    const adjustY = (clientY) => {
        if(clientY > top && clientY < bottom) return clientY -top
        else if(clientY <= top) return 0
        else if(clientY >= bottom) return bottom - top
    }

    const ratio = useRoomChatStore.getState().ratio
    let x = adjustX(touch ? touch.clientX : e.clientX) / ratio
    let y = adjustY(touch ? touch.clientY : e.clientY) / ratio
    const 
        cx = x *dpr,
        cy = y *dpr,
        dx = (x - pointer.x)/ scale,
        dy = (y - pointer.y)/ scale,
        type = e.pointerType,
        p = e.pressure === 0.5 ? 0 : e.pressure;

    pointer.x = x;
    pointer.y = y;
    pointer.cx = cx;
    pointer.cy = cy;
    pointer.dx = dx;
    pointer.dy = dy;
    pointer.tx += dx;
    pointer.ty += dy;
    pointer.p = p;
    pointer.type = type;
    keys.shift = e.shiftKey;
    keys.meta = e.metaKey;
    keys.alt = e.altKey;

    return pointer;
}
function handlePointerMove(e) {
    e.preventDefault()
    const {currPageID, pointerState, erase, addPointToCurrentMark, onDrawLine, onDrawRect, onDrawEllipse} = useRoomChatStore.getState()
    const {currentActiveTool, shapeStyle} = useToolbarSettingsStore.getState()
    updatePointer(e)

    if(pointerState !== PointerState.down){
        return
    }
  
    if(e.pointerType === 'touch' && (useRoomChatStore.getState().currentOnMultiTouch || useRoomChatStore.getState().currentOnScreenTouches.length >= 2)){
        handlePointerUp(e)
        return
    }

    const penSize = useToolbarSettingsStore.getState().size.value
    const {x, y, p} = getPointer()
    const point = {x: mathRound(x), y: mathRound(y), pressure: p}

    const dataChannel = useUserConnectionStore.getState().dataChannel
    const userId = useUserConnectionStore.getState().userId
    const accountType = useUserConnectionStore.getState().accountType

    if(currentActiveTool === ToolBarTool.eraser){
        
        erase( x, y, accountType===AccountType.invitee && userId)
        const removeIdArray = Array.from(removeIds)
        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentRemoveMarks,  {removeIds: removeIdArray})
            dataChannel.send(JSON.stringify(event)) 
        }


    }else if(currentActiveTool === ToolBarTool.shape){
        if(shapeStyle.name === ShapeType.line || shapeStyle.name === ShapeType.arrow || shapeStyle.name === ShapeType.doubleHeadedArrow){
            onDrawLine(currPageID, point)
            if(dataChannel && dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentOnDrawLine, point)
                dataChannel.send(JSON.stringify(event)) 
            }
        }
        else if(shapeStyle.name === ShapeType.ellipse){
            onDrawEllipse(currPageID, point)
            if(dataChannel && dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentOnDrawEllipse, point)
                dataChannel.send(JSON.stringify(event)) 
            }
        
        }else if(shapeStyle.name === ShapeType.rectangle){
            onDrawRect(currPageID, point)
            if(dataChannel && dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentOnDrawRect, point)
                dataChannel.send(JSON.stringify(event)) 
            }
        }

    }else if(currentActiveTool === ToolBarTool.textbox){

    }else if(currentActiveTool === ToolBarTool.pen){
        
        addPointToCurrentMark(currPageID, point, penSize)
    
        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentAddPointToCurrentMark, {point, penSize})
            dataChannel.send(JSON.stringify(event))         
        }
    }

}
function handlePointerUp(e, customAlert, t) {
    const {currPageID, updateMarksCreatedTime, createTextBox, updateShapesCreatedTime, pointerState, completeErase, updatePointerState, completeMark, completeShape, history, currentEraseId} = useRoomChatStore.getState()
    if(pointerState === PointerState.up){
        return
    }
    updatePointer(e);
    const {currentActiveTool} = useToolbarSettingsStore.getState()
    const {dataChannel, userId, roomId} = useUserConnectionStore.getState()

    if(currentActiveTool === ToolBarTool.eraser){
        const eraseHistory = history.find(h => h.id === currentEraseId)
        const eraseIds = eraseHistory.details.map(d => d.markId)

        if(eraseIds.length > 0){
            apiDeleteDrawObjectMulti(eraseIds).then(res => {
                if(res.status === 500){
                    const data = JSON.stringify({action: postMessageAction.error, type: 1, message: t('login.networkError')})
                    if(!notifyWindowsOrMac(data, true)){
                        deleteCookie('userInfo')
                        apiLeaveRoom({
                            roomID: useUserConnectionStore.getState().roomId,
                            accountID: useUserConnectionStore.getState().userId,
                        })
                        customAlert(t('login.networkError'), () => window.location.replace(`${window.location.pathname}/login`))
                    }else{
                        return 
                    }
                }
            })
        }
        const dropLastHistory = eraseIds.length === 0
        
        completeErase(dropLastHistory)
        updatePointerState(PointerState.up)


    }else if(currentActiveTool === ToolBarTool.shape){

        completeShape(currPageID)
        const shape = useRoomChatStore.getState().shapes[useRoomChatStore.getState().shapes.length-1]
        apiAddDrawObject({
            roomID: roomId,
            accountID: userId,
            pageID: currPageID,
            id: shape.id,
            type: shape.type,
            content: JSON.stringify(shape)
        }).then((res) => {
            if(res.status === 200){
                updateShapesCreatedTime(shape.id, new Date(res.data).getTime())
            }else if(res.status === 500){
                const data = JSON.stringify({action: postMessageAction.error, type: 1, message: t('login.networkError')})
                if(!notifyWindowsOrMac(data, true)){
                    deleteCookie('userInfo')
                    apiLeaveRoom({
                        roomID: useUserConnectionStore.getState().roomId,
                        accountID: useUserConnectionStore.getState().userId,
                    })
                    customAlert(t('login.networkError'), () => window.location.replace(`${window.location.pathname}/login`))
                }else{
                    return 
                }
            }
        })


        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentCompleteShape)
            dataChannel.send(JSON.stringify(event))
        }

    }else if(currentActiveTool === ToolBarTool.textbox){
        const currentMarkId = uuid()
        const point = getPointer()
        createTextBox(currPageID, currentMarkId, point, userId)
        console.log('create text box')
        const shape = useRoomChatStore.getState().shapes[useRoomChatStore.getState().shapes.length-1]
        apiAddDrawObject({
            roomID: roomId,
            accountID: userId,
            pageID: currPageID,
            id: currentMarkId,
            type: ToolBarTool.textbox,
            content: JSON.stringify(shape)
        }).then((res) => {
            if(res.status === 200){
                updateShapesCreatedTime(shape.id, new Date(res.data).getTime())
            }else if(res.status === 500){
                const data = JSON.stringify({action: postMessageAction.error, type: 1, message: t('login.networkError')})
                if(!notifyWindowsOrMac(data, true)){
                    deleteCookie('userInfo')
                    apiLeaveRoom({
                        roomID: useUserConnectionStore.getState().roomId,
                        accountID: useUserConnectionStore.getState().userId,
                    })
                    customAlert(t('login.networkError'), () => window.location.replace(`${window.location.pathname}/login`))
                }else{
                    return 
                }
            }
        })
        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentCreateTextbox, 
                {pageID: currPageID, id: currentMarkId, point, userId, penColor: shape.penColor})
            dataChannel.send(JSON.stringify(event))        
        }
        updatePointerState(PointerState.up)

    }else if(currentActiveTool === ToolBarTool.pen){
        completeMark(currPageID)
        const mark = useRoomChatStore.getState().marks[useRoomChatStore.getState().marks.length-1]
        apiAddDrawObject({
            roomID: roomId,
            accountID: userId,
            pageID: currPageID,
            id: mark.id,
            type: mark.type,
            content: JSON.stringify(mark)
        }).then((res) => {
            if(res.status === 200){
                updateMarksCreatedTime(mark.id, new Date(res.data).getTime())
            }else if(res.status === 500){
                const data = JSON.stringify({action: postMessageAction.error, type: 1, message: t('login.networkError')})
                if(!notifyWindowsOrMac(data, true)){
                    deleteCookie('userInfo')
                    apiLeaveRoom({
                        roomID: useUserConnectionStore.getState().roomId,
                        accountID: useUserConnectionStore.getState().userId,
                    })
                    customAlert(t('login.networkError'), () => window.location.replace(`${window.location.pathname}/login`))
                }else{
                    return 
                }
            }
        })

        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentCompleteMark)
            dataChannel.send(JSON.stringify(event))        
        }
    }

   
}
async function handlePointerDown(e) {
    e.preventDefault()
    if(document.activeElement?.type === 'number' && document.activeElement?.value){
        const value = Number(document.activeElement?.value)
        if(value < whiteboardMinSize || value > whiteboardMaxSize)
            return document.activeElement.blur()
        else
            document.activeElement.blur()
    }
    
    if(e.buttons !==1) return //必須要滑鼠左鍵或觸控
    if(e.pointerType === 'touch'){
        await sleep(32)
    }
    if(e.pointerType === 'touch' && (useRoomChatStore.getState().currentOnMultiTouch || useRoomChatStore.getState().currentOnScreenTouches.length >= 2)){
        handlePointerUp(e)
        return
    }

    const {currentActiveTool, shapeStyle} = useToolbarSettingsStore.getState()
    const {currPageID, updatePointerState, erase, pointerState, beginMark ,
        beginDrawLine, beginDrawRect, beginDrawEllipse} = useRoomChatStore.getState()


    if(pointerState === PointerState.up){
        updatePointer(e)
    }
    
    const {x, y, p} = getPointer()
    const point = {x: mathRound(x), y: mathRound(y), pressure: p}
    const penSize = useToolbarSettingsStore.getState().size.value
    const {color, penStyle} = useToolbarSettingsStore.getState()
    const currentMarkId = uuid()
    const dataChannel = useUserConnectionStore.getState().dataChannel
    const userId = useUserConnectionStore.getState().userId
    const accountType = useUserConnectionStore.getState().accountType

    if(currentActiveTool === ToolBarTool.eraser){
        updatePointerState(PointerState.down)
        
        erase( x, y, accountType===AccountType.invitee && userId)
        const removeIdArray = Array.from(removeIds)
        if(removeIdArray.length > 0 && dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentRemoveMarks, {removeIds: removeIdArray})
            dataChannel.send(JSON.stringify(event)) 
        }
        
    }else if(currentActiveTool === ToolBarTool.shape){
        if(shapeStyle.name === ShapeType.line || shapeStyle.name === ShapeType.arrow || shapeStyle.name === ShapeType.doubleHeadedArrow){
            beginDrawLine(currPageID, point, penSize, color, shapeStyle, currentMarkId, userId)
            if(dataChannel && dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentBeginDrawLine,
                    {id: currentMarkId, point, penColor:color.color, penSize, shapeStyle, userId})
                dataChannel.send(JSON.stringify(event))      
            }

        }else if(shapeStyle.name === ShapeType.ellipse){
            beginDrawEllipse(currPageID, point, penSize, color, shapeStyle, currentMarkId, userId)
            if(dataChannel && dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentBeginDrawEllipse,
                    {id: currentMarkId, point, penColor:color.color, penSize, shapeStyle, userId})
                dataChannel.send(JSON.stringify(event))  
            }
        }else if(shapeStyle.name === ShapeType.rectangle){
            beginDrawRect(currPageID, point, penSize, color, shapeStyle, currentMarkId, userId)
            if(dataChannel && dataChannel.readyState === 'open'){
                const event = new StrokeEvent(StrokeEventType.opponentBeginDrawRect,
                    {id: currentMarkId, point, penColor:color.color, penSize, shapeStyle, userId})
                dataChannel.send(JSON.stringify(event))  
            }
        }
            

    }else if(currentActiveTool === ToolBarTool.textbox){
        updatePointerState(PointerState.down)

    }else if(currentActiveTool === ToolBarTool.pen){
        
        beginMark(currPageID, point, penSize, color, penStyle, currentMarkId, userId)
        if(dataChannel && dataChannel.readyState === 'open'){
            const event = new StrokeEvent(StrokeEventType.opponentBeginMark, 
                {id: currentMarkId, points:[point], penColor:color.color, 
                penStyle, penSize, type: ToolBarTool.pen, userId})
            dataChannel.send(JSON.stringify(event))        
        }
    }
}

export default function useEvents({customAlert, t}) {
    const currentActiveTool = useToolbarSettingsStore((state) => state.currentActiveTool, (prev, curr) => {
        if(prev === ToolBarTool.cursor || curr === ToolBarTool.cursor){
            return false
        }
        return true
    })
    return {
        onPointerMove: currentActiveTool === ToolBarTool.cursor ? null : (e) => handlePointerMove(e),
        onPointerDown: currentActiveTool === ToolBarTool.cursor ? null : (e) => handlePointerDown(e),
        onPointerUp: currentActiveTool === ToolBarTool.cursor ? null : (e) => handlePointerUp(e, customAlert, t),
        onMouseLeave: currentActiveTool === ToolBarTool.cursor ? null : (e) => handlePointerUp(e, customAlert, t)
    };
}
export function getPointer() {
    return pointer;
}