import create from 'zustand'
import produce from 'immer'
import { persist, devtools } from 'zustand/middleware'
import { getStrokePath, getSVGLineAttributesPair, getSVGEllipseAttributesPair, getSVGRectAttributesPair, mathRound, isBetween, getPathRange, getHundredCeiling, getCirclePath, imageInkPathRadius, penSizeImageInkWidthMapper, imageInkPadding, getSmoothPathFromPoints, viewBoxZoomFactor } from './utils'
import { v4 as uuid } from 'uuid'
import { modifyPathAction } from './hooks/useModifyPathHandler'
import _sortBy from 'lodash/sortBy'
import findPathIntersections from "path-intersection";
import whiteImage from './images/Solid_white.svg'

// import simplifySvgPath from '@luncheon/simplify-svg-path'





export const ToolBarTool = {
    cursor: 'cursor',
    pen: 'pen',
    eraser: 'eraser',
    shape: 'shape',
    textbox: 'textbox',
}
export const HistoryType = {
    mark: 'mark',
    shape: 'shape',
    imageInk: 'imageInk',
    textboxTextChanged: 'textboxTextChanged',
    layerOrderChanged: 'layerOrderChanged',
    imageInksLayerOrderChanged: 'imageInksLayerOrderChanged',
    shapeColorChanged: 'shapeColorChanged',
    shapeStrokeWidthChanged: 'shapeStrokeWidthChanged',
    pageChanged: 'pageChanged',
    imageInkToInk: 'imageInkToInk',
}
export const PenStyles = [
    {
        name: 'pen',
        img:'images/ic_menu_pen_n@2x.png',
        imgHover:'images/ic_menu_pen_a&d@2x.png',
        opacity: '1'    
    }, //鋼筆
    // { 
    //     name: 'highlighter',
    //     img:'images/ic_menu_highlighter_n@2x.png',
    //     imgHover:'images/ic_menu_highlighter_a&d@2x.png',
    //     opacity: '0.5' 
        
    // }, //螢光筆
    // {
    //     name: 'chalk', 
    //     img:'images/ic_menu_chalk_n.png',
    //     imgHover:'images/ic_menu_chalk_a&d.png',
    //     opacity: '1'  
    // },//粉筆
    // { 
    //     name: 'brush',
    //     img:'images/ic_menu_chinese-pen_n.png',
    //     imgHover:'images/ic_menu_chinese-pen_a&d.png',
    //     opacity: '1'  
    // }, //毛筆
    // {
    //     name: 'marker', 
    //     img:'images/ic_menu_mark-pen_n.png',
    //     imgHover:'images/ic_menu_mark-pen_a&d.png',
    //     opacity: '1'  
    // },//麥克筆
 
]
export const EraserStyles = [
    // {
    //     name: 'small',
    //     img: 'images/ic_menu_eraser01_n.png',
    //     imgHover: 'images/ic_menu_eraser01_a&d.png'
        
    // },
    {
        name: 'largeEraser',
        img: 'images/ic_menu_eraser03_n@2x.png',
        imgHover: 'images/ic_menu_eraser03_a&d@2x.png'
    }
]

export const ShapeType = {
    line: 'line', ellipse: 'ellipse', rectangle: 'rectangle', arrow: 'arrow', doubleHeadedArrow: 'doubleHeadedArrow', image: 'image', textbox: 'textbox', imageInk: 'imageInk'
}
export const ShapeStyles = [
    { 
        name: ShapeType.line,
        img: 'images/ic_menu_line_n@2x.png',
        imgHover: 'images/ic_menu_line_a&d@2x.png'
    },
    {
        name: ShapeType.ellipse,
        img: 'images/ic_menu_circle_n@2x.png',
        imgHover: 'images/ic_menu_circle_a&d@2x.png'
    },
    {
        name: ShapeType.rectangle,
        img: 'images/ic_menu_rectangle_n@2x.png',
        imgHover: 'images/ic_menu_rectangle_a&d@2x.png'
    },
    // {
    //     name: ShapeType.arrow,
    //     img: 'images/ic_menu_arrow_n.png',
    //     imgHover: 'images/ic_menu_arrow_a&d.png'
    // },
    // {
    //     name: ShapeType.doubleHeadedArrow,
    //     img: 'images/ic_menu_arrow02_n.png',
    //     imgHover: 'images/ic_menu_arrow02_a&d.png'
    // }
]

export const PenColors = 
[
    {name: 'jungleGreen', color: '#27AE60'},
    {name: 'malachite', color: '#1FD430'},
    {name: 'green', color: '#00FF18'},
    {name: 'matisse', color:'#1C6A9D'},
    {name: 'cerulean', color:'#00ADEF'},
    {name: 'cyan', color:'#00F0FF'},
    {name: 'thunderbird', color:'#BF2110'},
    {name: 'cinnabar', color:'#E74C3C'},
    {name: 'red', color:'#FF0000'},
    {name: 'disco', color:'#911253'},
    {name: 'razzmatazz', color:'#DC0B76'},
    {name: 'frenchRose', color:'#F55096'}, 
    {name: 'buttercup', color:'#F1C40F'},
    {name: 'turbo', color:'#FEF200'},
    {name: 'parisDaisy', color:'#FFF36A'},
    {name: 'burntOrange', color:'#D35400'}, 
    {name: 'zest', color:'#E67E22'}, 
    {name: 'flushOrange', color:'#FF7800'}, 
    {name: 'white', color:'#FFFFFF'}, 
    {name: 'abbey', color:'#595B5C'}, 
    {name: 'bossanova', color:'#402B56'}, 
    {name: 'silverSand', color:'#BDC3C7'}, 
    {name: 'black', color:'#000000'}, 
    {name: 'metallicBronze', color: '#502D1D'}
]

export const PenColorsHorizontalOrders = 
[
    {name: 'green', color: '#00FF18'},
    {name: 'cyan', color:'#00F0FF'},
    {name: 'red', color:'#FF0000'},
    {name: 'frenchRose', color:'#F55096'},
    {name: 'parisDaisy', color:'#FFF36A'},
    {name: 'flushOrange', color:'#FF7800'}, 
    {name: 'bossanova', color:'#402B56'}, 
    {name: 'metallicBronze', color: '#502D1D'},

    {name: 'malachite', color: '#1FD430'},
    {name: 'cerulean', color:'#00ADEF'},
    {name: 'cinnabar', color:'#E74C3C'},
    {name: 'razzmatazz', color:'#DC0B76'},
    {name: 'turbo', color:'#FEF200'},
    {name: 'zest', color:'#E67E22'}, 
    {name: 'abbey', color:'#595B5C'},
    {name: 'black', color:'#000000'},  

    {name: 'jungleGreen', color: '#27AE60'},
    {name: 'matisse', color:'#1C6A9D'},
    {name: 'thunderbird', color:'#BF2110'},
    {name: 'disco', color:'#911253'},
    {name: 'buttercup', color:'#F1C40F'},
    {name: 'burntOrange', color:'#D35400'}, 
    {name: 'white', color:'#FFFFFF'}, 
    {name: 'silverSand', color:'#BDC3C7'}, 
]

export const PenSizeStyles = [
    {
        name: 'verySmallSize',
        img: 'images/ic_menu_strokes01_n@2x.png',
        imgHover: 'images/ic_menu_strokes01_a&d@2x.png',
        value: 1
    },
    {
        name: 'smallSize',
        img: 'images/ic_menu_strokes02_n@2x.png',
        imgHover: 'images/ic_menu_strokes02_a&d@2x.png',
        value: 3
    },
    {
        name: 'mediumSize',
        img: 'images/ic_menu_strokes03_n@2x.png',
        imgHover: 'images/ic_menu_strokes03_a&d@2x.png',
        value: 7
    },
    {
        name: 'largeSize',
        img: 'images/ic_menu_strokes04_n@2x.png',
        imgHover: 'images/ic_menu_strokes04_a&d@2x.png',
        value: 11
    },
    {
        name: 'veryLargeSize',
        img: 'images/ic_menu_strokes05_n@2x.png',
        imgHover: 'images/ic_menu_strokes05_a&d@2x.png',
        value: 15
    }
]
export const TextBoxStyles = [
    {
        name: 'horizontalTextbox',
        img: 'images/ic_menu_text-horizontal_n@2x.png',
        imgHover: 'images/ic_menu_text-horizontal_a&d@2x.png'
    }
]

export const PointerState = {
    up: 'up', down: 'down'
}


export const immer = config => (set, get) => config(fn => set(produce(fn)), get)

export const postMessageAction = {
    screenshot: 'screenshot',
    error: 'error',
    removeFromRoom: 'removeFromRoom',
    roomClosed: 'roomClosed'
}
export const deviceErrorType = {
    notAllowed: 'notAllowed',
    notDetected: 'notDetected',
    usedByApp: 'usedByApp'
}

export const perMarksToGetFromApi = 70
export const TextboxProperty = {
    defaultheight: 47,
    defaultWidth: 140 ,
    fontLineHeight: 25,
}
export const StrokeEventType = {
    clearAll: 'clearAll',
    opponentBeginMark: 'opponentBeginMark',
    opponentAddPointToCurrentMark: 'opponentAddPointToCurrentMark',
    opponentCompleteMark: 'opponentCompleteMark',

    opponentBeginDrawLine: 'opponentBeginDrawLine',
    opponentOnDrawLine: 'opponentOnDrawLine',

    opponentBeginDrawEllipse: 'opponentBeginDrawEllipse',
    opponentOnDrawEllipse: 'opponentOnDrawEllipse',

    opponentBeginDrawRect: 'opponentBeginDrawRect',
    opponentOnDrawRect: 'opponentOnDrawRect',

    opponentCompleteShape: 'opponentCompleteShape',

    opponentBeginDrag: 'opponentStartDrag',
    opponentOnDrag: 'opponentOnDrag',
    opponentCompleteDrag: 'opponentCompleteDrag',

    opponentOnResize: 'opponentOnResize',
    opponentCompleteResize: 'opponentCompleteResize',

    opponentOnRotate: 'opponentOnRotate',
    opponentCompleteRotate: 'opponentCompleteRotate',

    opponentRemoveMarks: 'opponentRemoveMarks',
    opponentRemoveShapes: 'opponentRemoveShapes',
    opponentShapeColorChanged: 'opponentShapeColorChanged',
    opponentShapeStrokeWidthChanged: 'opponentShapeStrokeWidthChanged',
    opponentUndo: 'opponentUndo',

    opponentCreateImage: 'opponentCreateImage',
    roomSizeChanged: 'roomSizeChanged',

    opponentBringToFront: 'opponentBringToFront',
    opponentBringForward: 'opponentBringForward',
    opponentSendBackward: 'opponentSendBackward',
    opponentSendToBack: 'opponentSendToBack',

    opponentCreateTextbox: 'opponentCreateTextbox',
    opponentOnTyping: 'opponentOnTyping',
    opponentUpdateTextBoxText: 'opponentUpdateTextBoxText',
    opponentUpdateTextBoxHeightAndTransform: 'opponentUpdateTextBoxHeightAndTransform',

    opponentMinimapChanged: 'opponentMinimapChanged',
    opponentPageDetailsChanged: 'opponentPageDetailsChanged',

    inviteeCurrPageID: 'inviteeCurrPageID',

    opponentChangeImageInkColor: 'opponentChangeImageInkColor',
    opponentChangeImageInkStrokeWidth: 'opponentChangeImageInkStrokeWidth'

}
export class StrokeEvent{
    constructor(strokeEventType, data){
        this.strokeEventType = strokeEventType
        this.data = data
    }
}
export const AccountType = {
    inviter: 'inviter',
    invitee: 'invitee'
}
export const UserPlatform = {
    web: 'web',
    remoteGo: 'remoteGo'
}
export const ratioToScrollThumbnail = 0.9
export const EMPTY_PAGES_ID = '00000000-0000-0000-0000-000000000000'

export let current = null
export let old = null
export let oldMid = null

export let opponentCurrent = null
export let opponentOld = null
export let opponentOldMid = null


export let erasePoints = []
export let removeIds = new Set()
export const MAX_UNDO_SIZE = 20
export const minFontSizeRem = 2

export let undoResult = {
    addMarks:[],
    deleteMarks:[],
    updateMarks:[],
    addShapes:[],
    deleteShapes:[],
    updateShapes:[],
    addImageInks:[],
    deleteImageInks:[],
    updateImageInks:[],
    newPageDetails: [],
    oldPageDetails: [],
    reset: function(){
        this.addMarks=[]
        this.deleteMarks=[]
        this.updateMarks=[]
        this.addShapes=[]
        this.deleteShapes=[]
        this.updateShapes=[]
        this.addImageInks=[]
        this.deleteImageInks=[]
        this.updateImageInks=[]
        this.newPageDetails=[]
        this.oldPageDetails=[]
    }
}

// pageDatas:[
//     {
//         marks:[], 全部marks
//         shapes:[] 全部shapes  //shapes可移動圖層
//         currentMark: null,  自己畫圖時加入，提筆後加入marks
//         opponentMark: null, 對方畫圖時加入，提筆後加入marks
//         modifyList: [], 自己修改已存在的畫筆時加入，multi touch 可同時修改多筆(移動)，提筆後加入marks
//         opponentModifyList: [], 對方修改已存在的畫筆時加入，multi touch 可同時修改多筆(移動)，提筆後加入marks
//         history: null，自己的歷史紀錄，用來undo
//     }
// ]

const pageDataTemplate = {
    marks: [],
    shapes: [],
    imageInks: [],
    currentMark: null,
    opponentMark: null,
    modifyList: [],
    history:[],
    opponentHistory:[]
}

let roomChatStore = (set, get) => ({
    roomId: '',
    currentPageWidth: 0,
    currentPageHeight: 0,
    currentPageIdx: 0,
    currPageID: EMPTY_PAGES_ID,
    inviteeCurrPageID: EMPTY_PAGES_ID,
    ...pageDataTemplate,
    currentActiveShape: null,
    shapeMoreButtonInfo: {
        x: 0,
        y: 0,
        isOpen: false,
        isImageInk: false
    },
    totalPages: 1,
    totalPagesOnInitial: -1,
    pointerState: PointerState.up,
    hasHistory: false,
    currentEraseId: null,
    scale:{
        current: 1,
        viewboxMinX: 0,
        viewboxMinY: 0
    },
    marksRangeIndex:{
        x:{},
        y:{}
    },
    ratio: 1,
    currentOnScreenTouches: [],
    currentOnMultiTouch: false,
    whiteboardTouchEventFire: false,
    pageDetails: [],
    opponentUndoResultTemp: {...undoResult},
    currentPointerPositionForZoom: [],
    whiteboardSizeResetTime: null,
    markAndShapesLastChanged: -1,
    isSwitchPageAction: false,
    isControlByInviter: true,
    imageInkColorPickerInfo: {left: 0, top: 0, isActive: false},
    imageInkStrokeWidthSliderInfo: {left: 0, top: 0, isActive: false, value: 0},
    setCurrentPageWidthHeight: (currentPageWidth, currentPageHeight) => set(state => {
        return{currentPageWidth, currentPageHeight}
    }),
    setImageInkStrokeWidthSliderInfo: (imageInkStrokeWidthSliderInfo) => set(state => {
        // const oldImageInkStrokeWidthSliderInfo = {...state.imageInkStrokeWidthSliderInfo}
        // oldImageInkStrokeWidthSliderInfo.left = imageInkStrokeWidthSliderInfo.left
        if(imageInkStrokeWidthSliderInfo.value == null){
            imageInkStrokeWidthSliderInfo.value = state.imageInkStrokeWidthSliderInfo.value
        }

        return {
            imageInkStrokeWidthSliderInfo
        }
    }),
    setImageInkColorPickerInfo: (imageInkColorPickerInfo) => set(state => {
        return {imageInkColorPickerInfo}
    }),
    setIsControlByInviter: (isControlByInviter) => set(state => {
        return {isControlByInviter}
    }),
    setInviteeCurrPageId: (inviteeCurrPageID) => set(state => {
        return {inviteeCurrPageID}
    }),
    setIsSwitchPageAction: (isSwitchPageAction) => set(state => {
        return {isSwitchPageAction}
    }),
    triggerMarkAndShapesChanged: () => set(state => {
        return {markAndShapesLastChanged: Date.now()}
    }),
    updateWhiteboardSizeResetTime: () => set(state => {
        return {whiteboardSizeResetTime: Date.now()}
    }),
    setCurrPageId: (currPageID, currentPageIdx) => set(state => {
        if(!currentPageIdx){
            currentPageIdx = state.pageDetails.findIndex(pageDetail => pageDetail.id === currPageID)
        }

        return {currPageID, currentPageIdx}
    }),
    setTotalPages: (totalPages) => set(state => {
        return {
            totalPages,
            totalPagesOnInitial: totalPages
        }
    }),

    resetTotalPageOnInitial: () => set(state => {
        return {
            totalPagesOnInitial: -1
        }
    }),
    updateCurrentPointerPositionForZoom: (currentPointerPositionForZoom) => set(state => {
        return{currentPointerPositionForZoom}
    }),
    setPageDetailsSizeByPageId: (pageId, width, height) => set(state => {
        return {
            pageDetails: state.pageDetails.map(pageDetail => {
                if(pageDetail.id === pageId){
                    return {
                        ...pageDetail, 
                        width,
                        height
                    }
                }
                return pageDetail
            })
        }
    }),
    setThumbnailByPageId: (pageId, thumbnail) => set(state => {
        return {
            pageDetails: state.pageDetails.map(pageDetail => {
                if(pageDetail.id === pageId){
                    return {
                        ...pageDetail, 
                        thumbnail
                    }
                }
                return pageDetail
            })
        }
    }),
    setPageDetails: (pageDetails) => set(state => {
        return {
            pageDetails,
            currentPageIdx: pageDetails.findIndex(pageDetail => pageDetail.id === state.currPageID),
            totalPages: pageDetails.length
        }
    }),
    setPageDetailsWithHistory: (pageDetails) => set(state => {
        const pageChangedHistoryDetail = {
            type: HistoryType.pageChanged,
            before: state.pageDetails
            // after: {
            //     pageDetails,
            //     currPageID: state.currPageID
            // },
        }
        const newHistory = [...state.history, {
            id: uuid(),
            details: [pageChangedHistoryDetail],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)


        return {
            pageDetails,
            currentPageIdx: pageDetails.findIndex(pageDetail => pageDetail.id === state.currPageID),
            hasHistory: true,
            history: newHistory
        }
    }),
    setOpponentUndoResultTemp: (opponentUndoResultTemp) => set(state => {
        return {opponentUndoResultTemp}
    }),
    setWhiteboardTouchEventFire: (whiteboardTouchEventFire) => set(state => {
        return{
            whiteboardTouchEventFire
        }
    }),
    setCurrentOnScreenTouches: (currentOnScreenTouches) => set(state => {
        return{
            currentOnScreenTouches
        }
    }),
    setCurrentOnMultiTouch: (currentOnMultiTouch) => set(state => {
        return{
            currentOnMultiTouch
        }
    }),
    resetAll: () => set(state => {
        return{
            ...pageDataTemplate,
            history: state.history,
            currentActiveShape: null,
            // opponentMark: state.opponentMark
        }
    }),
    loadAllMarksOrShapes: (datas) => set(state => {
        const newMarks = []
        const newShapes = []
        const newImageInks = []
        datas.forEach(d => {
            const data = JSON.parse(d.content)
            d.createTime = d.createTime.includes('Z') ? d.createTime : d.createTime+'Z'
            if(data.type === ToolBarTool.pen){
                newMarks.push({...data, createdAt: new Date(d.createTime).getTime()})
            }else{
                if(data.shapeStyle?.name === ShapeType.imageInk){
                    newImageInks.push({...data, createdAt: new Date(d.createTime).getTime()})
                }else{
                    newShapes.push({...data, createdAt: new Date(d.createTime).getTime()})
                }
            }
        })
        return{
            marks: newMarks,
            shapes: newShapes,
            imageInks: newImageInks
        }

    }),
    loadMarksAndShapesFromCache: ({marks, shapes}) => set(state => {
        return{
            marks,
            shapes
        }
    }),

    loadMarksOrShapes: (datas) => set(state => {
        const newMarks = []
        const newShapes = []
        datas.forEach(d => {
            const data = JSON.parse(d.content)
            d.createTime = d.createTime.includes('Z') ? d.createTime : d.createTime+'Z'
            if(data.type === ToolBarTool.pen){
                newMarks.push({...data, createdAt: new Date(d.createTime).getTime()})
            }else{
                newShapes.push({...data, createdAt: new Date(d.createTime).getTime()})
            }
        })
        

        return{
            marks: [...state.marks, ...newMarks],
            shapes: [...state.shapes, ...newShapes]
        }
    }),
    sortMarksAndShapes: () => set(state => {
        return{
            marks: _sortBy(state.marks, ['createdAt']),
            shapes:  _sortBy(state.shapes, ['createdAt']),
            markAndShapesLastChanged: Date.now()
        }
    }),

    addMark:(mark) => set(state => {
        return{
            marks:[...state.marks, mark]
        }
    }),
    fillEmptyMarksRangeIndex: (width, height) => set(state => {
        const x = state.marksRangeIndex.x
        const y = state.marksRangeIndex.y
        let i =0
        do{
            if(!x[i]){
                x[i] = []
            }
            i+=100
        }while(i<Number(width)+100)

        i = 0
        do{
            if(!y[i]){
                y[i] = []
            }
            i+=100
        }while(i< Number(height)+100)


        return{
            marksRangeIndex: {
                x, y 
            }
        }
    }),
    zoomIn: (isFinger, diff) => set(state => {
        // console.log('in')
        if(!isFinger){
            const percent = 0.1
            return {
                ratio: state.ratio * (1+percent)
            }
        }else{
            return{
                ratio: state.ratio * (1+diff/100)
            }
        }


        
    }),
    zoomOut: (isFinger, diff) => set(state => {
        if(!isFinger){
            const percent = 0.1
            return {
                ratio: state.ratio * (1-percent)
            }    
        }else{
            return {
                ratio: state.ratio * (1 - diff/100)
            }
        }
        
    }),
    pinchZoom: (scale) => set(state => {
        return {
            ratio: scale
        }

    }),

    updateShapeMoreButtonInfo: (shapeMoreButtonInfo) => set(state => {
        return {shapeMoreButtonInfo}
    }),
    updateShapeMoreButtonInfoIsOpen: (isOpen) => set(state => {
        return {
            shapeMoreButtonInfo: {...state.shapeMoreButtonInfo, isOpen}
        }
    }),
    setRatio: (ratio) => set(state => {
        return {
            ratio
        }
    }),
    updateCurrentActiveShape: (shapeId, isimageink) => set(state => {
        return {
            currentActiveShape: shapeId,
            currentActiveShapeIsImageInk: isimageink
        }
    }),
   


    // updateRoomId: (roomId) => set(state => {
    //     state.roomId = roomId
    // }),

    // switchPage: (currentPageIdx) => set(state => {
    //     state.currentPageIdx = currentPageIdx
    //     state.pageData = pageDataTemplate
    // }),
    // createNewPage: () => set(state => {
    //     // state.pageDatas.push(pageDataTemplate)
    //     state.totalPages++
    // }),
    // removePage: (pageIdx) => set(state => {
    //     // state.pageDatas.splice(pageIdx, 1)
    //     if(pageIdx === state.totalPages -1){
    //         state.currentPageIdx = pageIdx-1
    //     }else if(pageIdx === 0){
    //         state.currentPageIdx = 0
    //     }

    //     state.totalPages--
    //     state.pageData = pageDataTemplate
        
    // }),
    //移到最上層
    //放到array最後
    bringToFront: (shapeId, isImageInk) => set(state => { 
        if(isImageInk){
            const shapeIdx = state.imageInks.findIndex(s => s.id === shapeId)
            const length = state.imageInks.length 
            if(shapeIdx !== -1 && shapeIdx < length -1){
                const lastShape = state.imageInks[length -1]
                const currentShape = state.imageInks[shapeIdx]
                const firstPart = state.imageInks.slice(0, shapeIdx)
                const secondPart = state.imageInks.slice(shapeIdx+1, length)
    
                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.imageInksLayerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: lastShape.createdAt + 1},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [currentShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
    
                return {
                    imageInks: [...firstPart, ...secondPart, {...currentShape, createdAt: lastShape.createdAt + 1}],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }else{
            const shapeIdx = state.shapes.findIndex(s => s.id === shapeId)
            const length = state.shapes.length 
            if(shapeIdx !== -1 && shapeIdx < length -1){
                const lastShape = state.shapes[length -1]
                const currentShape = state.shapes[shapeIdx]
                const firstPart = state.shapes.slice(0, shapeIdx)
                const secondPart = state.shapes.slice(shapeIdx+1, length)
    
                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.layerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: lastShape.createdAt + 1},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [currentShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
    
                return {
                    shapes: [...firstPart, ...secondPart, {...currentShape, createdAt: lastShape.createdAt + 1}],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }
    }),
    //上移一層
    bringForward: (shapeId, isImageInk) => set(state => {
        if(isImageInk){
            const shapeIdx = state.imageInks.findIndex(s => s.id === shapeId)
            const length = state.imageInks.length 
            if(shapeIdx !== -1 && shapeIdx < length -1){
                const nextShape = state.imageInks[shapeIdx+1]
                const currentShape = state.imageInks[shapeIdx]
                const firstPart = state.imageInks.slice(0, shapeIdx)
                const secondPart = state.imageInks.slice(shapeIdx+2, length)
    
                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.imageInksLayerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: nextShape.createdAt},
                }
                const nextShapeChangedHistoryDetail = {
                    markId: nextShape.id,
                    type: HistoryType.imageInksLayerOrderChanged,
                    before: {createdAt: nextShape.createdAt},
                    after: {createdAt: currentShape.createdAt},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [currentShapeChangedHistoryDetail, nextShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
                return{
                    imageInks: [...firstPart, {...nextShape, createdAt: currentShape.createdAt}, {...currentShape, createdAt: nextShape.createdAt},...secondPart],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }

        }else{
            const shapeIdx = state.shapes.findIndex(s => s.id === shapeId)
            const length = state.shapes.length 
            if(shapeIdx !== -1 && shapeIdx < length -1){
                const nextShape = state.shapes[shapeIdx+1]
                const currentShape = state.shapes[shapeIdx]
                const firstPart = state.shapes.slice(0, shapeIdx)
                const secondPart = state.shapes.slice(shapeIdx+2, length)
    
                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.layerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: nextShape.createdAt},
                }
                const nextShapeChangedHistoryDetail = {
                    markId: nextShape.id,
                    type: HistoryType.layerOrderChanged,
                    before: {createdAt: nextShape.createdAt},
                    after: {createdAt: currentShape.createdAt},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [currentShapeChangedHistoryDetail, nextShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
                return{
                    shapes: [...firstPart, {...nextShape, createdAt: currentShape.createdAt}, {...currentShape, createdAt: nextShape.createdAt},...secondPart],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }
    }),

    //移到最下層
    sendToBack: (shapeId, isImageInk) => set(state => {
        if(isImageInk){
            const shapeIdx = state.imageInks.findIndex(s => s.id === shapeId)
            const length = state.imageInks.length 
            if(shapeIdx > 0){
                const firstShape = state.imageInks[0]
                const currentShape = state.imageInks[shapeIdx]
                const firstPart = state.imageInks.slice(0, shapeIdx)
                const secondPart = state.imageInks.slice(shapeIdx+1, length)
    
                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.imageInksLayerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: firstShape.createdAt},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [currentShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
                return {
                    imageInks: [{...currentShape, createdAt: firstShape.createdAt}, ...firstPart, ...secondPart],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
            
        }else{
            const shapeIdx = state.shapes.findIndex(s => s.id === shapeId)
            const length = state.shapes.length 
            if(shapeIdx > 0){
                const firstShape = state.shapes[0]
                const currentShape = state.shapes[shapeIdx]
                const firstPart = state.shapes.slice(0, shapeIdx)
                const secondPart = state.shapes.slice(shapeIdx+1, length)
    
                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.layerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: firstShape.createdAt},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [currentShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
                return {
                    shapes: [{...currentShape, createdAt: firstShape.createdAt}, ...firstPart, ...secondPart],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }
    }),

    //下移一層
    sendBackward: (shapeId, isImageInk) => set(state => {
        if(isImageInk){
            const shapeIdx = state.imageInks.findIndex(s => s.id === shapeId)
            const length = state.imageInks.length 
            if(shapeIdx > 0){
                const previousShape = state.imageInks[shapeIdx-1]
                const currentShape = state.imageInks[shapeIdx]
                const firstPart = state.imageInks.slice(0, shapeIdx-1)
                const secondPart = state.imageInks.slice(shapeIdx+1, length)

                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.imageInksLayerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: previousShape.createdAt},
                }
                const previousShapeChangedHistoryDetail = {
                    markId: previousShape.id,
                    type: HistoryType.imageInksLayerOrderChanged,
                    before: {createdAt: previousShape.createdAt},
                    after: {createdAt: currentShape.createdAt},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [previousShapeChangedHistoryDetail, currentShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

                return{
                    imageInks: [...firstPart, {...currentShape, createdAt: previousShape.createdAt}, {...previousShape, createdAt:currentShape.createdAt} ,...secondPart],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }

        }else{
            const shapeIdx = state.shapes.findIndex(s => s.id === shapeId)
            const length = state.shapes.length 
            if(shapeIdx > 0){
                const previousShape = state.shapes[shapeIdx-1]
                const currentShape = state.shapes[shapeIdx]
                const firstPart = state.shapes.slice(0, shapeIdx-1)
                const secondPart = state.shapes.slice(shapeIdx+1, length)

                const currentShapeChangedHistoryDetail = {
                    markId: currentShape.id,
                    type: HistoryType.layerOrderChanged,
                    before: {createdAt: currentShape.createdAt},
                    after: {createdAt: previousShape.createdAt},
                }
                const previousShapeChangedHistoryDetail = {
                    markId: previousShape.id,
                    type: HistoryType.layerOrderChanged,
                    before: {createdAt: previousShape.createdAt},
                    after: {createdAt: currentShape.createdAt},
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [previousShapeChangedHistoryDetail, currentShapeChangedHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

                return{
                    shapes: [...firstPart, {...currentShape, createdAt: previousShape.createdAt}, {...previousShape, createdAt:currentShape.createdAt} ,...secondPart],
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }
    }),
    updateImageInkColorByIdx: (imageInkIdx, color, imageId) => set(state => {
        const changeColorInk = {...state.imageInks[imageInkIdx]}
        const changeColorInkImageData = {...changeColorInk.imageInkData}

        changeColorInkImageData.Color = color
        changeColorInk.imageInkData = changeColorInkImageData
        changeColorInk.imageId = imageId

        const imageInksHistoryDetail = {
            markId: state.currentActiveShape,
            type: HistoryType.imageInk,
            before: state.imageInks[imageInkIdx],
            after: changeColorInk
        }
        const newHistory = [...state.history, {
            id: uuid(),
            pageId: state.currPageID,
            details: [imageInksHistoryDetail],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        return{
            imageInks: [...state.imageInks.slice(0, imageInkIdx), changeColorInk, ...state.imageInks.slice(imageInkIdx+1)],
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false,
            history: newHistory,
            hasHistory: newHistory.length > 0
        }
    }),

    updateImageInkStrokeWidthByIdx: (imageInkIdx, strokeWidth, imageId) => set(state => {
        const changeStrokeWidthInk = {...state.imageInks[imageInkIdx]}
        const changeStrokeWidthInkImageData = {...changeStrokeWidthInk.imageInkData}

        changeStrokeWidthInkImageData.StrokeWidth = strokeWidth
        changeStrokeWidthInk.imageInkData = changeStrokeWidthInkImageData
        changeStrokeWidthInk.imageId = imageId

        const imageInksHistoryDetail = {
            markId: state.currentActiveShape,
            type: HistoryType.imageInk,
            before: state.imageInks[imageInkIdx],
            after: changeStrokeWidthInk
        }
        const newHistory = [...state.history, {
            id: uuid(),
            pageId: state.currPageID,
            details: [imageInksHistoryDetail],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        return{
            imageInks: [...state.imageInks.slice(0, imageInkIdx), changeStrokeWidthInk, ...state.imageInks.slice(imageInkIdx+1)],
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false,
            history: newHistory,
            hasHistory: newHistory.length > 0
        }
    }),


    updateShapeColor:(shapeId, color) => set(state => {
        if(!shapeId) return
        let originalColor
        const newShapes = state.shapes.map(shape => {
            if(shape.id === shapeId && shape.shapeStyle?.name !== ShapeType.image){
                originalColor = shape.penColor
                return{...shape, penColor: color}
            }
            return shape
        })
        if(!originalColor) return 
        if(originalColor === color) return 
        
        const shapeColorChangedHistory = {
            markId: shapeId,
            type: HistoryType.shapeColorChanged,
            before: {penColor: originalColor},
            after: {penColor: color},
        }
        const newHistory = [...state.history, {
            id: uuid(),
            pageId: state.currPageID,
            details: [shapeColorChangedHistory],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        return{
            shapes: newShapes,
            history: newHistory,
            hasHistory: newHistory.length > 0,
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    opponentUpdateShapeColor: (shapeId, color) => set(state => {
        if(!shapeId) return
        let originalColor
        const newShapes = state.shapes.map(shape => {
            if(shape.id === shapeId && shape.shapeStyle?.name !== ShapeType.image){
                originalColor = shape.penColor
                return{...shape, penColor: color}
            }
            return shape
        })
        if(!originalColor) return 
        return{
            shapes: newShapes,
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    updateShapeWidth:(shapeId, strokeWidth) => set(state => {
        if(!shapeId) return
        let originalStrokeWidth
        const newShapes = state.shapes.map(shape => {
            if(shape.id === shapeId && shape.shapeStyle?.name !== ShapeType.image){
                originalStrokeWidth = shape.strokeWidth
                return{...shape, strokeWidth: strokeWidth}
            }
            return shape
        })
        if(!originalStrokeWidth) return 
        if(originalStrokeWidth === strokeWidth) return
        
        const shapeColorChangedHistory = {
            markId: shapeId,
            type: HistoryType.shapeStrokeWidthChanged,
            before: {strokeWidth: originalStrokeWidth},
            after: {strokeWidth: strokeWidth},
        }
        const newHistory = [...state.history, {
            id: uuid(),
            pageId: state.currPageID,
            details: [shapeColorChangedHistory],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        return{
            shapes: newShapes,
            history: newHistory,
            hasHistory: newHistory.length > 0,
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    opponentUpdateShapeWidth: (shapeId, strokeWidth) => set(state => {
        if(!shapeId) return
        let originalStrokeWidth
        const newShapes = state.shapes.map(shape => {
            if(shape.id === shapeId && shape.shapeStyle?.name !== ShapeType.image){
                originalStrokeWidth = shape.strokeWidth
                return{...shape, strokeWidth: strokeWidth}
            }
            return shape
        })
        if(!originalStrokeWidth) return 
        return{
            shapes: newShapes,
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),

    clearMarks: (pageID, accountType = AccountType.inviter, userId, isFromOther) => set(state => {
        if(AccountType.inviter === accountType){
            if(state.marks.length === 0 && state.shapes.length === 0 && state.imageInks.length === 0){
                return
            }
            if(isFromOther){
                return {
                    marks: [], 
                    shapes: [],
                    imageInks: [],
                    currentActiveShape: null,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
            const markHistoryDetails = state.marks.map(mark => ({
                markId: mark.id,
                type: HistoryType.mark,
                before: mark,
                after: null
            }))
            const shapeHistoryDetails = state.shapes.map(shape => ({
                markId: shape.id,
                type: HistoryType.shape,
                before: shape,
                after: null
            }))
            const imageInksHistoryDetails = state.imageInks.map(imageInk => ({
                markId: imageInk.id,
                type: HistoryType.imageInk,
                before: imageInk,
                after: null
            }))

            const newHistory = [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details: [...markHistoryDetails, ...shapeHistoryDetails, ...imageInksHistoryDetails],
                completed: true
            }]
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
            return {
                marks: [], 
                shapes: [],
                imageInks: [],
                currentActiveShape: null,
                history: newHistory,
                hasHistory: newHistory.length > 0,
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }else if(AccountType.invitee === accountType){
            const markHistoryDetails = state.marks
            .filter(m => m.userId === userId)
            .map(mark => ({
                markId: mark.id,
                type: HistoryType.mark,
                before: mark,
                after: null
            }))
            const shapeHistoryDetails = state.shapes
            .filter(m => m.userId === userId)
            .map(shape => ({
                markId: shape.id,
                type: HistoryType.shape,
                before: shape,
                after: null
            }))

            const imageInksHistoryDetails = state.imageInks
            .filter(m => m.userId === userId)
            .map(imageInk => ({
                markId: imageInk.id,
                type: HistoryType.imageInk,
                before: imageInk,
                after: null
            }))

            if(markHistoryDetails.length === 0 && shapeHistoryDetails.length === 0 && imageInksHistoryDetails.length === 0){
                return
            }
            if(isFromOther){
                return {
                    marks: state.marks.filter(m => m.userId !== userId), 
                    shapes: state.shapes.filter(m => m.userId !== userId),
                    imageInks: state.imageInks.filter(m => m.userId !== userId),
                    currentActiveShape: null,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }


            const newHistory = [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details: [...markHistoryDetails, ...shapeHistoryDetails, ...imageInksHistoryDetails],
                completed: true
            }]
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
    
            return {
                marks: state.marks.filter(m => m.userId !== userId), 
                shapes: state.shapes.filter(m => m.userId !== userId),
                imageInks: state.imageInks.filter(m => m.userId !== userId),
                currentActiveShape: null,
                history: newHistory,
                hasHistory: newHistory.length > 0,
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }
    }),
    updateMarksCreatedTime: (id, timeInMills) => set(state => {
        return{
            marks: _sortBy(state.marks.map(m => {
                if(m.id === id){
                    return{...m, createdAt: timeInMills}
                }
                return m
            }), ['createdAt']),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    updateShapesCreatedTime: (id, timeInMills, isimageink) => set(state => {
        if(isimageink){
            return{
                imageInks: _sortBy(state.imageInks.map(s => {
                    if(s.id === id){
                        return{...s, createdAt: timeInMills}
                    }
                    return s
                }), ['createdAt']),
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }

        }else{
            return{
                shapes: _sortBy(state.shapes.map(s => {
                    if(s.id === id){
                        return{...s, createdAt: timeInMills}
                    }
                    return s
                }), ['createdAt']),
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }
        
    }),
    erase: (x, y, userId) => set(state => {
        let eraseId = state.currentEraseId
        erasePoints.push({x, y, pressure: 1})
        let r = (20 + erasePoints.length*1.3)/state.ratio
        let erasePath = getStrokePath(erasePoints, r, false)
        if(erasePoints.length === 1){
            r = 15/state.ratio
            erasePath = getCirclePath(x, y, r)
        }
        let minX = 99999
        let maxX = -99999
        let minY = 99999
        let maxY = -99999
        erasePoints.forEach(e => {
            if(e.x < minX) minX = e.x
            if(e.x > maxX) maxX = e.x
            if(e.y < minY) minY = e.y
            if(e.y > maxY) maxY = e.y 
        })
        if(erasePoints.length > 7){
            erasePoints.splice(0, 4)
        }
        const factor = getHundredCeiling(r)
        let offsetX, offsetY
        let xLow = (minX + maxX)/2 - factor/2
        offsetX = (xLow < 0) ? 0-xLow : 0
        xLow += offsetX
        let xHigh = (minX + maxX)/2 + factor/2 + offsetX

        let yLow = (minY + maxY)/2 - factor/2
        offsetY = (yLow < 0) ? 0-yLow : 0
        yLow += offsetY
        let yHigh = (minY + maxY)/2 + factor/2 + offsetY

        removeIds.clear()
        let details = []
        const newMarks = state.marks.filter(mark => {
            if(userId && mark.userId !== userId){
                return true
            }
            if((isBetween(xLow, mark.xLow, mark.xHigh) || isBetween(xHigh, mark.xLow, mark.xHigh)) 
            && (isBetween(yLow, mark.yLow, mark.yHigh) || isBetween(yHigh, mark.yLow, mark.yHigh))){
                if(findPathIntersections(erasePath, mark.paths.join()).length > 0){
                    removeIds.add(mark.id)
                    details.push({
                        markId: mark.id,
                        type: HistoryType.mark,
                        before: mark, 
                        after: null
                    })
                    return false
                }
            }
            return true
        })
        

        if(eraseId){
            const newHistory = state.history.map(history => {
                if(history.id === eraseId){
                    return{
                        ...history,
                        details: [
                            ...history.details, ...details
                        ]
                    }
                }
                return history
            })
        
            return{
                marks: newMarks,
                history: newHistory,
                hasHistory: newHistory.length > 0
            }
        }else{
            eraseId = uuid()
            const newHistory = [...state.history, {
                id: eraseId,
                pageId: state.currPageID,
                details,
                completed: true
            }]
            return{
                marks: newMarks,
                history: newHistory,
                hasHistory: newHistory.length > 0,
                currentEraseId: eraseId,
            }
        }
    }),
    completeErase: (dropLastHistory) => set(state => {
        erasePoints = []
        if(dropLastHistory){
            const newHistory = state.history.slice(0, state.history.length - 1)
            return{
                currentEraseId: null,
                history: newHistory,
                hasHistory: newHistory.length,
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }else{
            return{
                currentEraseId: null,
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }
    }),
    undo: () => set(state => {
        if(state.history.length === 0) return
        undoResult.reset()
        const history = state.history[state.history.length - 1]
        const historyMarksMap = {}
        const historyShapesMap = {}
        const historyImageInksMap = {}
        const historyLayerOrderChangedMap = {}
        const historyImageInksLayerOrderChangedMap = {}
        const historyShapeColorChangedMap = {}
        const historyShapeStrokeWidthChangedMap = {}
        let historyImageInkToInk = null
        let historyPageChangedDetail = null
        let currentActiveShape = null
        
        history.details.forEach(detail => {
            if(detail.type === HistoryType.mark){
                historyMarksMap[detail.markId] = detail
            }else if(detail.type === HistoryType.shape){
                historyShapesMap[detail.markId] = detail   
            }else if(detail.type === HistoryType.imageInk){
                historyImageInksMap[detail.markId] = detail   
            }else if(detail.type === HistoryType.layerOrderChanged){
                historyLayerOrderChangedMap[detail.markId] = detail
            }else if(detail.type === HistoryType.imageInksLayerOrderChanged){
                historyImageInksLayerOrderChangedMap[detail.markId] = detail
            }else if(detail.type === HistoryType.shapeColorChanged){
                historyShapeColorChangedMap[detail.markId] = detail
            }else if(detail.type === HistoryType.shapeStrokeWidthChanged){
                historyShapeStrokeWidthChangedMap[detail.markId] = detail
            }else if(detail.type === HistoryType.imageInkToInk){
                historyImageInkToInk = detail
            }else if(detail.type === HistoryType.pageChanged){
                historyPageChangedDetail = detail
            }
        })
        let filterMarks 
        if(Object.keys(historyMarksMap).length === 0){
            filterMarks = state.marks
        }else{
            filterMarks = state.marks.filter(mark => {
                const record = historyMarksMap[mark.id]
                if(record && !record.before){
                    delete historyMarksMap[mark.id]
                    undoResult.deleteMarks.push(mark.id)
                    return false
                }
                return true
            }).map(mark => {
                const record = historyMarksMap[mark.id]
                if(record && record.before){
                    delete historyMarksMap[mark.id]
                    undoResult.updateMarks.push({
                        ...mark,
                        paths: record.before.paths
                    })
                    return {
                        ...mark,
                        paths: record.before.paths
                    }
                }
                return mark
            })
        }
        let filterShapes
        
        if(Object.keys(historyShapesMap).length === 0){
            filterShapes = state.shapes
        }else{
            filterShapes = state.shapes.filter(shape => {
                const record = historyShapesMap[shape.id]
                if(record && !record.before){
                    delete historyShapesMap[shape.id]
                    undoResult.deleteShapes.push(shape.id)
                    return false
                }
                return true
            }).map(shape => {
                const record = historyShapesMap[shape.id]
                if(record && record.before){
                    delete historyShapesMap[shape.id]
                    undoResult.updateShapes.push({
                        ...shape,
                        svgAttributesPair: record.before?.svgAttributesPair || shape.svgAttributesPair,
                        transform: record.before?.transform || shape.transform,
                        text: record.before?.text || shape.text,
                        fontSizeRem: record.before?.fontSizeRem || shape.fontSizeRem
                    })
                    return {
                        ...shape,
                        svgAttributesPair: record.before?.svgAttributesPair || shape.svgAttributesPair,
                        transform: record.before?.transform || shape.transform,
                        text: record.before?.text || shape.text,
                        fontSizeRem: record.before?.fontSizeRem || shape.fontSizeRem
                    }
                }
                return shape
            })  
        } 

        let filterImageInks
        if(Object.keys(historyImageInksMap).length === 0){
            filterImageInks = state.imageInks
        }else{
            filterImageInks = state.imageInks.filter(imageInk => {
                const record = historyImageInksMap[imageInk.id]
                if(record && !record.before){
                    delete historyImageInksMap[imageInk.id]
                    undoResult.deleteImageInks.push(imageInk.id)
                    return false
                }
                return true
            }).map(imageInk => {
                const record = historyImageInksMap[imageInk.id]
                if(record && record.before){
                    delete historyImageInksMap[imageInk.id]
                    const imageInkData = record.before?.imageInkData || imageInk.imageInkData
                    if(record.before.zoomFactor){
                        imageInkData.zoomFactor = record.before.zoomFactor
                    }

                    undoResult.updateImageInks.push({
                        ...imageInk,
                        svgAttributesPair: record.before?.svgAttributesPair || imageInk.svgAttributesPair,
                        transform: record.before?.transform || imageInk.transform,
                        imageId: record.before?.imageId || imageInk.imageId,
                        imageInkData
                    })
                    return {
                        ...imageInk,
                        svgAttributesPair: record.before?.svgAttributesPair || imageInk.svgAttributesPair,
                        transform: record.before?.transform || imageInk.transform,
                        imageId: record.before?.imageId || imageInk.imageId,
                        imageInkData
                    }
                }
                return imageInk
            })  
        } 

       

        if(Object.keys(historyLayerOrderChangedMap).length !== 0){
            filterShapes = _sortBy(
                filterShapes.map(shape => {
                    const record = historyLayerOrderChangedMap[shape.id]
                    if(record && record.before){
                        undoResult.updateShapes.push({
                            ...shape,
                            createdAt: record.before?.createdAt
                        })
                        return{
                            ...shape,
                            createdAt: record.before?.createdAt
                        }
                    }
                    return shape
                })
            , ['createdAt'])
        }
        if(Object.keys(historyImageInksLayerOrderChangedMap).length !== 0){
            filterImageInks = _sortBy(
                filterImageInks.map(imageInk => {
                    const record = historyImageInksLayerOrderChangedMap[imageInk.id]
                    if(record && record.before){
                        undoResult.updateImageInks.push({
                            ...imageInk,
                            createdAt: record.before?.createdAt
                        })
                        return{
                            ...imageInk,
                            createdAt: record.before?.createdAt
                        }
                    }
                    return imageInk
                })
            , ['createdAt'])
        }

        if(Object.keys(historyShapeColorChangedMap).length !== 0){
            filterShapes = filterShapes.map(shape => {
                const record = historyShapeColorChangedMap[shape.id]
                if(record){
                    undoResult.updateShapes.push({
                        ...shape,
                        penColor: record.before?.penColor
                    })
                    return{
                        ...shape,
                        penColor: record.before?.penColor
                    }
                }
                return shape
            })
        }

        if(Object.keys(historyShapeStrokeWidthChangedMap).length !== 0){
            filterShapes = filterShapes.map(shape => {
                const record = historyShapeStrokeWidthChangedMap[shape.id]
                if(record){
                    undoResult.updateShapes.push({
                        ...shape,
                        strokeWidth: record.before?.strokeWidth
                    })
                    return{
                        ...shape,
                        strokeWidth: record.before?.strokeWidth
                    }
                }
                return shape
            })
        }

        

        if(historyImageInkToInk){
            
            //historyImageInkToInk.before: imageInk
            //historyImageInkToInk.after: marks
            const needRemoveMarkIds = historyImageInkToInk.after.map(mark => mark.id)
            const needAddImageInk = historyImageInkToInk.before

            filterMarks = filterMarks.filter(mark => !needRemoveMarkIds.includes(mark.id))
            filterImageInks = [...filterImageInks, needAddImageInk]
            undoResult.addImageInks.push(needAddImageInk)
            undoResult.deleteMarks.push(...needRemoveMarkIds)

            currentActiveShape = needAddImageInk.id
            useToolbarSettingsStore.getState().updateCurrentActiveTool(ToolBarTool.cursor)
        }

        let oldPageDetails = state.pageDetails
        let currPageID = state.currPageID
        let currentPageIdx = state.currentPageIdx
        let totalPages = state.totalPages
        if(historyPageChangedDetail){
            oldPageDetails = historyPageChangedDetail.before
            currentPageIdx = oldPageDetails.findIndex(p => p.id === currPageID)
            if(currentPageIdx === -1){
                currentPageIdx = 0
            }
            currPageID = oldPageDetails[currentPageIdx].id
            totalPages = oldPageDetails.length
            undoResult.newPageDetails = oldPageDetails.map(detail => {
                return{
                    id: detail.id,
                    width: detail.width,
                    height: detail.height
                }
            })
            undoResult.oldPageDetails = state.pageDetails.map(detail => {
                return{
                    id: detail.id,
                    width: detail.width,
                    height: detail.height
                }
            })
        }

        const addMarks = Object.values(historyMarksMap).filter(h => h.before).map(h => h.before)
        undoResult.addMarks = [...undoResult.addMarks, ...addMarks]
        const addShapes = Object.values(historyShapesMap).filter(s => s.before).map(s => s.before)
        undoResult.addShapes = [...undoResult.addShapes, ...addShapes]
        const addImageInks = Object.values(historyImageInksMap).filter(s => s.before).map(s => s.before)
        undoResult.addImageInks = [...undoResult.addImageInks, ...addImageInks]

    
        const newHistory = state.history.slice(0, -1)
        console.log(undoResult)

        if(undoResult.updateShapes.length === 1){
            currentActiveShape = undoResult.updateShapes[0].id
        }else if(undoResult.updateImageInks.length === 1){
            currentActiveShape = undoResult.updateImageInks[0].id
        }else if(undoResult.addShapes.length === 1 && undoResult.addImageInks.length === 0){
            currentActiveShape = undoResult.addShapes[0].id
        }else if(undoResult.addImageInks.length === 1 && undoResult.addShapes.length === 0){
            currentActiveShape = undoResult.addImageInks[0].id
        }

        return{
            history: newHistory,
            hasHistory: newHistory.length,
            currentActiveShape,
            marks: _sortBy([...filterMarks, ...addMarks], ['createdAt']),
            shapes: _sortBy([...filterShapes, ...addShapes], ['createdAt']),
            imageInks: _sortBy([...filterImageInks, ...addImageInks], ['createdAt']),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false,
            pageDetails: oldPageDetails,
            currPageID,
            currentPageIdx,
            totalPages
        }
    }),
    opponentUndo: (undoResult) => set(state => {
        let marks
        if(undoResult.deleteMarks.length > 0){
            marks = state.marks.filter(mark => {
                if(undoResult.deleteMarks.includes(mark.id)) return false
                return true
            })
            marks = _sortBy(marks, ['createdAt'])
        }else if(undoResult.updateMarks.length > 0){
            marks = state.marks.map(mark => {
                const m = undoResult.updateMarks.find(i => i.id === mark.id)
                if(m){
                    return m
                }else{
                    return mark
                }
            })
            marks = _sortBy(marks, ['createdAt'])
        }else if(undoResult.addMarks.length > 0){
            marks = [...state.marks, ...undoResult.addMarks.filter(o => state.marks.findIndex(m => m.id === o.id)===-1)]
            marks = _sortBy(marks, ['createdAt'])
        }else{
            marks = state.marks
        } 

        let shapes
        if(undoResult.deleteShapes.length > 0){
            shapes = state.shapes.filter(shape => {
                if(undoResult.deleteShapes.includes(shape.id)) return false
                return true
            })
            shapes = _sortBy(shapes, ['createdAt'])
        }else if(undoResult.updateShapes.length > 0){
            shapes = state.shapes.map(shape => {
                const s = undoResult.updateShapes.find(i => i.id === shape.id)
                if(s){
                    return s
                }else{
                    return shape
                }
            })
            shapes = _sortBy(shapes, ['createdAt'])
        }else if(undoResult.addShapes.length > 0){
            shapes = [...state.shapes, ...undoResult.addShapes.filter(o => state.shapes.findIndex(s => s.id === o.id)===-1)]
            shapes = _sortBy(shapes, ['createdAt'])
        }else{
            shapes = state.shapes
        }


        // this.addImageInks=[]
        // this.deleteImageInks=[]
        // this.updateImageInks=[]
        let imageInks
        if(undoResult.deleteImageInks.length > 0){
            imageInks = state.imageInks.filter(imageInk => {
                if(undoResult.deleteImageInks.includes(imageInk.id)) return false
                return true
            })
            imageInks = _sortBy(imageInks, ['createdAt'])
        }else if(undoResult.updateImageInks.length > 0){
            imageInks = state.imageInks.map(imageInk => {
                const s = undoResult.updateImageInks.find(i => i.id === imageInk.id)
                if(s){
                    return s
                }else{
                    return imageInk
                }
            })
            imageInks = _sortBy(imageInks, ['createdAt'])
        }else if(undoResult.addImageInks.length > 0){
            imageInks = [...state.imageInks, ...undoResult.addImageInks.filter(o => state.imageInks.findIndex(i => i.id === o.id)===-1)]
            imageInks = _sortBy(imageInks, ['createdAt'])
        }else{
            imageInks = state.imageInks
        }

        return {
            marks,
            shapes,
            imageInks,
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }


    }),
    clearHistory: () => set(state => {
        return{
            history: [],
            hasHistory: false
        }
    }),
    clearCurrentActiveShape: () => set(state => {
        if(state.currentActiveShape){
            if(state.currentActiveShapeIsImageInk){
                const imageInksHistoryDetail = {
                    markId: state.currentActiveShape,
                    type: HistoryType.imageInk,
                    before: state.imageInks.find(imageInk => imageInk.id === state.currentActiveShape),
                    after: null
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [imageInksHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
                return{
                    imageInks: state.imageInks.filter(shape => shape.id !== state.currentActiveShape),
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    currentActiveShape: null,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }else{
                const shapeHistoryDetail = {
                    markId: state.currentActiveShape,
                    type: HistoryType.shape,
                    before: state.shapes.find(shape => shape.id === state.currentActiveShape),
                    after: null
                }
                const newHistory = [...state.history, {
                    id: uuid(),
                    pageId: state.currPageID,
                    details: [shapeHistoryDetail],
                    completed: true
                }]
                newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
                return{
                    shapes: state.shapes.filter(shape => shape.id !== state.currentActiveShape),
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    currentActiveShape: null,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }
    }),
    
    createTextBox: (pageID, markId, {x, y, p}, userId) => set(state => {
        // const currentPageData = state.pageData
        const shapeId = markId
        // state.currentActiveShape = shapeId
        const {color, textboxStyle} = useToolbarSettingsStore.getState()
        const startPoint = {x: mathRound(x), y: mathRound(y), pressure: 1}
        const endPoint = {x: startPoint.x + TextboxProperty.defaultWidth , y: startPoint.y + TextboxProperty.defaultheight, pressure: 1}
        useToolbarSettingsStore.getState().updateCurrentActiveTool(ToolBarTool.cursor)
        return{
            shapes: [...state.shapes, {
                id: shapeId,
                penColor: color.color,
                fill: color.color,
                opacity: 1,
                type: ToolBarTool.textbox,
                textboxStyle,
                startPoint,
                endPoint,
                userId,
                text:'',
                transform: "translate(0, 0) rotate(0)",
                fontSizeRem: minFontSizeRem,
                svgAttributesPair: getSVGRectAttributesPair(startPoint, endPoint)
            }],
            currentActiveShape: shapeId,
        }
    }),
    opponentCreateTextBox: (pageID, markId, {x, y, p}, userId, penColor) => set(state => {
        // const currentPageData = state.pageData
        const shapeId = markId
        // state.currentActiveShape = shapeId
        const {color, textboxStyle} = useToolbarSettingsStore.getState()
        const startPoint = {x: mathRound(x), y: mathRound(y), pressure: 1}
        const endPoint = {x: startPoint.x + TextboxProperty.defaultWidth , y: startPoint.y + TextboxProperty.defaultheight, pressure: 1}
        return{
            shapes: [...state.shapes, {
                id: shapeId,
                penColor,
                fill: color.color,
                opacity: 1,
                type: ToolBarTool.textbox,
                textboxStyle,
                startPoint,
                endPoint,
                userId,
                text:'',
                transform: "translate(0, 0) rotate(0)",
                fontSizeRem: minFontSizeRem,
                svgAttributesPair: getSVGRectAttributesPair(startPoint, endPoint)
            }],
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    updateTextBoxHeightAndTransform: (shapeId, height, transform) => set(state => {
        return{
            shapes: state.shapes.map(shape => {
                if(shape.id === shapeId){
                    return {
                        ...shape,
                        transform,
                        svgAttributesPair: {
                            ...shape.svgAttributesPair,
                            height,
                        }
                    }
                }
                return shape
            }),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),

    

    updateTextBoxText: (shapeId, prev, curr) => set(state => {
        const oldText = prev.text
        const newText = curr.text
        if(oldText === newText && (!newText || newText === '')){
            return{
                shapes: state.shapes
                    .filter(shape => shape.id !== shapeId),
                currentActiveShape: null
            }
        }

        if(oldText === newText) return 

        const textbox = state.shapes.find(shape => shape.id === shapeId)
        const before = (oldText === '' || !oldText) ? null :{...textbox, text: oldText, transform: prev.transform, svgAttributesPair: prev.svgAttributesPair}
        const newHistory = [...state.history, {
            id: uuid(),
            pageId: state.currPageID,
            details:[
                {
                    markId: shapeId,
                    type: HistoryType.shape,
                    shapeStyle: {name: ShapeType.textbox},
                    before,
                    after: {...textbox, text: newText, transform: curr.transform, svgAttributesPair: curr.svgAttributesPair},
                }
            ],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        return{
            shapes: state.shapes
                .filter(shape => {
                    if(shape.id === shapeId && (!newText || newText === '')){
                        return false
                    }
                    return true
                })
                .map(shape => {
                if(shape.id === shapeId){
                    return {...shape, text: newText}
                }
                return shape
            }),
            history: newHistory,
            hasHistory: newHistory.length > 0,
            currentActiveShape: (state.currentActiveShape === shapeId && (!newText || newText === '')) ? null : state.currentActiveShape,
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    opponentUpdateTextBoxText: (shapeId, prev, curr) => set(state => {
        const oldText = prev.text
        const newText = curr.text
        if(oldText === newText && (!newText || newText === '')){
            return{
                shapes: state.shapes
                    .filter(shape => shape.id !== shapeId)
            }
        }

        if(oldText === newText) return 
        return{
            shapes: state.shapes
                .filter(shape => {
                    if(shape.id === shapeId && (!newText || newText === '')){
                        return false
                    }
                    return true
                })
                .map(shape => {
                if(shape.id === shapeId){
                    return {...shape, text: newText}
                }
                return shape
            }),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    
    updatePointerState: (pointerState) => set(state => {
        return{
            pointerState
        }
    }),
    beginDrawLine: (pageID, point, penSize, color, shapeStyle, currentMarkId, userId) => set(state => {
        return{
            currentMark: {
                id: currentMarkId,
                penColor: color.color,
                fill: color.color,
                opacity: 1,
                type: ToolBarTool.shape,
                shapeStyle,
                startPoint: point,
                endPoint: point,
                strokeWidth: penSize,
                svgAttributesPair: getSVGLineAttributesPair(point, point),
                userId
            },
            pointerState: PointerState.down,
            history: [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details:[
                    {
                        markId: currentMarkId,
                        type: HistoryType.shape,
                        shapeStyle,
                        before: null,
                        after: null,
                    }
                ]
            }],
        }

    }),
    opponentBeginDrawLine: ({id, point, penColor, penSize, shapeStyle, userId}) => set(state => {
        return{
            opponentMark: {
                id,
                penColor,
                fill: penColor,
                opacity: 1,
                type: ToolBarTool.shape,
                shapeStyle,
                startPoint: point,
                endPoint: point,
                strokeWidth: penSize,
                svgAttributesPair: getSVGLineAttributesPair(point, point),
                userId
            }
        }
    }),
    onDrawLine: (pageID, point) => set(state => {
        return{
            currentMark: {
                ...state.currentMark,
                endPoint: point, 
                svgAttributesPair: getSVGLineAttributesPair(state.currentMark.startPoint, point)
            }
        }
    }),
    opponentOnDrawLine: (point) => set(state => {

        try{
            if(!state.opponentMark) return
            return{
                opponentMark: {
                    ...state.opponentMark,
                    endPoint: point, 
                    svgAttributesPair: getSVGLineAttributesPair(state.opponentMark.startPoint, point)
                }
            }
        }catch(e){
            return
        }

    }),
    beginDrawRect: (pageID, point, penSize, color, shapeStyle, currentMarkId, userId) => set(state => {
        return{
            currentMark: {
                id: currentMarkId,
                penColor: color.color,
                fill: 'transparent',
                opacity: 1,
                type: ToolBarTool.shape,
                shapeStyle,
                startPoint: point,
                endPoint: point,
                strokeWidth: penSize,
                svgAttributesPair: getSVGRectAttributesPair(point, point),
                transform: "translate(0, 0) rotate(0)",
                userId
            },
            pointerState: PointerState.down,
            history: [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details:[
                    {
                        markId: currentMarkId,
                        type: HistoryType.shape,
                        shapeStyle,
                        before: null,
                        after: null,
                    }
                ]
            }],
        }


    }),
    opponentBeginDrawRect: ({id, point, penColor, penSize, shapeStyle, userId}) => set(state => {
        return{
            opponentMark: {
                id,
                penColor,
                fill: 'transparent',
                opacity: 1,
                type: ToolBarTool.shape,
                shapeStyle,
                startPoint: point,
                endPoint: point,
                strokeWidth: penSize,
                svgAttributesPair: getSVGRectAttributesPair(point, point),
                transform: "translate(0, 0) rotate(0)",
                userId
            }
        }
    }),
    onDrawRect: (pageID, point) => set(state => {
        return{
            currentMark: {
                ...state.currentMark,
                endPoint: point, 
                svgAttributesPair: getSVGRectAttributesPair(state.currentMark.startPoint, point)
            }
        }
    }),
    opponentOnDrawRect: (point) => set(state => {
        try{
            if(!state.opponentMark) return
            return{
                opponentMark: {
                    ...state.opponentMark,
                    endPoint: point, 
                    svgAttributesPair: getSVGRectAttributesPair(state.opponentMark.startPoint, point)
                }
            }
        }catch(e){
            return
        }
    }),
    beginDrawEllipse: (pageID, point, penSize, color, shapeStyle, currentMarkId, userId) => set(state => {
        return{
            currentMark: {
                id: currentMarkId,
                penColor: color.color,
                fill: 'transparent',
                opacity: 1,
                type: ToolBarTool.shape,
                shapeStyle,
                startPoint: point,
                endPoint: point,
                strokeWidth: penSize,
                svgAttributesPair: getSVGEllipseAttributesPair(point, point),
                transform: "translate(0, 0) rotate(0)",
                userId
            },
            pointerState: PointerState.down,
            history: [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details:[
                    {
                        markId: currentMarkId,
                        type: HistoryType.shape,
                        shapeStyle,
                        before: null,
                        after: null,
                    }
                ]
            }]
        }



    }),
    opponentBeginDrawEllipse: ({id, point, penSize, penColor, shapeStyle, userId}) => set(state => {
        return{
            opponentMark: {
                id,
                penColor,
                fill: 'transparent',
                opacity: 1,
                type: ToolBarTool.shape,
                shapeStyle,
                startPoint: point,
                endPoint: point,
                strokeWidth: penSize,
                svgAttributesPair: getSVGEllipseAttributesPair(point, point),
                transform: "translate(0, 0) rotate(0)",
                userId
            }
        }
    }),
    onDrawEllipse: (pageID, point) => set(state => {
        return{
            currentMark: {
                ...state.currentMark,
                endPoint: point, 
                svgAttributesPair: getSVGEllipseAttributesPair(state.currentMark.startPoint, point)
            }
        }
    }),
    opponentOnDrawEllipse: (point) => set(state => {
        try{
            if(!state.opponentMark) return
            return{
                opponentMark: {
                    ...state.opponentMark,
                    endPoint: point, 
                    svgAttributesPair: getSVGEllipseAttributesPair(state.opponentMark.startPoint, point)
                }
            }
        }catch(e){
            return
        }
        
    }),
    beginMark: (pageID, point, penSize, color, penStyle, currentMarkId, userId) => set(state => {
        
        const circlePath = getCirclePath(point.x, point.y, penSize/2)

        current = {x: point.x, y: point.y}
        old = current
        oldMid = current

        return {
            currentMark: {
                id: currentMarkId, 
                points: [point], 
                paths:[circlePath], 
                penColor: color.color,
                fill: color.color,
                opacity: penStyle.opacity,
                type: ToolBarTool.pen,
                penStyle,
                strokeWidth: penSize,   //get strokePath 得到的svg path,圍繞一圈本身有width
                userId
            },
            history: [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details:[
                    {
                        markId: currentMarkId,
                        type: HistoryType.mark,
                        before: null,
                        after: null,
                    }
                ]
            }],
            pointerState: PointerState.down
        }

    }),
    opponentBeginMark: ({id, points, penColor, penStyle, penSize, type, userId}) => set(state => {
        // const point = points[0]
        // opponentCurrentMarkPoints.push(point)
        // opponentCurrentMarkTotalPoints.push(point)
        // opponentCurrentMarkPath = getStrokePath(opponentCurrentMarkPoints, penSize)
        // opponentAccCurrentMarkPath = `M ${point.x} ${point.y} `
        // opponentCurrentMarkPoints.push([points[0].x, points[0].y])
        // opponentCurrentMarkTotalPoints.push(new window.fabric.Point(points[0].x, points[0].y))
        // opponentCurrentMarkPath = getCirclePath(opponentCurrentMarkPoints[0][0], opponentCurrentMarkPoints[0][1], penSize/2)

        // opponentAccCurrentMarkPath = `M ${points[0].x} ${points[0].y} `


        const circlePath = getCirclePath(points[0].x, points[0].y, penSize/2)
        opponentCurrent = {x: points[0].x, y: points[0].y}
        opponentOld = opponentCurrent
        opponentOldMid = opponentCurrent


        return {
            opponentMark: {
                id,
                points,
                paths:[circlePath], 
                penColor,
                fill: penColor,
                opacity: penStyle.opacity,
                type,
                penStyle,
                strokeWidth: penSize,
                userId
            }
        }
    }),
    addPointToCurrentMark: (pageID, point, penSize) => set(state => {
        if(state.pointerState === PointerState.down){
            current = {x: point.x, y:point.y}

            let paths = state.currentMark.paths
            let currentMid = {x: (old.x + current.x)/ 2, y: (old.y + current.y)/ 2}
            let newPath = `M ${currentMid.x} ${currentMid.y} Q ${old.x} ${old.y} ${oldMid.x} ${oldMid.y}`
            paths = [...paths, newPath]

            old = current
            oldMid = currentMid

            return {
                currentMark: {
                    ...state.currentMark,
                    strokeWidth: penSize,
                    paths
                }
            }
        }
    }),
    opponentAddPointToCurrentMark: ({point, penSize}) => set(state => {

        // opponentCurrentMarkPoints.push(point)
        // opponentCurrentMarkTotalPoints.push(point)
        // if(opponentCurrentMarkPoints.length >= 2 && currentMarkPoints.length<150){
        //     opponentCurrentMarkPath = `${opponentAccCurrentMarkPath} ${getStrokePath(opponentCurrentMarkPoints, penSize, true)}`
        // }else{
        //     opponentCurrentMarkPath = `${opponentAccCurrentMarkPath} ${getStrokePath(opponentCurrentMarkPoints, penSize, false)}`
        //     opponentAccCurrentMarkPath = opponentCurrentMarkPath
            
        //     opponentCurrentMarkPoints = opponentCurrentMarkPoints.slice(opponentCurrentMarkPoints.length-5)
        // }

        // opponentCurrentMarkPath = `${opponentAccCurrentMarkPath} L ${point.x} ${point.y}`
        // opponentAccCurrentMarkPath = opponentCurrentMarkPath
        try{
            if(!state.opponentMark) return 
            // opponentCurrentMarkPoints.push([point.x, point.y])
            // opponentCurrentMarkTotalPoints.push(new window.fabric.Point(point.x, point.y))
                
            // let paths = state.opponentMark.paths
            // if(opponentCurrentMarkPoints.length > 20){
            //     opponentCurrentMarkPath = `M ${opponentCurrentMarkPoints[opponentCurrentMarkPoints.length-2][0]} ${opponentCurrentMarkPoints[opponentCurrentMarkPoints.length-2][1]}`
            //     opponentAccCurrentMarkPath = opponentCurrentMarkPath
            //     paths = [...paths, opponentCurrentMarkPath]
            //     opponentCurrentMarkPoints = opponentCurrentMarkPoints.slice(opponentCurrentMarkPoints.length -1)
            // }else{
            //     opponentCurrentMarkPath = `${opponentAccCurrentMarkPath} L ${point.x} ${point.y}`
            //     paths[paths.length -1] = opponentCurrentMarkPath
            //     opponentAccCurrentMarkPath = opponentCurrentMarkPath
            // }

            // if(!state.opponentMark) return 
            // if(state.opponentMark.pointsLength === 1){
            //     paths = [getCirclePath((opponentCurrentMarkPoints[0][0]+opponentCurrentMarkPoints[1][0])/2, (opponentCurrentMarkPoints[0][1]+opponentCurrentMarkPoints[1][1])/2, state.opponentMark.strokeWidth/2)]
                
            // }else if(state.opponentMark.pointsLength === 2){
            //     paths = [getCirclePath(opponentCurrentMarkPoints[1][0], opponentCurrentMarkPoints[1][1], state.opponentMark.strokeWidth/2)] 
            // }
            opponentCurrent = {x: point.x, y: point.y}
            let paths = state.opponentMark.paths
            let opponentCurrentMid = {x: (opponentOld.x + opponentCurrent.x)/ 2, y: (opponentOld.y + opponentCurrent.y)/ 2}
            let newPath = `M ${opponentCurrentMid.x} ${opponentCurrentMid.y} Q ${opponentOld.x} ${opponentOld.y} ${opponentOldMid.x} ${opponentOldMid.y}`
            paths = [...paths, newPath]

            opponentOld = opponentCurrent
            opponentOldMid = opponentCurrentMid


            return {
                opponentMark: {
                    ...state.opponentMark,
                    strokeWidth: penSize,
                    paths
                }
            }
        }catch(e){
            return
        }
    }),
    completeMark: (pageID) => set(state => {
        if(state.pointerState !== PointerState.down) return
        // let fixPath
        let fixPaths
        const isCirclePath = state.currentMark.paths.length === 1
        if(isCirclePath){
            fixPaths = state.currentMark.paths.slice(0, 1)
        }else{
            fixPaths = state.currentMark.paths.slice(1)
        }

        const createdAt = Date.now()
        const newHistory = state.history.map((h, index) => {
            if(index === state.history.length-1){
                return{
                    ...h,
                    details: h.details.map(d => {
                        if(d.markId === state.currentMark.id){
                            return {
                                ...d,
                                after: {paths: fixPaths, createdAt}
                            }
                        }
                        return d
                    }),
                    completed: true
                }
            }
            return h
        })
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
        const {xLow, xHigh, yLow, yHigh} = getPathRange(fixPaths.join())
        return {
            currentMark: null,
            marks: [...state.marks, {...state.currentMark, xLow, xHigh, yLow, yHigh, createdAt, isCirclePath, paths: fixPaths}],
            pointerState: PointerState.up,
            history: newHistory,
            hasHistory: newHistory.length > 0,
            // markAndShapesLastChanged: Date.now()
        }
    }),
    opponentCompleteMark: (pageID) => set(state => {
        try{
            if(!state.opponentMark) return 


            let fixPaths
            const isCirclePath = state.opponentMark.paths.length === 1
            if(isCirclePath){
                fixPaths = state.opponentMark.slice(0, 1)
            }else{
                fixPaths = state.opponentMark.paths.slice(1)
            }
        
        

            const createdAt = Date.now()
            const {xLow, xHigh, yLow, yHigh} = getPathRange(fixPaths.join())


            return {
                opponentMark: null,
                marks: [...state.marks, {...state.opponentMark, xLow, xHigh, yLow, yHigh, createdAt, isCirclePath, paths: fixPaths}],
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }catch(e){
            return
        }
        

    }),
    createImage: (id, imageId, startPoint, endPoint, createdAt, userId, isimageink, imageRatio, imageInkData) => set(state => {
        useToolbarSettingsStore.getState().updateCurrentActiveTool(ToolBarTool.cursor)
        const svgAttributesPair = getSVGRectAttributesPair(startPoint, endPoint)
    
        console.log(imageInkData)
        if(isimageink){
            const newHistory = [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details:[
                    {
                        markId: id,
                        type: HistoryType.imageInk,
                        shapeStyle: {name: ShapeType.imageInk},
                        before: null,
                        after: null,
                    }
                ],
                completed: true
            }]
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
            return {
                imageInks:[...state.imageInks, {
                    id,
                    type: ToolBarTool.shape,
                    shapeStyle: {name: ShapeType.imageInk},
                    startPoint,
                    endPoint,
                    svgAttributesPair,
                    imageId,
                    createdAt,
                    userId,
                    strokeWidth: 0,
                    transform: "translate(0, 0) rotate(0)",
                    imageRatio,
                    imageInkData
                }],
                currentActiveShape: id,
                currentActiveShapeIsImageInk: isimageink,
                history: newHistory,
                hasHistory: newHistory.length > 0
            }

        }else{
            const newHistory = [...state.history, {
                id: uuid(),
                pageId: state.currPageID,
                details:[
                    {
                        markId: id,
                        type: HistoryType.shape,
                        shapeStyle: {name: ShapeType.image},
                        before: null,
                        after: null,
                    }
                ],
                completed: true
            }]
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
            return {
                shapes:[...state.shapes, {
                    id,
                    type: ToolBarTool.shape,
                    shapeStyle: {name: ShapeType.image},
                    startPoint,
                    endPoint,
                    svgAttributesPair,
                    imageId,
                    createdAt,
                    userId,
                    strokeWidth: 0,
                    transform: "translate(0, 0) rotate(0)"
                }],
                currentActiveShape: id,
                currentActiveShapeIsImageInk: false,
                history: newHistory,
                hasHistory: newHistory.length > 0
            }
        }
    }),
    opponentCreateImage: (id, imageId, startPoint, endPoint, createdAt, userId, isimageink, imageRatio) => set(state => {
        const svgAttributesPair = getSVGRectAttributesPair(startPoint, endPoint)
        if(isimageink){
            return {
                imageInks:[...state.imageInks, {
                    id,
                    type: ToolBarTool.shape,
                    shapeStyle: {name: ShapeType.imageInk},
                    startPoint,
                    endPoint,
                    svgAttributesPair,
                    imageId,
                    createdAt,
                    userId,
                    imageRatio
                }]
            }

        }else{
            return {
                shapes:[...state.shapes, {
                    id,
                    type: ToolBarTool.shape,
                    shapeStyle: {name: ShapeType.image},
                    startPoint,
                    endPoint,
                    svgAttributesPair,
                    imageId,
                    createdAt,
                    userId
                }]
            }
        }
    }),
    completeShape: (pageID) => set(state => {
        if(state.pointerState !== PointerState.down) return
        // useToolbarSettingsStore.getState().updateCurrentActiveTool(ToolBarTool.cursor)
        const createdAt = Date.now()
        const newHistory = state.history.map((h, index) => {
            if(index === state.history.length-1){
                return{
                    ...h,
                    details: h.details.map(d => {
                        if(d.markId === state.currentMark.id){
                            return {
                                ...d,
                                after: {svgAttributesPair: state.currentMark.svgAttributesPair, transform: state.currentMark.transform, createdAt}
                            }
                        }
                        return d
                    }),
                    completed: true
                }
            }
            return h
        })
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        if(useLocalStorageStore.getState().disableContinuousShapeDrawing){
            useToolbarSettingsStore.getState().updateCurrentActiveTool(ToolBarTool.cursor)
        }

        return {
            currentMark: null,
            shapes: [...state.shapes, {...state.currentMark, createdAt}],
            pointerState: PointerState.up,
            currentActiveShape: useLocalStorageStore.getState().disableContinuousShapeDrawing ? state.currentMark.id : null,
            history: newHistory,
            hasHistory: newHistory.length > 0
            
        }

    }),
    opponentCompleteShape: () => set(state => {

        try{
            if(!state.opponentMark) return
            const createdAt = Date.now()
            return {
                opponentMark: null,
                shapes: [...state.shapes, {...state.opponentMark, createdAt}],
                markAndShapesLastChanged: Date.now()
            }
        }catch(e){
            return
        }

    }),
    dragShape: (shapeId, {cx, cy}, isimageink) => set(state => {
        try{
            const idx = state.modifyList.filter(shape => shape != null).findIndex(shape => shape.id === shapeId)
            if(idx === -1){
                const shape = isimageink ? state.imageInks.find(s => s.id === shapeId) : state.shapes.find(s => s.id === shapeId)
                return{
                    modifyList: [
                        ...state.modifyList, {
                            id: shapeId,
                            action: modifyPathAction.drag,
                            list:[{cx, cy}]
                        }
                    ],
                    history: [...state.history, {
                        id: uuid(),
                        pageId: state.currPageID,
                        details:[
                            {
                                markId: shapeId,
                                type: isimageink ? HistoryType.imageInk : HistoryType.shape,
                                before: {text: shape.text, svgAttributesPair: shape.svgAttributesPair, transform: shape.transform},
                                after: null,
                            }
                        ]
                    }],
                }
            }
            if(state.modifyList[idx]?.action === modifyPathAction.drag){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null).map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                list: [...shape.list, {cx, cy}]
                            }
                        }
                        return shape
                    })
                }
            }
        }catch(e){
            return 
        }
        
    }),
    dragEnd: (shapeId, svgAttributesPair, transform, isimageink) => set(state => {
        try{
            const newHistory = state.history.map((h, index) => {
                if(index === state.history.length-1){
                    return{
                        ...h,
                        details: h.details.map(d => {
                            if(d.markId === shapeId){
                                return {
                                    ...d,
                                    after: {text: d.before?.text, svgAttributesPair, transform}
                                }
                            }
                            return d
                        }),
                        completed: true
                    }
                }
                return h
            })
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

            if(isimageink){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    imageInks: state.imageInks.map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                svgAttributesPair,
                                transform
                            }
                        }
                        return shape
                    }),	
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }

            }else{
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    shapes: state.shapes.map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                svgAttributesPair,
                                transform
                            }
                        }
                        return shape
                    }),	
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }

            }

            
        }catch(e){
            return 
        }
    }),
    opponentDragShape: (shapeId, {cx, cy}) => set(state => {
        if(!shapeId) return
        try{
            const idx = state.modifyList.filter(shape => shape != null).findIndex(shape => shape.id === shapeId)
            if(idx === -1){
                return{
                    modifyList: [
                        ...state.modifyList, {
                            id: shapeId,
                            action: modifyPathAction.drag,
                            list:[{cx, cy}]
                        }
                    ]
                }
            }
            if(state.modifyList[idx]?.action === modifyPathAction.drag){
                return {
                    modifyList: state.modifyList.filter(shape=> shape != null).map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                list: [...shape.list, {cx, cy}]
                            }
                        }
                        return shape
                    })
                }
            }
        }catch(e){
            return 
        }
        
    }),
    opponentDragEnd: (shapeId, svgAttributesPair, transform, isimageink) => set(state => {
        if(!shapeId) return
        try{
            if(isimageink){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    imageInks: state.imageInks.map(imageInk => {
                        if(imageInk.id === shapeId){
                            return {
                                ...imageInk,
                                svgAttributesPair,
                                transform
                            }
                        }
                        return imageInk
                    }),
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }

            }else{
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    shapes: state.shapes.map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                svgAttributesPair,
                                transform
                            }
                        }
                        return shape
                    }),
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }catch(e){
            return
        }
    }),
    onResize:(shapeId, {cx, cy, left, top, rx, ry, x1, y1, x2, y2, transform, controlElementClassName, fontSize, fontSizeRem}, isimageink) => set(state => {
        const idx = state.modifyList.filter(shape => shape != null).findIndex(shape => shape.id === shapeId)
        try{
            if(idx === -1){
                const shape = isimageink ? state.imageInks.find(s => s.id === shapeId) : state.shapes.find(s => s.id === shapeId)
                return{
                    modifyList: [
                        ...state.modifyList, {
                            id: shapeId,
                            action: modifyPathAction.resize,
                            list: [{left,top, rx,ry, cx,cy, x1, y1, x2, y2, transform, controlElementClassName, fontSize, fontSizeRem}]
                        }
                    ],
                    history: [...state.history, {
                        id: uuid(),
                        pageId: state.currPageID,
                        details:[
                            {
                                markId: shapeId,
                                type: isimageink ? HistoryType.imageInk : HistoryType.shape,
                                before: {text: shape.text, svgAttributesPair: shape.svgAttributesPair, transform: shape.transform, zoomFactor: shape.imageInkData?.zoomFactor, fontSizeRem: shape.fontSizeRem},
                                after: null,
                            }
                        ]
                    }]
                }
            }
            if(state.modifyList[idx]?.action === modifyPathAction.resize){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null).map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                list: [...shape.list, {left,top, rx,ry, cx,cy, x1, y1, x2, y2, transform,controlElementClassName, fontSize, fontSizeRem}]
                            }
                        }
                        return shape
                    })
                }
            }
        }catch(e){
            return 
        }

    }),
    resizeEnd:(shapeId, svgAttributesPair, transform, isimageink, fontSizeRem) => set(state => {
        console.log(fontSizeRem)
        try{
            const newHistory = state.history.map((h, index) => {
                if(index === state.history.length-1){
                    return{
                        ...h,
                        details: h.details.map(d => {
                            if(d.markId === shapeId){
                                return {
                                    ...d,
                                    after: {svgAttributesPair, transform, fontSizeRem}
                                }
                            }
                            return d
                        }),
                        completed: true
                    }
                }
                return h
            })
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)
        
            if(isimageink){
                
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    imageInks: state.imageInks.map(shape => {
                        if(shape.id === shapeId){
                            const imageInkData = shape.imageInkData
                            imageInkData.zoomFactor = svgAttributesPair.width/imageInkData.Width
                            return {
                                ...shape,
                                svgAttributesPair,
                                transform,
                                imageInkData
                            }
                        }
                        return shape
                    }),	
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }


            }else{
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    shapes: state.shapes.map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                svgAttributesPair,
                                transform,
                                fontSizeRem
                            }
                        }
                        return shape
                    }),	
                    history: newHistory,
                    hasHistory: newHistory.length > 0,
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
            
        }catch(e){
            return
        }
    }),
    opponentOnResize:(shapeId, {cx, cy, left, top, rx, ry, x1, y1, x2, y2, transform, fontSizeRem}) => set(state => {
        const idx = state.modifyList.filter(shape => shape != null).findIndex(shape => shape.id === shapeId)
        try{
            if(idx === -1){
                return{
                    modifyList: [
                        ...state.modifyList, {
                            id: shapeId,
                            action: modifyPathAction.resize,
                            list: [{left,top, rx,ry, cx,cy, x1, y1, x2, y2, transform, fontSizeRem}]
                        }
                    ]
                }
            }
            if(state.modifyList[idx]?.action === modifyPathAction.resize){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null).map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                list: [...shape.list, {left,top, rx,ry, cx,cy, x1, y1, x2, y2, transform, fontSizeRem}]
                            }
                        }
                        return shape
                    })
                }
            }
        }catch(e){
            return 
        }

    }),
    opponentOnResizeEnd:(shapeId, svgAttributesPair, transform, isImageInk, fontSizeRem) => set(state => {
        try{
            if(isImageInk){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    imageInks: state.imageInks.map(imageInk => {
                        if(imageInk.id === shapeId){
                            return {
                                ...imageInk,
                                svgAttributesPair,
                                transform
                            }
                        }
                        return imageInk
                    }),
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }

            }else{
                return {
                    modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                    shapes: state.shapes.map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                svgAttributesPair,
                                transform,
                                fontSizeRem
                            }
                        }
                        return shape
                    }),
                    markAndShapesLastChanged: Date.now(),
                    isSwitchPageAction: false
                }
            }
        }catch(e){
            return
        }
    }),
    onRotate:(shapeId, {transform}) => set(state => {
        const idx = state.modifyList.filter(shape => shape != null).findIndex(shape => shape.id === shapeId)
        try{
            if(idx === -1){
                const shape = state.shapes.find(s => s.id === shapeId)
                return{
                    modifyList: [
                        ...state.modifyList, {
                            id: shapeId,
                            action: modifyPathAction.rotate,
                            list: [{transform}]
                        }
                    ],
                    history: [...state.history, {
                        id: uuid(),
                        pageId: state.currPageID,
                        details:[
                            {
                                markId: shapeId,
                                type: HistoryType.shape,
                                before: {text:shape.text, svgAttributesPair: shape.svgAttributesPair, transform: shape.transform},
                                after: null,
                            }
                        ]
                    }]
                }
            }
            if(state.modifyList[idx]?.action === modifyPathAction.rotate){
                return {
                    modifyList: state.modifyList.map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                list: [...shape.list, {transform}]
                            }
                        }
                        return shape
                    })
                }
            }
        }catch(e){
            return
        }
    }),
    rotateEnd:(shapeId, svgAttributesPair, transform) => set(state => {
        try{
            const newHistory = state.history.map((h, index) => {
                if(index === state.history.length-1){
                    return{
                        ...h,
                        details: h.details.map(d => {
                            if(d.markId === shapeId){
                                return {
                                    ...d,
                                    after: {svgAttributesPair, transform}
                                }
                            }
                            return d
                        }),
                        completed: true
                    }
                }
                return h
            })
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

            return {
                modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                shapes: state.shapes.map(shape => {
                    if(shape.id === shapeId){
                        return {
                            ...shape,
                            svgAttributesPair,
                            transform
                        }
                    }
                    return shape
                }),	
                history: newHistory,
                hasHistory: newHistory.length > 0,
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }catch(e){
            return 
        }
    }),
    opponentOnRotate:(shapeId, {transform}) => set(state => {
        const idx = state.modifyList.filter(shape => shape != null).findIndex(shape => shape.id === shapeId)
        try{
            if(idx === -1){
                return{
                    modifyList: [
                        ...state.modifyList, {
                            id: shapeId,
                            action: modifyPathAction.rotate,
                            list: [{transform}]
                        }
                    ]
                }
            }
            if(state.modifyList[idx]?.action === modifyPathAction.rotate){
                return {
                    modifyList: state.modifyList.filter(shape => shape != null).map(shape => {
                        if(shape.id === shapeId){
                            return {
                                ...shape,
                                list: [...shape.list, {transform}]
                            }
                        }
                        return shape
                    })
                }
            }
        }catch(e){
            return 
        }
    }),
    opponentRotateEnd:(shapeId, svgAttributesPair, transform) => set(state => {
        try{
            return {
                modifyList: state.modifyList.filter(shape => shape != null && shape.id !== shapeId),
                shapes: state.shapes.map(shape => {
                    if(shape.id === shapeId){
                        return {
                            ...shape,
                            svgAttributesPair,
                            transform
                        }
                    }
                    return shape
                }),
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }catch(e){
            return 
        }
    }),
    opponentRemoveMarks: (markIds) => set(state => {
        return{
            marks: state.marks.filter(mark => !markIds.includes(mark.id)),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    opponentRemoveShapes: (shapeIds, isImageInk) => set(state => {
        if(isImageInk){
            return{
                imageInks: state.imageInks.filter(imageInk => !shapeIds.includes(imageInk.id)),
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }

        }else{
            return{
                shapes: state.shapes.filter(shape => !shapeIds.includes(shape.id)),
                markAndShapesLastChanged: Date.now(),
                isSwitchPageAction: false
            }
        }
    }),

    transferImageInkToInk: (userId, imageInkId) => set(state => {
        const idx = state.imageInks.findIndex(imageInk => imageInk.id === imageInkId)
        if(idx === -1)
            return
        const imageInk = state.imageInks[idx]
        let  {StrokeList, zoomFactor, offsetX, offsetY, Color, StrokeWidth} = imageInk.imageInkData
        const {x, y} = imageInk.svgAttributesPair
        const newMarks = []

        const newHistory = [...state.history, {
            id: uuid(),
            pageId: state.currPageID,
            details:[
                {
                    type: HistoryType.imageInkToInk,
                    before: imageInk,
                    after: newMarks,
                }
            ],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        
        for(var key in StrokeList){
            let points = StrokeList[key].Points

            // points  = points.map(point => {
            //             return {
            //                 x: (point.X-offsetX)*zoomFactor + x , y: (point.Y-offsetY)*zoomFactor + y, pressure: 0.5
            //             }
            //         })
            points = points.map(point => {
                return new window.fabric.Point((point.X-offsetX)*zoomFactor/viewBoxZoomFactor + x, (point.Y-offsetY)*zoomFactor/viewBoxZoomFactor + y)
            })

            const correction = penSizeImageInkWidthMapper(StrokeWidth)*zoomFactor / 1000
            let path = window.fabric.util.joinPath(window.fabric.util.getSmoothPathFromPoints(points, correction))

            // let path = getStrokePath(points, penSizeImageInkWidthMapper(StrokeWidth)*zoomFactor, false)
            const {xLow, xHigh, yLow, yHigh} = getPathRange(path)
            const mark ={
                id: uuid(), 
                points, 
                paths:[path],
                pointsLength: points.length,
                penColor: Color,
                fill: Color,
                opacity: "1",
                type: ToolBarTool.pen,
                penStyle: {
                    name:"pen", 
                    img:"images/ic_menu_pen_n@2x.png",
                    imgHover:"images/ic_menu_pen_a&d@2x.png",
                    opacity:"1"
                },
                userId: userId,
                strokeWidth: penSizeImageInkWidthMapper(StrokeWidth)*zoomFactor,   //get strokePath 得到的svg path,圍繞一圈本身有width
                createdAt: new Date().getTime(),
                isTransferFromImageInk: true,
                xLow,
                xHigh,
                yLow,
                yHigh
            }
            newMarks.push(mark)
        }
        return{
            marks:[...state.marks, ...newMarks],
            imageInks: [...state.imageInks.slice(0, idx), ...state.imageInks.slice(idx+1, state.imageInks.length)],
            history: newHistory,
            hasHistory: newHistory.length > 0
        }

    }),
    opponentChangeImageInkColor: (imageInkId, Color, imageId) => set(state => {
        return{
            imageInks: state.imageInks.map(imageInk => {
                if(imageInk.id === imageInkId){
                    return{
                        ...imageInk,
                        imageId,
                        imageInkData: {
                            ...imageInk.imageInkData,
                            Color
                        }
                    }
                }
                return imageInk
            }),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false

        }
    }),
    opponentChangeImageInkStrokeWidth: (imageInkId, StrokeWidth, imageId) => set(state => {
        return{
            imageInks: state.imageInks.map(imageInk => {
                if(imageInk.id === imageInkId){
                    return{
                        ...imageInk,
                        imageId,
                        imageInkData: {
                            ...imageInk.imageInkData,
                            StrokeWidth
                        }
                    }
                }
                return imageInk
            }),
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false

        }
    }),

    opponentTransferImageInkToInk: (addMarks, needToRemoveImageInkIds) => set(state => {

        return{
            imageInks: state.imageInks.filter(imageInk => !needToRemoveImageInkIds.includes(imageInk.id)),
            marks: [...state.marks, ...addMarks],
            markAndShapesLastChanged: Date.now(),
            isSwitchPageAction: false
        }
    }),
    insertPage: ({pageIdx, pageID, width, height}) => set(state => {
        const pageDetails = [...state.pageDetails.slice(0, pageIdx), 
            {
               id: pageID,
               thumbnail: whiteImage,
               width,
               height,
               markCount: 0,
               shapeCount: 0,
               pageIdx
            }, 
        ...state.pageDetails.slice(pageIdx)]

        const pageChangedHistoryDetail = {
            type: HistoryType.pageChanged,
            before: state.pageDetails,
            // after: {
            //     pageDetails,
            //     currPageID: state.currPageID
            // },
        }
        const newHistory = [...state.history, {
            pageId: state.currPageID,
            details: [pageChangedHistoryDetail],
            completed: true
        }]
        newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

        return{
            pageDetails,
            totalPages: state.totalPages + 1,
            currentPageIdx: pageDetails.findIndex(pageDetail => pageDetail.id === state.currPageID),
            currentActiveShape: null,
            history: newHistory,
            hasHistory: true
        }
    }),
    toNextPage:() => set(state => {
        if(state.totalPages === state.currentPageIdx +1) return
        const newPageIdx = state.currentPageIdx+1
        return{
            currentPageIdx: newPageIdx,
            currPageID: state.pageDetails[newPageIdx].id,
            isSwitchPageAction: true,
            currentActiveShape: null,
        }
    }),
    toPrevPage:() => set(state => {
        if(state.currentPageIdx === 0) return
        const newPageIdx = state.currentPageIdx-1
        return{
            currentPageIdx: state.currentPageIdx-1,
            currPageID: state.pageDetails[newPageIdx].id,
            isSwitchPageAction: true,
            currentActiveShape: null,
        }
    }),
    toFirstPage:() => set(state => {
        if(state.currentPageIdx === 0) return
        const newPageIdx = 0
        return{
            currentPageIdx: newPageIdx,
            currPageID: state.pageDetails[newPageIdx].id,
            isSwitchPageAction: true,
            currentActiveShape: null,
        }
    }),
    toLastPage:() => set(state => {
        if(state.totalPages === state.currentPageIdx +1) return
        const newPageIdx = state.totalPages-1
        return{
            currentPageIdx: newPageIdx,
            currPageID: state.pageDetails[newPageIdx].id,
            isSwitchPageAction: true,
            currentActiveShape: null,
        }
    }),
    toCertainPage:(pageIdx) => set(state => {
        return{
            currentPageIdx: pageIdx,
            currPageID: state.pageDetails[pageIdx].id,
            isSwitchPageAction: true,
            currentActiveShape: null,
        }
    }),
    toCertainPageID: (pageID) => set(state => {
        return{
            currPageID: pageID,
            currentPageIdx: state.pageDetails.findIndex(pageDetail => pageDetail.id === pageID),
            isSwitchPageAction: true,
            currentActiveShape: null,
        }
    }),
    deletePageById: (pageID) => set(state => {
        if(state.totalPages <= 1) return
        const newPageDetails = state.pageDetails.filter(detail => detail.id !== pageID)
        if(newPageDetails[state.currentPageIdx]){
            const pageChangedHistoryDetail = {
                type: HistoryType.pageChanged,
                before: state.pageDetails,
                // after: {
                //     pageDetails: newPageDetails,
                //     currPageID: newPageDetails[state.currentPageIdx].id,
                // },
            }
            const newHistory = [...state.history, {
                id: uuid(),
                details: [pageChangedHistoryDetail],
                completed: true
            }]
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

            return{
                totalPages: newPageDetails.length,
                pageDetails: newPageDetails,
                currPageID: newPageDetails[state.currentPageIdx].id,
                currentPageIdx: state.currentPageIdx,
                isSwitchPageAction: true,
                currentActiveShape: null,
                history: newHistory,
                hasHistory: true
            } 
        }else{

            const pageChangedHistoryDetail = {
                type: HistoryType.pageChanged,
                before: state.pageDetails,
            }
            const newHistory = [...state.history, {
                id: uuid(),
                details: [pageChangedHistoryDetail],
                completed: true
            }]
            newHistory.splice(0, newHistory.length-MAX_UNDO_SIZE)

            return{
                totalPages: newPageDetails.length,
                pageDetails: newPageDetails,
                currPageID: newPageDetails[newPageDetails.length-1].id,
                currentPageIdx: newPageDetails.length-1,
                isSwitchPageAction: true,
                currentActiveShape: null,
                history: newHistory,
                hasHistory: true
            }
        }
    })
    
})

let toolbarSettingsStore = (set) => ({
    penStyle: PenStyles.find(p => p.name ==='pen'),
    eraserStyle: EraserStyles.find(e => e.name ==='largeEraser'),
    shapeStyle: ShapeStyles.find(s => s.name === ShapeType.line),
    color: PenColors.find(c => c.name === 'red'),
    size: PenSizeStyles.find(s => s.name === 'smallSize'),
    textboxStyle: TextBoxStyles.find(t => t.name === 'horizontalTextbox'),
    currentActiveTool: ToolBarTool.pen,
    
    updateCurrentActiveTool: (currentActiveTool) => set(state => {
        return{
            currentActiveTool
        }
    }),
    updatePenStyle: (penStyle) => set(state => {
        return{
            penStyle,
            currentActiveTool: ToolBarTool.pen
        }
    }),
    updateEraserStyle: (eraserStyle) => set(state => {
        return{
            eraserStyle,
            currentActiveTool: ToolBarTool.eraser
        }
    }),
    updateShapeStyle: (shapeStyle) => set(state => {
        return{
            shapeStyle,
            currentActiveTool: ToolBarTool.shape
        }
    }),
    updatePenSize: (size) => set(state => {
        return{
            size
        }
    }),
    updatePenColor: (color) => set(state => {
        return{
            color
        }
    }),
    clearMarks: (pageID, accountType) => set(() => {
        useRoomChatStore.getState().clearMarks(pageID, accountType)
    }),
    undo: (pageID) => set(() => {
        useRoomChatStore.getState().undo(pageID)
    })

})

let userConnectionStore = (set) => ({
    userId: null,
    roomId: null,
    socket: null,
    sendSocketData: null,
    socketMessage: null,
    peerConnection: null,
    dataChannel: null,
    accountType: null,
    hasHardware: false,
    token: null,
    connectionId: null,
    iceCandidateFinished: false,
    audio: null,
    video: null,
    disableContinuousShapeDrawing: null,
    setConnectionId: (connectionId) => set(state => {
        return {connectionId}
    }),
    setToken: (token) => set(state => {
        return {token}
    }),
    setIceCandidateFinished: (iceCandidateFinished) => set(state => {
        return {iceCandidateFinished}
    }),
    setUserIdAndRoomIdAndToken: (userId, roomId, token, accountType) => set(state => {
        return {
            userId,
            roomId,
            token,
            accountType
        }
    }),
    setAccountType: (accountType) => set(state => {
        return {accountType}
    }),
    setHasHardware: (hasHardware) => set(state => {
        return {hasHardware}
    }),
    updateSocketAndFunction: (socket, sendSocketData) => set(state => {
        return{
            socket,
            sendSocketData
        }
    }),
    setSocketMessage: (socketMessage) => set(state => {
        return {
            socketMessage
        }
    }),
    setPeerConnection: (peerConnection) => set(state => {
        return{
            peerConnection
        }
    }),
    setDataChannel: (dataChannel) => set(state => {
        return{
            dataChannel
        }
    }),
    setVideo: (video) => set(state => {
        return{
            video
        }
    
    }),

    setAudio: (audio) => set(state => {
        return{
            audio
        }
    })
})

let userSettingStore = (set) => ({
    deviceSetting: null,
    isThumbnailHide: false,
    setDeviceSetting: (deviceSetting) => set(state => {
        return {
            deviceSetting
        }
    }),
    toggleThumbnail: () => set(state => {
        return {
            isThumbnailHide: !state.isThumbnailHide 
        }
    }),
    openThumbnail: () => set(state => {
        return {
            isThumbnailHide: false 
        }
    })
})

let localStorageStore = (set) => ({
    imageInkToHintShowNextTime: true,
    disableContinuousShapeDrawing: false,
    setImageInkToHintShowNextTime: (imageInkToHintShowNextTime) => set(state => {
        return {imageInkToHintShowNextTime}
    }),
    setDisableContinuousShapeDrawing: (disableContinuousShapeDrawing) => set(state => {
        return {disableContinuousShapeDrawing}
    })
})


// roomChatStore = devtools(immer(roomChatStore))
// toolbarSettingsStore = devtools(immer(toolbarSettingsStore))
// userConnectionStore = devtools(userConnectionStore)

// roomChatStore = immer(roomChatStore)

roomChatStore = devtools(roomChatStore)
// toolbarSettingsStore = toolbarSettingsStore
// userConnectionStore = persist(devtools(userConnectionStore), {name: 'user_settings'})

// userSettingStore = persist(userSettingStore, {name: 'user_settings'})
// userConnectionStore = devtools(userConnectionStore)
// userSettingStore = persist((userSettingStore), {name: 'user_settings'})
// pageCacheStore = persist((pageCacheStore), {name: 'page_cache'})

localStorageStore = persist(localStorageStore, {name: 'localstorage_store'})
export const useRoomChatStore = create(roomChatStore)
export const useToolbarSettingsStore = create(toolbarSettingsStore)
export const useUserConnectionStore = create(userConnectionStore)
export const useUserSettingStore = create(userSettingStore)
export const useLocalStorageStore = create(localStorageStore)
// export const usePageCacheStore = create(pageCacheStore)