import getStroke from 'perfect-freehand'
import shallow from 'zustand/shallow'
import {AccountType, StrokeEvent, StrokeEventType, ToolBarTool, useRoomChatStore, useUserConnectionStore} from './store'
import {rotateDEG, applyToPoint} from 'transformation-matrix'
import SoundMeter from './library/soundmeters'
import svgToMiniDataURI from "mini-svg-data-uri"
import { v4 as uuid } from 'uuid'
import { apiAddDrawObject, apiUploadResource } from './api'
import {isFirefox} from 'react-device-detect'
import Compressor from 'compressorjs'
import pngChunkExtract from 'png-chunks-extract'
import 'fast-text-encoding'

export const getRandom = (min, max) => {
    return Math.floor(Math.random()*(max-min+1))+min
}


export const getImageBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error)
    })
}
export const toBoolean = (boolValue) => {
    if(boolValue && boolValue.toLowerCase() === 'true') return true
    else return false
}
export const isBetween = (number, bound1, bound2) => {
    if(number >= bound1 && number <= bound2) return true
    else return false
}
// export const imageInkPathRadius = 1050
// export const penSizeImageInkWidthMapper = (strokeWidth = 7) => {
//     if(strokeWidth === 1) return 30
//     else if(strokeWidth === 3) return 70
//     else if(strokeWidth === 7) return 110
//     else if(strokeWidth === 11) return 150
//     else if(strokeWidth === 15) return 190
// }
export const penSizeImageInkWidthMapper = (strokeWidth = 4) => {
    return 20 + 12*strokeWidth
}


export const imageInkPadding = 1000
export const viewBoxZoomFactor = 1.08
export const generateImageInkBase64 = (data) => {
    const imageInks = document.querySelector(".image-inks")
    const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    svgElement.setAttribute("viewBox", `0 0 ${data.Width} ${data.Height}`)
    const strokeList = data.StrokeList
    for(let key in strokeList){
        let points = strokeList[key].Points
        // points = points.map(point => {
        //             return {
        //                 x: point.X, y: point.Y, pressure: point.P
        //             }
        //         })
        points = points.map(point => {
            return new window.fabric.Point(point.X, point.Y)
        })
    
        // let path = getStrokePath(points, penSizeImageInkWidthMapper(data.StrokeWidth), false)
        const correction = penSizeImageInkWidthMapper(data.StrokeWidth) / 1000
        let path = window.fabric.util.joinPath(window.fabric.util.getSmoothPathFromPoints(points, correction))
        let pathDom = document.createElementNS("http://www.w3.org/2000/svg", "path")
        pathDom.setAttribute("d", path)
        pathDom.setAttribute('opacity', 1)
        pathDom.setAttribute("stroke-width", penSizeImageInkWidthMapper(data.StrokeWidth))
        pathDom.setAttribute("fill", 'none')
        pathDom.setAttribute("stroke", data.Color)
        pathDom.setAttribute('stroke-linejoin','round')
        pathDom.setAttribute('stroke-linecap', 'round')
        pathDom.setAttribute('shape-rendering', 'geometricPrecision')

        svgElement.append(pathDom)
    }
    imageInks.append(svgElement)
    let {x, y, width, height} = svgElement.getBBox()

    const offsetX = (width*viewBoxZoomFactor - width) /2
    const offsetY = (height*viewBoxZoomFactor - height) /2

    svgElement.setAttribute("viewBox", `${x-offsetX} ${y-offsetY} ${width*viewBoxZoomFactor} ${height*viewBoxZoomFactor}`)
    const serializedSVG = new XMLSerializer().serializeToString(svgElement)
    const url = `data:image/svg+xml;base64,${Buffer.from(serializedSVG, 'utf8').toString('base64')}`

    svgElement.remove()

    return {url, width, height, x: x-offsetX, y:y-offsetY, imageRatio: width/height}

}



const line = (pointA, pointB) => {
    const lengthX = pointB.x - pointA.x
    const lengthY = pointB.y - pointA.y
    return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
    }
}

export const controlPoint = (current, previous, next, reverse) => {
    const smoothing = 0.2
    const p = previous || current
    const n = next || current

    const o = line(p, n)
    const angle = o.angle + (reverse ? Math.PI : 0)
    const length = o.length * smoothing
    const x = current.x + Math.cos(angle) * length
    const y = current.y + Math.sin(angle) * length
    return [x, y]
}

export const getStrokePath = (points, size, last) => {
    const stroke = getStroke(points, {
        size: size,
        thinning: 0.00,
        smoothing: -1.00,
        streamline: 0.300,
        simulatePressure: false,
        last
    })
    const path = getSvgPathFromStroke(stroke)
    return path
}

export const mathRound = (n) => {
    return Math.round(n*100)/100
}

export const getPathRange = (path) => {
    const offset = 10
    const bbox = window.Snap.path.getBBox(path)
  
    const x = bbox.x - offset < 0 ? 0 :  bbox.x - offset
    const y = bbox.y - offset < 0 ? 0 :  bbox.y - offset
    const x2 = bbox.x2 + offset < 0 ? 0 :  bbox.x2 + offset
    const y2 = bbox.y2 + offset < 0 ? 0 :  bbox.y2 + offset
    const xLow = getHundredFloor(x)
    const xHigh = getHundredCeiling(x2)
    const yLow = getHundredFloor(y)
    const yHigh = getHundredCeiling(y2)
    return{xLow, xHigh, yLow, yHigh}
}
export const deleteCookie = (cookieName) =>{
    document.cookie=`${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;SameSite=lax`
}
export const getCirclePath = (x, y, r) => {
    return `M ${x} ${y} m ${-r}, 0 a ${r},${r} 0 1,0 ${r*2},0 a ${r},${r} 0 1,0 ${-r*2},0`
}

export const getLatestHistory = () => {
    const history = useRoomChatStore.getState().history[useRoomChatStore.getState().history.length-1]
    if(!history) return null
    if(history.details === null){
        history.details = []
    }
    return history
}

export const getSvgPathFromStroke = (stroke) => {
    if (!stroke.length) return ''

    stroke[0][0] = mathRound(stroke[0][0])
    stroke[0][1] = mathRound(stroke[0][1])
    const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
          
          const [x1, y1] = arr[i === arr.length - 1 ? 0 : i + 1]
          acc.push(mathRound(x0), mathRound(y0), 
          mathRound((x0 + x1) / 2), 
          mathRound((y0 + y1) / 2))
        
          return acc
        },
        ['M', ...stroke[0], 'Q']
      )

    d.push('Z')
    return d.join(' ')
}

export const moveToPath = (x, y) =>{
    return `M ${x} ${y}`
}
const horizontalMove = (distance) => {
    return `h ${distance}`
}
const verticalMove = (distance) => {
    return `v ${distance}`
}

export const getLinePath = (start, end) => {
    return `${moveToPath(start.x, start.y)} L ${end.x} ${end.y}`
}

export const getSVGLineAttributesPair = (start, end) => {
    if(start && end){
       return {
            x1: mathRound(start.x), 
            y1: mathRound(start.y), 
            x2: mathRound(end.x), 
            y2: mathRound(end.y)}
    }
    return null
}

export const getSVGRectAttributesPair = (start, end) => {
    if(start && end){
       const x = mathRound(Math.min(start.x, end.x))
       const y = mathRound(Math.min(start.y, end.y))
       const width = mathRound(Math.abs(start.x - end.x))
       const height = mathRound(Math.abs(start.y - end.y))
       return {
           x,  y, width, height
        }
    }
    return null
}

export const getSVGEllipseAttributesPair = (start, end) => {
    if(start && end){
        const cx = (start.x + end.x)/2
        const cy = (start.y + end.y)/2
        const rx = Math.abs(cx - start.x)
        const ry = Math.abs(cy - start.y)
        return {
            cx: mathRound(cx), 
            cy: mathRound(cy), 
            rx: mathRound(rx), 
            ry: mathRound(ry),
        }
    }
    return null
}

export const getRectPath = (start, end) => {
    const dx = end.x - start.x
    const dy = end.y - start.y

    return `${moveToPath(start.x, start.y)} ${horizontalMove(dx)} ${verticalMove(dy)} ${horizontalMove(-dx)} Z`
}

export function copyToClipboard(string) {
    let textarea
    let result

    try {
        textarea = document.createElement('textarea')
        textarea.setAttribute('position', 'fixed')
        textarea.setAttribute('top', '0')
        textarea.setAttribute('readonly', 'true')
        textarea.setAttribute('contenteditable', 'true')
        textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string

        document.body.appendChild(textarea)

        textarea.focus()
        textarea.select()

        const range = document.createRange()
        range.selectNodeContents(textarea)

        const sel = window.getSelection();
        if(sel != null){
            sel.removeAllRanges()
            sel.addRange(range)
        }


        textarea.setSelectionRange(0, textarea.value.length)
        result = document.execCommand('copy')
    } catch (err) {
        console.error(err)
        result = null
    } finally {
        document.body.removeChild(textarea)
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C'
        result = prompt(`Press ${copyHotkey}`, string) // eslint-disable-line no-alert
        if (!result) {
            return false
        }
    }
    return true
}

export const isSameCurrentMark = (oldCurrentMark, newCurrentMark) => {
    if(!oldCurrentMark && newCurrentMark) return false
    if(oldCurrentMark && !newCurrentMark) return false
    if(!oldCurrentMark && !newCurrentMark) return true

    if(oldCurrentMark.type === ToolBarTool.pen){
        if(oldCurrentMark.paths?.length === newCurrentMark.paths?.length) return true
    }

    
    if(oldCurrentMark.type === ToolBarTool.shape){
        if(shallow(oldCurrentMark.svgAttributesPair, newCurrentMark.svgAttributesPair) && 
            shallow(oldCurrentMark.transform, newCurrentMark.transform) && 
            shallow(oldCurrentMark.penColor, newCurrentMark.penColor)&&
            shallow(oldCurrentMark.imageId, newCurrentMark.imageId) && 
            shallow(oldCurrentMark.strokeWidth, newCurrentMark.strokeWidth)){
            return true
        }
    }
    if(oldCurrentMark.type === ToolBarTool.textbox){
        if(shallow(oldCurrentMark.svgAttributesPair, newCurrentMark.svgAttributesPair) && 
            shallow(oldCurrentMark.transform, newCurrentMark.transform) && 
            shallow(oldCurrentMark.text, newCurrentMark.text) && 
            shallow(oldCurrentMark.penColor, newCurrentMark.penColor)){
            return true
        }
    }
    return false
}

export const isSameMarks = (oldMarks, newMarks) => {
    if(oldMarks.length !== newMarks.length) return false
    const length = oldMarks.length
    for(let i = 0 ; i < length ; i++){
        const oldMark = oldMarks[i]
        const newMark = newMarks[i]
        if(oldMark.id !== newMark.id) 
            return false
        if(oldMark.points.length !== newMark.points.length)
            return false
    }
    return true
}

export const shortenGuidForDisplay = (guid) => guid ? guid.split('-')[0] : ''


export const getRotatePoint = (rotateDeg, cx, cy, x, y) => {
    return applyToPoint(rotateDEG(rotateDeg, cx, cy), {x, y})
}
export const createVector = (x, y) => {
    return new window.p5.Vector(x, y)
}
export const vectorSub = (v2, v1) => {
    return window.p5.Vector.sub(v2, v1)
}
export const vectorDistance = (v1, v2) => {
    return window.p5.Vector.dist(v1, v2)
}

export const angleBetween = (v1, v2) => {
    return window.p5.Vector.an
}
export const vectorAdd = (v1, v2) =>{
    return window.p5.Vector.add(v1,v2)
}

export const getResizeDifferenceWithRotate = (x1, y1, x2, y2, rotateDegree, cx, cy, orginalTranslateX, orginalTranslateY) => {
    const v1 = createVector(x1, y1)
    const v2 = createVector(x2, y2)

    const vec = vectorSub(v2, v1)
    const distance = vectorDistance(v2, v1)

    if(distance === 0){
        return {
            differenceOfX: 0,
            differenceOfY: 0
        }
    }

    //如果是0 會出現NAN
    if(cx === 0) cx = 0.00001
    if(cy === 0) cy = 0.00001
    let horizontalDiff = vec.angleBetween(createVector(Math.abs(cx), 0))
    let verticalDiff = vec.angleBetween(createVector(0, Math.abs(cy)))

    
    const horizontalDegreeDiff = -rotateDegree - (horizontalDiff)* 180/Math.PI
    const verticalDegreeDiff = -rotateDegree - (verticalDiff)* 180/Math.PI

    
    return{
        differenceOfX: distance*Math.cos(horizontalDegreeDiff * Math.PI/180),
        differenceOfY: distance*Math.cos(verticalDegreeDiff * Math.PI/180)
    }
}

export function getMidPoint( /*int*/ x, /*int*/ y, /*int*/ width, /*int*/ height, /*int(in degrees)*/ angle_degrees )
{
    var angle_rad = angle_degrees * Math.PI / 180;
    var cosa = Math.cos(angle_rad);
    var sina = Math.sin(angle_rad);
    var wp = width/2;
    var hp = height/2;
    return { px: ( x + wp * cosa - hp * sina ),
             py: ( y + wp * sina + hp * cosa ) };
}

export const getBoundingClientRectPos = (shape) => {
    const rect = shape.getBoundingClientRect()
    return{
        LT:[rect.left, rect.top],
        RT:[rect.left+rect.width,rect.top],
        LB:[rect.left, rect.top+rect.height],
        RB:[rect.left+rect.width,rect.top+rect.height]
    }
}
const AudioContext = window.AudioContext || window.webkitAudioContext
export const loadSoundMeter = (stream, dom) => {
    const soundMeter = new SoundMeter(new AudioContext())
    let meterRefresh
    try{
        soundMeter.connectToSource(stream, function(e){
            if(e){
                console.log(e)
                return null
            }
            if(dom?.classList.contains('microphone-audio-dynamic')){
                meterRefresh = setInterval(() => {
                    const percent = soundMeter.instant.toFixed(2)*5*100
                    dom.style.width = `${percent >= 100 ? 100 : percent}%`
                }, 200)
            }else if(dom?.classList.contains('voice-detect')){
                if(stream.getAudioTracks() && stream.getAudioTracks()[0]){
                    const audioTrack = stream.getAudioTracks()[0]
                    if(!audioTrack?.fake){
                        meterRefresh = setInterval(() => {
                            const percent = soundMeter.slow.toFixed(2)
                            if(percent > 0){
                                dom.classList.remove('stop')
                                dom.percents = []
                            }else{
                                dom.percents = dom.percents? dom.percents : []
                                if(dom.percents.length > 0){
                                    dom.percents = []
                                    dom.classList.add('stop')   
                                }else{
                                    dom.percents.push(0)
                                }
                            }
                        }, 200)
                    }else{
                        return null
                    }
                }
                
            }
            
        })
    }catch(ex){
        console.log(ex)
        return null
    }
    return meterRefresh
}

export const getHundredCeiling = (r) => {
    return Math.ceil(r/100)*100
}
export const getHundredFloor = (r) => {
    return Math.floor(r/100)*100
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const thumbnailScrollIntoView = (index) => {
    const parent = document.querySelector('.thumbnail')
    const element = document.querySelector(`img[data-idx="${index}"]`)
    if(!element) return
    const top = element.getBoundingClientRect().top
    setTimeout(() => {
        element.parentElement.parentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
        })
    }, 100)
    
}
export const thumbnailScrollToBottom = () => {
    const parent = document.querySelector('.thumbnail')
    parent.scrollTop = parent.scrollHeight
}

export const notifyWindowsOrMac = (data, needDeleteCookie) => {
    console.log('======notifyWindowsOrMac=====')
    try{
        if(window?.webkit?.messageHandlers?.Mobile){
            if(needDeleteCookie){
                deleteCookie('userInfo')
            }
            window.webkit.messageHandlers.Mobile.postMessage(data)
            return true
        }else if(window?.chrome?.webview){
            if(needDeleteCookie){
                deleteCookie('userInfo')            
            }
            window.chrome.webview.postMessage(data)
            return true
        }else{
            return false
        }
    }catch(e){
        console.log(e)
    }
    return false
}

export const downloadImage = (quality) => {
    return new Promise(resolve => {
        const svgNode = document.querySelector('.whiteboard')

        const image = new Image();
        image.onload = () => {
            // Create image canvas
            const canvas = document.createElement('canvas');
            // Set width and height based on SVG node
            // const rect = svgNode.getBoundingClientRect();
            const whiteboardSize = document.getElementById('whiteboard-size')
            // canvas.width = rect.width * quality;
            // canvas.height = rect.height * quality;
            canvas.width = whiteboardSize.firstElementChild.value
            canvas.height = whiteboardSize.lastElementChild.value

            // Draw background
            const context = canvas.getContext('2d');
            context.fillStyle = '#FFFFFF';
            context.fillRect(0, 0,canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            // Set some image metadata
            let dt = canvas.toDataURL('image/png')
            resolve(dt)
        }


        var url = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(serializeString(svgNode));

        image.src = url
        

        function serializeString(svg) {
            const xmlns = 'http://www.w3.org/2000/xmlns/';
            const xlinkns = 'http://www.w3.org/1999/xlink';
            const svgns = 'http://www.w3.org/2000/svg';
            svg = svg.cloneNode(true);
            const marksNode = svg.querySelector('.marks')
            const shapesNode = svg.querySelector('.shapes')
            const nodeToRemoveChildren = Array.from(svg.children)
                .filter( dom => !(dom === marksNode || dom === shapesNode))
        
            nodeToRemoveChildren.forEach(child => {
                svg.removeChild(child)
            })


            const fragment = window.location.href + '#';
            const walker = document.createTreeWalker(svg, NodeFilter.SHOW_ELEMENT, null, false);
            while (walker.nextNode()) {
                for (const attr of walker.currentNode.attributes) {
                if (attr.value.includes(fragment)) {
                    attr.value = attr.value.replace(fragment, '#');
                }
                }
            }
            svg.setAttributeNS(xmlns, 'xmlns', svgns);
            svg.setAttributeNS(xmlns, 'xmlns:xlink', xlinkns);
            const serializer = new XMLSerializer();
            const string = serializer.serializeToString(svg);
            return string
        }
    })
}


export const minimapRatioCalcluate = () => {

   const whiteboardContainer = document.querySelector('.whiteboard-container')
   const whiteboardWrapper = document.querySelector('.whiteboard-wrapper')
   const hardware = whiteboardContainer.closest('.hasHardware')

   const containerRect = whiteboardContainer.getBoundingClientRect()
   const whiteboardWrapperRect = whiteboardWrapper.getBoundingClientRect()
   let diffX, diffY, containerLeftTop, containerSize, whiteboardWrapperLeftTop, whiteboardWrapperSize
   if(hardware){
        diffX = Math.round(whiteboardWrapperRect.x - containerRect.x) - 1  //border-left
        diffY = Math.round(whiteboardWrapperRect.y - containerRect.y) - 1 // hardware has border-top
        containerLeftTop = [Math.round(containerRect.x), Math.round(containerRect.y) - 1]
        containerSize = [Math.round(containerRect.width)-2,Math.round(containerRect.height)-1] //-2: border-left and border-right, -1:border-bottom
        whiteboardWrapperLeftTop = [Math.round(whiteboardWrapperRect.x)-1, Math.round(whiteboardWrapperRect.y)-1]
        whiteboardWrapperSize = [Math.round(whiteboardWrapperRect.width), Math.round(whiteboardWrapperRect.height)]
   }else{
        diffX = Math.round(whiteboardWrapperRect.x - containerRect.x) - 1  //border-left
        diffY = Math.round(whiteboardWrapperRect.y - containerRect.y)
        containerLeftTop = [Math.round(containerRect.x), Math.round(containerRect.y)]
        containerSize = [Math.round(containerRect.width)-2,Math.round(containerRect.height)-1] //-2: border-left and border-right, -1:border-bottom
        whiteboardWrapperLeftTop = [Math.round(whiteboardWrapperRect.x)-1, Math.round(whiteboardWrapperRect.y)]
        whiteboardWrapperSize = [Math.round(whiteboardWrapperRect.width), Math.round(whiteboardWrapperRect.height)]
   }

   return {
        diffX,
        diffY,
        containerLeftTop,
        containerSize,
        whiteboardWrapperLeftTop,
        whiteboardWrapperSize,
        whiteboardContainer
   }
}

export const getCurrentPageSVGbase64String = () => {
    const svgClone = document.querySelector('.whiteboard').cloneNode(true)
    const marksNode = svgClone.querySelector('.marks')
    const shapesNode = svgClone.querySelector('.shapes')
    const imageInksNode = svgClone.querySelector('.image-inks')
    const nodeToRemoveChildren = Array.from(svgClone.children)
        .filter( dom => !(dom === marksNode || dom === shapesNode || dom === imageInksNode))
    nodeToRemoveChildren.forEach(child => {
        svgClone.removeChild(child)
    })
    const hasLoadingElement = shapesNode.querySelectorAll('.img-loading').length > 0
    const serializedSVG = new XMLSerializer().serializeToString(svgClone)

    // console.log(svgToMiniDataURI(serializedSVG))
    return {
        base64: svgToMiniDataURI(serializedSVG),
        pageId: svgClone.dataset.pageid,
        markCount: marksNode.childElementCount,
        shapeCount: shapesNode.childElementCount,
        imageInkCount: imageInksNode.childElementCount,
        hasLoadingElement
    } 

}

export const getCurrentRatioBaseOnContainer = (containerLeftTop, containerSize, whiteboardWrapperLeftTop, 
    whiteboardWrapperSize) => {

    const containerHorizontalRange = [containerLeftTop[0], containerLeftTop[0]+containerSize[0]]
    const containerVerticalRange = [containerLeftTop[1], containerLeftTop[1]+containerSize[1]]

    const wrapperHorizontalRange = [whiteboardWrapperLeftTop[0], whiteboardWrapperLeftTop[0]+whiteboardWrapperSize[0]]
    const wrapperVerticalRange = [whiteboardWrapperLeftTop[1], whiteboardWrapperLeftTop[1]+whiteboardWrapperSize[1]]


    let leftResult, topResult, widthResult, heightResult
    if(containerHorizontalRange[0] <= wrapperHorizontalRange[0] && 
        containerHorizontalRange[1] >= wrapperHorizontalRange[1] ){ //X軸在範圍內: X滿版
            leftResult = 0
            widthResult = 100

    }else{ //X overflow 
        //     a         c       t      u      a           l

        //                     container
        //ex: (-81)-------(52)----------(608)-------------(720)

        let leftRatio = ((containerHorizontalRange[0] - wrapperHorizontalRange[0])/whiteboardWrapperSize[0])*100
        let widthRatio = (containerSize[0]/whiteboardWrapperSize[0])*100
        if(leftRatio < 0){
            leftRatio = 0
        }

        if(leftRatio + widthRatio > 100){
            widthRatio = 100 - leftRatio
        }

        leftResult = leftRatio
        widthResult = widthRatio

    }
    if(containerVerticalRange[0] <= wrapperVerticalRange[0] && 
        containerVerticalRange[1] >= wrapperVerticalRange[1] ){ //Y軸在範圍內: Y滿版
            topResult = 0
            heightResult = 100
    }else{

        let topRatio = ((containerVerticalRange[0] - wrapperVerticalRange[0])/whiteboardWrapperSize[1])*100
        let heightRatio = (containerSize[1]/whiteboardWrapperSize[1])*100
        if(topRatio < 0){
            topRatio = 0
        }
        if(topRatio + heightRatio > 100){
            heightRatio = 100 - topRatio
        }
        topResult = topRatio
        heightResult = heightRatio
    }

    return {leftResult, topResult, widthResult, heightResult}
}

export const getScrollLeftMaxAndScrollTopMax = (element) => {
    return{
        scrollLeftMax: element.scrollWidth - element.clientWidth,
        scrollTopMax: element.scrollHeight - element.clientHeight,
    }
}
export const canControlPage = (accountType, isControlByInviter) => {
    if((accountType === AccountType.inviter && isControlByInviter) ||
        (accountType === AccountType.invitee && !isControlByInviter)){
            return true
    }
    return false
}

const IMAGE_QUALITY = 0.6
export const DEFAULT_MAX_SIZE_FOR_DISPLAY = 960

const toggleSvgLoadingCircleWithPos = (dragPoint) => {
    
    const loadingCircle = document.querySelector('.loading-circle')
    const loadingCircleGroup = document.querySelector('.loading-circle-group')
    if(loadingCircle.classList.contains('loadimg') || dragPoint == null){
        loadingCircle.classList.remove('loadimg')
        loadingCircleGroup.style.removeProperty('--translateX')
        loadingCircleGroup.style.removeProperty('--translateY')
        document.querySelector('.whiteboard').classList.remove('pointer-none')
    }else{
        const {x: loadingCircleGroupPosX, y:loadingCircleGroupPosY} = loadingCircleGroup.getBBox()
        const translateX = dragPoint.x - loadingCircleGroupPosX
        const translateY = dragPoint.y - loadingCircleGroupPosY

        loadingCircleGroup.style.setProperty('--translateX', translateX + 'px')
        loadingCircleGroup.style.setProperty('--translateY', translateY + 'px')
        loadingCircle.classList.add('loadimg')
        document.querySelector('.whiteboard').classList.add('pointer-none')
        
    }
}

const decodePngItxt = (imageBufferArray) => {
    try{
        const allChunks = pngChunkExtract(new Uint8Array(imageBufferArray))
        const itxtChunks = []
        allChunks.forEach(chunk => {
            if(chunk.name === 'iTXt'){
                itxtChunks.push(chunk)
            }
        })

        if(itxtChunks.length === 0){
            return null
        }

        const keyList = ['tenny', 'XML:com.adobe.xmp']
        let values = []
        for(let i = 0 ; i < itxtChunks.length ; i++){
            const chunk = itxtChunks[i]
            if(chunk == null){
                continue
            }
            const result = new TextDecoder().decode(chunk.data)
            for(let j = 0; j < keyList.length ; j++){
                const key = keyList[j]
                if(result.startsWith(key)){
                    values.push(result.substring(key.length+5))
                }
            }
        }

        let data = null
        for(let i = 0 ; i < values.length ; i++){
            const value = values[i]
            try{
                data = JSON.parse(value)
            }catch(e){
                try{
                    const parser = new DOMParser()
                    const doc = parser.parseFromString(value, "text/xml")
                    data = JSON.parse(doc.getElementsByTagName('dc:description')[0].childNodes[0].nodeValue)
                }catch(e){
                    console.log(e)
                    data = null
                }
            }
            if(data !== null){
                break
            }
        }
        

        return data
    }catch(e){
        return null
    }

}

export const uploadImageToWhiteboard = ({file, customAlert, t, ev}) => {
    const fileReader = new FileReader()
    console.log('upload image...')



    let startPoint
    const ratio = useRoomChatStore.getState().ratio
    if(!ev){
        startPoint = {x: getRandom(30, 150), y: getRandom(30, 150)}
    }else if(isFirefox){ //firefox的offsetX和offsetY為零
        const boundingRect = ev.target.getBoundingClientRect()
        startPoint = {
            x: ev.clientX/ratio - boundingRect.left/ratio,
            y: ev.clientY/ratio - boundingRect.top/ratio
        }
    }else{
        startPoint = {x: ev.offsetX/ratio, y: ev.offsetY/ratio} //左上
    }
    toggleSvgLoadingCircleWithPos(startPoint)
    
    

    fileReader.onloadend = async(e) => {
        let data = decodePngItxt(e.target.result)
        
        console.log(data)
        const {roomId, userId} = useUserConnectionStore.getState()
        const {createImage, updateShapesCreatedTime} = useRoomChatStore.getState()
        if(data != null){
            data.Color = data.Color || '#000000'
            data.StrokeWidth = 4
            
            toggleSvgLoadingCircleWithPos()
            const {url, width, height, x, y, imageRatio} = generateImageInkBase64(data)
            
            let scaleWidth = width/30 //縮小倍率
            let scaleHeight = scaleWidth/imageRatio
            const imageId = uuid()
            const shapeId = uuid()
            apiUploadResource({
                id: imageId,
                roomID: roomId,
                accountID: userId,
                type: 'IMAGE_INK',
                content: url
            }).then(res => {
                
                const isImageInk = true
                data.zoomFactor = scaleWidth/width //縮小倍率
                data.Width = width
                data.Height = height
                data.offsetX = x
                data.offsetY = y

                const endPoint = {x: startPoint.x + scaleWidth, y: startPoint.y + scaleHeight} //右下
                const createdAt = new Date().getTime()
                
                createImage(shapeId, imageId, startPoint, endPoint, createdAt, userId, isImageInk, imageRatio, data)
                const imageInksContent = useRoomChatStore.getState().imageInks.find(s => s.id === shapeId)
                apiAddDrawObject({
                    roomID: roomId,
                    accountID: userId,
                    pageID: useRoomChatStore.getState().currPageID,
                    id: imageInksContent.id,
                    type: imageInksContent.type,
                    content: JSON.stringify(imageInksContent)
                }).then((res) => {
                    updateShapesCreatedTime(imageInksContent.id, new Date(res.data).getTime(), isImageInk)
                })

                if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                    
                    const event = new StrokeEvent(StrokeEventType.opponentCreateImage, {shapeId, imageId, startPoint, endPoint, createdAt, userId, isImageInk, imageRatio, color: data.Color, strokeWidth: data.StrokeWidth})
                    console.log('opponentCreateImage')
                    useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))  
                }

            }).catch((res) => {
                console.log(res)
                if(res.response.status === 406){
                    customAlert(t('toolbar.imageCountExceed'))
                }else if(res.response.status === 413){
                    customAlert(t('toolbar.fileSizeExceed'))
                }else{
                    customAlert(t('toolbar.imageUploadFailed'))
                }
            })
        }else{
            const size = file.size/1024/1024
            if(size > 20){ //20M
                customAlert(t('toolbar.fileSizeExceed'))
                return
            }

            new Compressor(file, {
                quality: IMAGE_QUALITY,
                maxWidth: 2048,
                async success(result) {
                    const img = new Image()
                    const base64 = await getImageBase64(result)
                    img.src = base64
                    img.onload = () => {
                        let imgWidth = parseFloat(img.naturalWidth)
                        let imgHeight = parseFloat(img.naturalHeight)
                        const ratio = imgWidth/imgHeight
                        if(ratio > 1){
                            if(imgWidth > DEFAULT_MAX_SIZE_FOR_DISPLAY){
                                imgWidth = DEFAULT_MAX_SIZE_FOR_DISPLAY
                                imgHeight = DEFAULT_MAX_SIZE_FOR_DISPLAY / ratio
                            }
                        }else{
                            if(imgHeight > DEFAULT_MAX_SIZE_FOR_DISPLAY){
                                imgHeight = DEFAULT_MAX_SIZE_FOR_DISPLAY
                                imgWidth = DEFAULT_MAX_SIZE_FOR_DISPLAY * ratio
                            }
                        }

                        const imageId = uuid()
                        const shapeId = uuid()
                        apiUploadResource({
                            id: imageId,
                            roomID: roomId,
                            accountID: userId,
                            type: 'IMAGE',
                            content: base64
                        }).then(res => {
                            
                            const endPoint = {x: startPoint.x + imgWidth, y: startPoint.y + imgHeight} //右下
                            const createdAt = Date.now()
                            createImage(shapeId, imageId, startPoint, endPoint, createdAt, userId)
                            toggleSvgLoadingCircleWithPos()
                            const shapeContent = useRoomChatStore.getState().shapes.find(s => s.id === shapeId)
                            apiAddDrawObject({
                                roomID: roomId,
                                accountID: userId,
                                pageID: useRoomChatStore.getState().currPageID,
                                id: shapeContent.id,
                                type: shapeContent.type,
                                content: JSON.stringify(shapeContent)
                            }).then((res) => {
                                updateShapesCreatedTime(shapeContent.id, new Date(res.data).getTime())
                            })
    
                            if(useUserConnectionStore.getState().dataChannel && useUserConnectionStore.getState().dataChannel.readyState === 'open'){
                                const event = new StrokeEvent(StrokeEventType.opponentCreateImage, {shapeId, imageId, startPoint, endPoint, createdAt, userId, isImageInk: false})
                                useUserConnectionStore.getState().dataChannel.send(JSON.stringify(event))  
                            }
                        }).catch(({response}) => {
                            if(response.status === 406){
                                customAlert(t('toolbar.imageCountExceed'))
                            }else if(response.status === 413){
                                customAlert(t('toolbar.fileSizeExceed'))
                            }else{
                                customAlert(t('toolbar.imageUploadFailed'))
                            }
                        })
                        
                    }
                },
                error(err) {
                    console.log(err)
                    customAlert(t('toolbar.imageUploadFailed'))
                    return
                },
            })

        }
    } 


    fileReader.readAsArrayBuffer(file)

}

export function getSmoothPathFromPoints(points, correction) {
    var path = [], i,
        p1 = new window.fabric.Point(points[0].x, points[0].y),
        p2 = new window.fabric.Point(points[1].x, points[1].y),
        len = points.length, multSignX = 1, multSignY = 0, manyPoints = len > 2;
    correction = correction || 0;

    path.push(['M', p1.x, p1.y]);
    for (i = 1; i < len; i++) {
     
        var midPoint = p1.midPointFrom(p2);
        // p1 is our bezier control point
        // midpoint is our endpoint
        // start point is p(i-1) value.
        if(i === 1){
            path.push(['Q', p1.x, p1.y, midPoint.x, midPoint.y]);
        }else{
            path.push(['T', midPoint.x, midPoint.y]);
        }
        

        p1 = points[i];
        if ((i + 1) < points.length) {
            p2 = points[i + 1];
        }
      
    }
  
    // path.push(['L', p1.x, p1.y]);
    return path;
  }