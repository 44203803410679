import {useState, useEffect} from 'react'
import {ToolBarTool, useToolbarSettingsStore } from '../store'
import ReactDOM from "react-dom"

const useToolbarEffectEvent = ({iconRef, toolbarTool, clickOneTimeTool, setClickOneTimeTool}) => {
    const [isActive, setIsActive] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [isTouchDevice, setIsTouchDevice] = useState(false)
    const currentActiveTool = useToolbarSettingsStore((state) => state.currentActiveTool)
    const updateCurrentActiveTool = useToolbarSettingsStore((state) => state.updateCurrentActiveTool)


    useEffect(() => {
        const onTouchStart = (e) =>{
            ReactDOM.unstable_batchedUpdates(() => {
                setIsTouchDevice(true)
                setToolActive()
                setIsShowDetail(prev => !prev)
                if(clickOneTimeTool != null){
                    setClickOneTimeTool(true)
                }
            })
        }
        const onTouchEnd = (e) => {
            e.preventDefault()
        }
        const onMouseEnter = (e) => {
            e.preventDefault()
            if(e.target instanceof HTMLDivElement){
                return setIsHover(true)
            }
            setIsShowDetail(true)
        }
        const onMouseLeave = (e) => {
            e.preventDefault()
            if(e.target instanceof HTMLDivElement){
                return setIsHover(false)
            }
            setIsShowDetail(false)
        }
        const onMouseDown = (e) => {
            e.preventDefault()
            if(e.buttons !== 1) return
            
            if(clickOneTimeTool != null){
                return setClickOneTimeTool(true)
            }
            setToolActive()
        }
        if(iconRef.current){
            iconRef.current.addEventListener('touchstart', onTouchStart, { passive: true})
            iconRef.current.addEventListener('touchend', onTouchEnd)
            iconRef.current.addEventListener('mouseenter', onMouseEnter)
            iconRef.current.addEventListener('mouseleave', onMouseLeave)
            
            const iconImage = iconRef.current.querySelector('.toolbar-icon')
            iconImage.addEventListener('mouseenter', onMouseEnter)
            iconImage.addEventListener('mouseleave', onMouseLeave)
            iconImage.addEventListener('mousedown', onMouseDown)
        }

        return () => {
            if(iconRef.current){
                iconRef.current.removeEventListener('touchstart', onTouchStart)
                iconRef.current.removeEventListener('touchend', onTouchEnd)
                iconRef.current.removeEventListener('mouseenter', onMouseEnter)
                iconRef.current.removeEventListener('mouseleave', onMouseLeave)

                const iconImage = iconRef.current.querySelector('.toolbar-icon')
                iconImage.removeEventListener('mouseenter', onMouseEnter)
                iconImage.removeEventListener('mouseleave', onMouseLeave)
                iconImage.removeEventListener('mousedown', onMouseDown)
            }
        }

    }, [])

    useEffect(() => {    
        if(!toolbarTool) return
        if(currentActiveTool === toolbarTool) {
            return setIsActive(true)
        }
        const whiteboard = document.querySelector('.whiteboard')
        const eraserMode = 'erasermode'
        const penMode = 'penmode'
        if(currentActiveTool === ToolBarTool.eraser){
            whiteboard?.classList.add(eraserMode)
            whiteboard?.classList.remove(penMode)
        }else if(currentActiveTool === ToolBarTool.pen){
            whiteboard?.classList.add(penMode)
            whiteboard?.classList.remove(eraserMode)
        }else{
            whiteboard?.classList.remove(penMode)
            whiteboard?.classList.remove(eraserMode)
        }
        if(currentActiveTool === ToolBarTool.cursor){
            document.querySelector('.shapes').classList.remove('pointer-none')
        }else{
            document.querySelector('.shapes').classList.add('pointer-none')
        }
        

        ReactDOM.unstable_batchedUpdates(() => {
            setIsShowDetail(false)
            setIsActive(false)
            setIsHover(false)    
        })
        
        
    }, [currentActiveTool, toolbarTool])

    const setToolActive = () => {
        if(!toolbarTool) return
        updateCurrentActiveTool(toolbarTool)
        setIsActive(true)
    }

    return {
        isActive, 
        isHover, 
        isShowDetail,
        setIsShowDetail,
        isTouchDevice, 
        displayIconBackground: (() => {
            if(toolbarTool)
                return isHover || isActive
            else
                return isHover || (isTouchDevice && isShowDetail)
        })(),

        displayHoverImage: (() => {
            if(toolbarTool === ToolBarTool.textbox || toolbarTool === ToolBarTool.cursor)
                return false
            else
                return isHover || (isTouchDevice && isShowDetail)
        })()
    }
}


export default useToolbarEffectEvent
