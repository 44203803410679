export class SocketEvent{
    constructor(type, data, userId, roomId, targetUserId, deviceSetting) {
        this.type = type
        this.data = data
        this.sessionId = userId
        this.roomId = roomId
        this.targetUserId = targetUserId
        this.deviceSetting = deviceSetting
    }
}
export const SocketEventType = {
    INIT: "INIT",
    ALL_READY: 'ALL_READY',
    ALL_READY_RESPONSE: 'ALL_READY_RESPONSE',
    ENTER_ROOM: "ENTER_ROOM",
    LEAVE_ROOM: "LEAVE_ROOM",
    ROOM_CHANGED: "ROOM_CHANGED",
    CLEAR_STROKE: "CLEAR_STROKE",
    CLEAR_CANVAS: "CLEAR_CANVAS",
    DRAW_POINT: "DRAW_POINT",
    VIDEO_OFFER: "VIDEO_OFFER",
    VIDEO_ANSWER: "VIDEO_ANSWER",
    NEW_ICE_CANDIDATE: "NEW_ICE_CANDIDATE",
    ICE_COMPLETE: 'ICE_COMPLETE',
    USER_ENTER_IN_YOUR_ROOM: "USER_ENTER_IN_YOUR_ROOM",
    USER_LEAVE_YOUR_ROOM: "USER_LEAVE_YOUR_ROOM",
    HANG_OUT: "HANG_OUT",
    CLOSE_VIDEO: 'CLOSE_VIDEO',
    OPEN_VIDEO: 'OPEN_VIDEO',
    MUTE_AUDIO: 'MUTE_AUDIO',
    UNMUTE_AUDIO: 'UNMUTE_AUDIO',
    OPPONENT_UNDO: 'OPPONENT_UNDO',
    OPPONENT_TRANSFER_IMAGE_INK_TO_INK: 'OPPONENT_TRANSFER_IMAGE_INK_TO_INK',
    REQUEST_OPPONENT_VIDEO_SETTING: 'REQUEST_OPPONENT_VIDEO_SETTING'
}