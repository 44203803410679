import './library/wdyr'
import React, {useEffect, lazy, Suspense, useLayoutEffect} from "react"
import './App.css'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import {useTranslation} from 'react-i18next'
import useContextMenu from './hooks/useContextMenu'
import useKeyboardShortcuts from './hooks/useKeyboardShortcuts'
import { ToastContainer } from 'react-toastify'
import {isMobile, isTablet} from 'react-device-detect'
import 'react-toastify/dist/ReactToastify.css'
import Div100vh from 'react-div-100vh'

const Room = lazy(() => import('./components/Room'))
const RoomLogin = lazy(() => import('./components/RoomLogin'))
const App = () => {
    const {t} = useTranslation()
    const notAllowToUse = (isMobile===true && isTablet===false)
    useLayoutEffect(() => {
        if(notAllowToUse){
            document.body.classList.add('disallowed-mobile')
        }
    })
    useKeyboardShortcuts()
    useContextMenu()

    

    useEffect(() => {
        document.title = t('room.cloudWhiteboard')

        if(!window.location.href.includes('https') && !window.location.href.includes('localhost')){
            // alert('not support http')

        }
        const fn = (e) => {
            if(e.ctrlKey || e.metaKey){
                e.preventDefault()
            }
        }
        document.addEventListener('wheel', fn, {passive: false})
        document.addEventListener('mousewheel', fn, {passive: false})
        document.addEventListener('DOMMouseScroll', fn, {passive: false})
        
        import('./api').then(({apiSystemGetVersion}) => {
            apiSystemGetVersion().then((res) => {
                console.log(res.data)

                // toast.info("Welcome");
            })
        })

        if ( !is_touch_device() ) {
            document.querySelector('html').classList.add('no-touch')
        }

        return () => {
            document.title = ''
            document.removeEventListener('wheel', fn, {passive: false})
            document.removeEventListener('mousewheel', fn, {passive: false})
            document.removeEventListener('DOMMouseScroll', fn, {passive: false})
            if(window.streams && window.streams.length > 0){
                window.streams[0].getTracks()[0].stop()
                window.streams.forEach(stream => {
                    if(stream?.getTracks){
                        stream.getTracks().forEach(track => {
                            track.stop()
                        })
                    }
                })
            }
        }
    }, [])

    function is_touch_device() {
        return 'ontouchstart' in window        // works on most browsers 
         || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    }

    return (
        <Router>
            {!notAllowToUse &&
                // <div className="App">
                <Div100vh>
                    <Suspense fallback={<></>}>
                        <CookiesProvider>
                                <Switch>
                                    <Route path="/:id" exact component={Room}/>
                                    <Route path="/:id/login" exact component={RoomLogin}/>
                                </Switch>
                        </CookiesProvider>
                        {/* <div style={{pointerEvents:'none', position: 'fixed', display: 'flex',justifyContent: 'space-around', width: '300px', bottom: 0, height: '60px', left: '50%', fontSize: '.8rem'}}>
                            <div>2022/6/28, 10:57</div>
                        </div> */}
                    </Suspense>
                    <ToastContainer
                            position="top-center"
                            autoClose={1400}
                            hideProgressBar
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover={false}
                    />
                {/* </div> */}
                </Div100vh>
            }
            {notAllowToUse && 
                <div>
                    <div className="disallowed-mobile-text">{t('general.disallowedMobile')}</div>
                </div>
            }
        </Router>
    )
}

export default App
